export enum MdsButtonSize {
  XXSmall = "xxsmall",
  XSmall = "xsmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge",
}

export enum MdsButtonVariant {
  Brand = "brand",
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Text = "text",
  TextTertiary = "text-tertiary",
  TextTertiaryOutlined = "text-tertiary-outlined",
  FilledPrimary = "filled-primary",
  FilledDark = "filled-dark",
  Filled = "filled",
  OutlinedAccent = "outlined Accent",
  Outlined = "outlined",
  Transparent = "transparent",
  MultiIcon = "multi-icon",
  Danger = "danger",
  Toggled = "toggled",
}

export enum MdsSelectionVariant {
  Standard = "standard",
  Accent = "accent",
}

export enum MdsButtonShape {
  Round = "round",
  Square = "square",
}

export enum MdsButtonIconPosition {
  Left = "left",
  Right = "right",
}
