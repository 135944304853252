import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { css } from "@/domains/emotion";
import {
  MdsItemListSkeletonRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";

export const MdsSkeletonItemList = () => {
  const data: MdsItemListSkeletonRowData = {
    key: "skeleton",
    type: MdsItemListRowType.Skeleton,
    size: MdsItemListSize.Large,
    payload: {},
  };

  return (
    <div className={skeletonStyles}>
      {Array.from({ length: 10 }).map((_, i) => (
        <MdsItemListSkeletonRow data={data} key={i} />
      ))}
    </div>
  );
};

const skeletonStyles = css({
  margin: `40px 10px`,
  overflowY: "hidden",
  "> span": {
    display: "block",
    margin: `20px 0`,
  },
});
