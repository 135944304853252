import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntryEmail,
  SyncModelPermissionEntryGuestAccount,
  SyncModelPermissionEntryStatus,
} from "@/domains/sync-scopes/types";
import { ShareSheetPermissionRowLayout } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetPermissionRowLayout";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { MdsTextOverflow } from "@/design-system/components/text/types";

interface ShareSheetEmailPermissionRowProps {
  permission: (SyncModelPermissionEntryEmail | SyncModelPermissionEntryGuestAccount) &
    SyncModelPermissionEntryStatus;
  isUnsharing: boolean;
  handleRevokeAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleUpdateAccess: (modelAccessData: SyncModelPermissionEntry) => void;
  handleCancelOperation: (syncOperationId: string) => void;
}

export const ShareSheetEmailPermissionRow = observer<ShareSheetEmailPermissionRowProps>(
  ({ permission, isUnsharing, handleRevokeAccess, handleUpdateAccess, handleCancelOperation }) => {
    // If isGuestAccount is false, then it means it is a PENDING grant access operation using an email, which is not editable.
    const isGuestAccount = permission.scope_kind === "GUEST_ACCOUNT_SCOPE";
    return (
      <ShareSheetPermissionRowLayout
        permission={permission}
        isUnsharing={isUnsharing}
        canUpdateAccess={isGuestAccount}
        canRevokeAccess={isGuestAccount}
        handleRevokeAccess={isGuestAccount ? handleRevokeAccess : noop}
        handleUpdateAccessToReadOnly={isGuestAccount ? handleUpdateAccess : noop}
        handleUpdateAccessToReadWrite={isGuestAccount ? handleUpdateAccess : noop}
        handleCancelOperation={isGuestAccount ? handleCancelOperation : noop}
      >
        <div className={container}>
          <div className={iconWrapperStyles}>
            <MdsIcon
              kind={MdsIconKind.Envelope}
              innerStyles={{ Icon: { className: iconStyles } }}
            />
          </div>
          <MdsText
            size={MdsTextSize.Small}
            overflow={MdsTextOverflow.EllipsisWrap}
            numberOfLines={2}
          >
            {permission.scope_kind === "EMAIL_SCOPE"
              ? permission.email_address
              : "TODO: guest email"}
          </MdsText>
        </div>
      </ShareSheetPermissionRowLayout>
    );
  }
);

const container = css({
  display: "flex",
  alignItems: "center",
  minWidth: 0,
});

const iconWrapperStyles = css({
  height: "34px",
  width: "34px",
  borderRadius: "100%",
  backgroundColor: mdsColors().grey.x500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",
  flexShrink: 0,
});

const iconStyles = css({
  color: mdsColors().white,
});
