export const askForUserFile = async (accept?: string) => {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.style.display = "none";
  fileInput.accept = accept ?? "";
  fileInput.click();

  let resolveFile: (value?: File) => void;
  const userFilePromise = new Promise<File | undefined>(resolve => {
    resolveFile = resolve;
  });

  fileInput.addEventListener("change", (e: Event) => {
    const file = (e?.target as HTMLInputElement)?.files?.[0];
    resolveFile(file);
  });

  const file = await userFilePromise;
  fileInput.remove();
  return file;
};
