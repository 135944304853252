import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import {
  IndexedNoteSyncUpdateValue,
  NoteUpsertedSyncUpdateValue,
  INoteObservable,
  NoteObservable,
  NotesSearchParams,
  NotesIndexTuple,
} from "@/store/note";
import { uuidModule } from "@/modules/uuid";
import { NoteModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateNoteOperation } from "@/store/sync/operations/notes/CreateNoteOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, ObservableMap, override } from "mobx";
import { Table } from "dexie";
import { SearchSuggestionType } from "@/domains/search";
import { NoteIndexes } from "@/store/note/NoteIndexes";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { NoteQueueObservable } from "@/store/note/NoteQueueObservable";
import { searchForNotes } from "@/store/note/NoteSearch";

export interface NotesStore {
  allNotes: INoteObservable[];
  createNote: (payload: { noteId: Uuid; eventContext: EventContext }) => void;
  getNoteObservableById: (payload: { noteId: Uuid }) => Maybe<INoteObservable>;
  search: (payload: NotesSearchParams) => Promise<NotesIndexTuple[]>;
}

export class AppStoreNoteStore
  extends BaseSyncModelStore<NoteObservable, NoteModelData>
  implements NotesStore
{
  public static searchTitleLimit = 100;

  private queues = new ObservableMap<Uuid, NoteQueueObservable>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Note, ...injectedDeps });
    makeObservable<this, "queues" | "updateSearchSuggestions">(this, {
      // App/react lifecycle
      resetState: false,

      // Queues
      queues: false,
      beforeUnload: false,
      getNoteQueue: false,

      remoteTable: override,
      localTable: override,
      recompute: override,

      createSyncModel: false,
      getNoteObservableById: false,

      allNotes: computed,

      composeNewNote: action,
      createNote: action,
      deleteNote: action,

      updateSearchSuggestions: false,
      search: false,
    });

    window.addEventListener("beforeunload", this.beforeUnload);
  }

  beforeUnload = (e: Event) => {
    for (const [, queue] of this.queues) {
      if (queue.isEmpty) continue;

      e.preventDefault();
      e.stopPropagation();

      return "Changes are being saved. Are you sure you want to leave?";
    }
  };

  createSyncModel(updateValue: IndexedNoteSyncUpdateValue): NoteObservable {
    return new NoteObservable({ id: updateValue.model_id, data: updateValue, store: this.store });
  }

  getNoteObservableById = ({ noteId }: { noteId?: Uuid }): Maybe<INoteObservable> => {
    return noteId ? this.get(noteId) : undefined;
  };

  getNoteQueue = ({ noteId }: { noteId: Uuid }): NoteQueueObservable => {
    let queue = this.queues.get(noteId);
    if (!queue) {
      queue = new NoteQueueObservable(noteId);
      this.queues.set(noteId, queue);
    }
    // Idea:
    //     We could use the queue on recompute + call recompute when reacting to a new operation
    //     on the queue.
    // Concerns:
    // 1. Order of operations read from disk vs those still only kept in memory.
    //    One possibility is to process disk operations and then memory ops, giving them
    //    higher priority.
    // 2. This only covers operations saved to the note queue (mostly from the editor).
    //    Since they are never queued in memory but written to disk and then they are loaded to
    //    the queue maybe it's not such a big issue.
    // 3. Collections and other models are not covered.
    //    To fix those we would need queues like NoteQueueObservable for each model id or to
    //    bring back an unified memory queue (much more complex).
    // Overall:
    //    Doing it for notes seems relevant, for other entities perhaps the complexity is not worth it.
    return queue;
  };

  get allNotes(): INoteObservable[] {
    // DEXIE REFACTOR TODO: REFACTOR FOR SEARCH
    return [];
  }

  // ACTIONS
  public composeNewNote = async ({ eventContext }: { eventContext: EventContext }) => {
    const noteId = uuidModule.generate();
    await this.createNote({ noteId, eventContext });

    this.store.navigation.goToNote({ noteId, autoFocus: true });
  };

  public async createNote({ noteId, eventContext }: { noteId: Uuid; eventContext: EventContext }) {
    await new CreateNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
    trackEvent(TrackedEvent.NoteCreate, { note_id: noteId, context: eventContext });
  }

  public async deleteNote({ noteId }: { noteId: Uuid }) {
    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(new DeleteNoteOperation({ store: this.store, payload: { id: noteId } }));
  }

  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<SyncUpdateValue<NoteModelData>>;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<IndexedNoteSyncUpdateValue>;
  }

  public async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");

    const lastOptimisticUpdate = optimisticUpdates.at(-1);
    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      logger.debug({
        message: "[APP STORE NOTE STORE] deleting note / revoking access",
        info: { modelId },
      });

      await this.localTable.delete(modelId);
      this.pool.delete(modelId);
      await this.store.search.remove(modelId);

      return;
    }

    // Fetch other required data
    const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
      noteId: modelId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });

    const spaceAccountNote = (await this.db.mappedTables[SyncModelKind.SpaceAccountNote].local.get(
      spaceAccountNoteId
    )) as SyncUpdateValue<SpaceAccountNoteModelData> | undefined;

    // Optimistic updates
    const data = lastOptimisticUpdate?.value || remoteData; // At least one of these should be defined
    if (!data) {
      await this.localTable.delete(modelId);
      await this.store.search.remove(modelId);
      return;
    }

    const indexes = new NoteIndexes({
      store: this.store,
      remoteData,
      optimisticUpdates,
      spaceAccountNote,
    }).indexes;

    const dataWithIndexes: IndexedNoteSyncUpdateValue = {
      ...(data as NoteUpsertedSyncUpdateValue),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);
    await this.updateSearchSuggestions(dataWithIndexes);

    // NOTE: we update fullText search content not here but in the NoteContentDocumentStore to
    // a. detach its potentially heavy computation from note content update
    // b. get a direct access to the most up-to-date note content (we don't optimistically update note content)
  }

  public search = (params: NotesSearchParams) => searchForNotes(this.store, params);

  private async updateSearchSuggestions(value: IndexedNoteSyncUpdateValue) {
    try {
      const generateSortKey = (
        value: IndexedNoteSyncUpdateValue
        // includeMentionedAt: boolean
      ): number => {
        const sortKey = value.last_viewed_at || value.created_at;

        // TODO: add lastMentionedAt for includeMentionedAt

        return new Date(sortKey || "").getTime();
      };

      const suggestion = {
        modelId: value.model_id,
        label: value.primary_label.slice(0, AppStoreNoteStore.searchTitleLimit),
        lowercaseLabel: value.primary_label
          .slice(0, AppStoreNoteStore.searchTitleLimit)
          .toLowerCase(),
        type: SearchSuggestionType.NOTE,
        lastViewedAt: value.last_viewed_at,
        sortKey: generateSortKey(value),
        mentionKey: generateSortKey(value),
        isAvailable: value.is_available,
      };

      await this.store.search.updateSuggestion(suggestion);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreNoteStore] Error generating search suggestion",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  resetState() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  }
}
