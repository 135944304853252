import {
  computed,
  makeObservable,
  observable,
  action,
  reaction,
  runInAction,
  onBecomeObserved,
} from "mobx";
import { groupLens, LensGroup } from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import {
  INoteObservable,
  NotesLensKind,
  NotesSortByKind,
  NotesSearchParams,
  NotesIndexTuple,
} from "@/store/note";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { lensModule } from "@/modules/lenses";
import localDb from "@/domains/local-db";
import { liveQuery, Subscription } from "dexie";
import { getRowForNoteObservable } from "@/store/note/getRowForNoteObservable";
import { EventContext } from "@/domains/metrics/context";

const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;
const DEFAULT_LIMIT = 100;

export interface NotesListPage {
  supportsAddToCollectionListAction: boolean;
  listState: ListStateObservable;
  hasNoItems: boolean;
  params: NotesSearchParams;
  lens: NotesLensKind;
  sortBy: NotesSortByKind;
}

export class NotesListPageStore extends AppSubStore implements NotesListPage {
  params: NotesSearchParams;
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  searchQuery = "";
  addToCollectionModal: AddToCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  shareSheetModal: ShareSheetModalStore;

  isLoading: boolean;
  subscriptionInitialized: boolean;
  liveQuerySubscription?: Subscription;
  subscribedNoteRows: NotesIndexTuple[] = [];

  eventContext = EventContext.NotesMultiselectActions;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.params = { sortBy: DEFAULT_SORT_BY, lens: DEFAULT_LENS, limit: DEFAULT_LIMIT };
    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    this.isLoading = true;
    this.subscriptionInitialized = false;

    makeObservable<this, "generateItemDropdown">(this, {
      initializeLiveQuery: action,
      liveQuerySubscription: observable,
      subscriptionInitialized: observable,

      subscribedNoteRows: observable,
      groupedLensItems: computed,
      itemRows: computed,

      supportsAddToCollectionListAction: observable,
      supportsMoveToTrashListAction: observable,
      searchQuery: observable,
      listState: false,
      addToCollectionModal: false,
      deleteSharedNotesModal: false,
      shareSheetModal: false,
      eventContext: false,

      isLoading: observable,
      generateItemDropdown: false,
      orderedItemIds: computed,
      hasNoItems: computed,

      // PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setSortBy: action,
      sortOptions: computed,
      increaseLimit: action,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,
      initialize: action,
    });

    reaction(
      () => this.params,
      () => this.store.memDb.settings.setNotesListPageParams(this.params)
    );

    reaction(
      () => this.subscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );

    onBecomeObserved(this, "subscribedNoteRows", () => this.initializeLiveQuery());
  }

  public setParams(params: Partial<NotesSearchParams>) {
    const lens = params.lens ?? this.params.lens;
    const sortBy = params.sortBy ?? this.params.sortBy;
    const limit = params.limit ?? this.params.limit;
    this.params = { lens, sortBy, limit };
  }

  setLens = (lens: NotesLensKind) => {
    this.setParams({ lens });
  };

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ sortBy: itemId as NotesSortByKind });
  };

  increaseLimit = () => {
    this.setParams({ limit: this.params.limit + 100 });
  };

  get lens() {
    return this.params.lens;
  }

  get sortBy() {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: SortByKind.LastCreated,
          kind: MdsDropdownItemKind.Button,
          label: "Last created",
          isChecked: this.sortBy === "LAST_CREATED",
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastModified,
          kind: MdsDropdownItemKind.Button,
          label: "Last modified",
          isChecked: this.sortBy === "LAST_MODIFIED",
          onClick: this.setSortBy,
        },
        {
          id: SortByKind.LastViewed,
          kind: MdsDropdownItemKind.Button,
          label: "Last viewed",
          isChecked: this.sortBy === "LAST_VIEWED",
          onClick: this.setSortBy,
        },
      ],
    };
  }

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
              eventContext: EventContext.NotesRowActions,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: async () => await noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () =>
            this.addToCollectionModal.open({
              noteIds: [noteObservable.id],
              eventContext: EventContext.NotesRowActions,
            }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: async () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              await noteObservable.moveToTrash();
            }
          },
        },
      ],
    };
  }

  get groupedLensItems(): LensGroup<NotesIndexTuple>[] {
    return groupLens(
      this.subscribedNoteRows.map(tuple => ({
        dateTime: new Date(tuple.length === 4 ? tuple[2] : tuple[1]),
        item: tuple,
      }))
    );
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.groupedLensItems) {
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      for (const item of group.items) {
        const tuple = item.item;
        output.push({
          type: MdsItemListRowType.AsyncNote,
          key: tuple.length === 4 ? tuple[3] : tuple[2],
          size: MdsItemListSize.Medium,
          payload: {
            noteId: tuple.length === 4 ? tuple[3] : tuple[2],
            itemRow: note =>
              getRowForNoteObservable({
                dateTime: item.dateTime,
                dropdown: this.generateItemDropdown({ noteObservable: note }),
                inMainPanel: true,
                listState: this.listState,
                noteObservable: note,
                store: this.store,
              }),
          },
        });
      }
    }
    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });
    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get hasNoItems() {
    return !this.isLoading && this.subscribedNoteRows.length === 0;
  }

  // Initialization
  async initialize() {
    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getNotesListPageParams(),
      this.store.memDb.settings.getNotesListPageParams(),
    ]);
    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setNotesListPageParams(localDBParams);
      await localDb.settings.removeNotesListPageParams();
    }
    this.setParams({
      lens: localDBParams?.lens ?? memDbParams?.lens ?? DEFAULT_LENS,
      sortBy: localDBParams?.sortBy ?? memDbParams?.sortBy ?? DEFAULT_SORT_BY,
    });
  }

  initializeLiveQuery() {
    reaction(
      () => this.params,
      ({ sortBy, lens, limit }) => {
        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(() =>
          this.store.notes.search({ lens, sortBy, limit })
        ).subscribe({
          next: rows => {
            runInAction(() => {
              this.subscribedNoteRows = rows;
              this.subscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }
}
