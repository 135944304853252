import Dexie from "dexie";
import { isUndefined } from "lodash-es";
import { SortByKind } from "@/modules/lenses/types";
import { AppStore } from "@/store/AppStore";
import {
  IndexedCollectionSyncUpdateValue,
  CollectionSearchParams,
  CollectionSearchReturnType,
  CollectionSearchReturnTypeMap,
  CollectionSearchSortByKind,
} from "@/store/collections/types";

const DEFAULT_SORT_BY = SortByKind.LastModified;

export async function searchCollections<T extends CollectionSearchReturnType>(
  store: AppStore,
  params: CollectionSearchParams<T>
): Promise<CollectionSearchReturnTypeMap<T>> {
  const sortBy = params.sortBy ?? DEFAULT_SORT_BY;

  const filterIndex = isUndefined(params.filter?.isOwnedByMe)
    ? "is_available"
    : "is_available+is_owned_by_me";

  const filteredSortMap: Record<CollectionSearchSortByKind, string> = {
    [SortByKind.LastCreated]: `[${filterIndex}+created_at+model_id]`,
    [SortByKind.LastModified]: `[${filterIndex}+modified_at+model_id]`,
    [SortByKind.LastViewed]: `[${filterIndex}+last_viewed_at+model_id]`,
    [SortByKind.Alphabetical]: `[${filterIndex}+lowercase_title+model_id]`,
    [SortByKind.LastShared]: `[${filterIndex}+shared_with_me_at+model_id]`,
  };
  const betweenClause = getBetweenClause<T>(params);

  let query = store.collections.localTable
    .where(filteredSortMap[sortBy])
    .between(betweenClause[0], betweenClause[1])
    .filter(collection => {
      return (
        matchesQuery(collection, params.query) &&
        matchesShared(collection, params.filter?.isShared) &&
        matchesModifiedBy(collection, params.filter?.modifiedBy) &&
        matchesCreatedBy(collection, params.filter?.createdBy)
      );
    });

  if (sortBy !== SortByKind.Alphabetical) query = query.reverse();

  switch (params.returns) {
    case CollectionSearchReturnType.CollectionObservable: {
      const result = await query.primaryKeys();
      return store.collections.getMany(result) as unknown as CollectionSearchReturnTypeMap<T>;
    }
    case CollectionSearchReturnType.IndexedCollection:
      return query.toArray() as unknown as CollectionSearchReturnTypeMap<T>;
    case CollectionSearchReturnType.CollectionIndexTuple:
      return query.keys() as unknown as CollectionSearchReturnTypeMap<T>;
    default:
      throw new Error(`Invalid return type: ${params.returns}`);
  }
}

export function getBetweenClause<T extends CollectionSearchReturnType>(
  params: CollectionSearchParams<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[][] {
  if (params.filter?.isOwnedByMe === true) {
    return [
      [1, 1, "\0", Dexie.minKey], // Filter out empty string values
      [1, 1, [[]], [[]]],
    ];
  }
  if (params.filter?.isOwnedByMe === false) {
    return [
      [1, 0, "\0", Dexie.minKey], // Filter out empty string values
      [1, 0, [[]], [[]]],
    ];
  }
  // params.filter.isOwnedByMe is undefined
  return [
    [1, "\0", Dexie.minKey, Dexie.minKey],
    [1, [[]], [[]], [[]]],
  ];
}

const matchesQuery = (collection: IndexedCollectionSyncUpdateValue, query?: string) => {
  return !query || collection.lowercase_title.includes(query.toLowerCase());
};

const matchesShared = (collection: IndexedCollectionSyncUpdateValue, isShared?: boolean) => {
  if (isShared === undefined) return true;
  return collection.is_shared === (isShared ? 1 : 0);
};

const matchesModifiedBy = (collection: IndexedCollectionSyncUpdateValue, modifiedBy?: string[]) => {
  if (!modifiedBy?.length) return true;
  const modifiedBySet = new Set(collection.model_data.modified_by_space_account_ids);
  return modifiedBy.some(id => modifiedBySet.has(id));
};

const matchesCreatedBy = (collection: IndexedCollectionSyncUpdateValue, createdBy?: string[]) => {
  if (!createdBy?.length) return true;
  return createdBy.some(id => id === collection.model_data.owned_by_space_account_id);
};
