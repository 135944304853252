import { useAnonMetrics } from "@/app/outlets/layout/hooks";
import { GuestModeOverlay } from "@/app/overlays/GuestModeOverlay";
import {
  GUEST_MODE_BANNER_HEIGHT,
  GuestModeTopBanner,
} from "@/components/layout/components/GuestModeTopBanner";
import { GuestModeTopBar } from "@/components/layout/components/GuestModeTopBar";
import {
  TOP_BAR_DEFAULT_HEIGHT,
  TOP_BAR_SMALL_DEVICES_HEIGHT,
} from "@/components/layout/components/topBarStyles";
import { mdsMediaQueries } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router";

const wrapperStyles = css({
  height: "100%",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const contentStyles = css({
  paddingTop: "20px",
  height: `calc(100% - ${GUEST_MODE_BANNER_HEIGHT} - ${TOP_BAR_DEFAULT_HEIGHT})`,
  width: "100%",
  justifyContent: "center",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",

  [mdsMediaQueries().exclusion.rules.desktop]: {
    height: `calc(100% - ${GUEST_MODE_BANNER_HEIGHT} - ${TOP_BAR_SMALL_DEVICES_HEIGHT})`,
  },
});

interface AppLayoutOutletProps {}

export const GuestModeAppLayoutOutlet = observer<AppLayoutOutletProps>(() => {
  useAnonMetrics();

  return (
    <>
      <GuestModeOverlay />
      <div className={wrapperStyles}>
        <GuestModeTopBar />
        <GuestModeTopBanner />
        <div className={contentStyles}>
          <Outlet />
        </div>
      </div>
    </>
  );
});
