import { sleep } from "@/domains/event-loop/synchronization";
import { logger } from "@/modules/logger";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  ISyncOperation,
  ISyncOperationGuestMode,
  IExternalOperation,
} from "@/store/sync/operations/types";
import { makeAutoObservable, runInAction } from "mobx";

type SyncOperation = BaseSyncOperationGeneric<
  ISyncOperation | ISyncOperationGuestMode | IExternalOperation
>;

export class NoteQueueObservable {
  private id: string;

  constructor(id: string) {
    this.id = id;
    makeAutoObservable(this);
  }

  get isEmpty() {
    return !this.isProcessing || !this.operations.length;
  }

  // QUEUE FOR OPERATIONS
  operations: {
    operation: SyncOperation;
    uniqueId?: string;
  }[] = [];

  isProcessing = false;

  push(operation: SyncOperation, opt?: { dropUniqueId?: string; uniqueId?: string }) {
    const dropUniqueId = opt?.dropUniqueId || opt?.uniqueId;
    // Dedup by uniqueId.
    this.operations = this.operations.filter(
      (operation, i) =>
        // The head is prob. already being processed.
        i === 0 ||
        // Only some operations want to be deduped, e.g, metadata.
        !dropUniqueId ||
        // Keep other operations.
        operation.uniqueId !== dropUniqueId
    );
    this.operations.push({ operation, uniqueId: opt?.uniqueId });
    if (this.operations.length > 0) this.process();
  }

  async process() {
    if (this.isProcessing) return;
    runInAction(() => (this.isProcessing = true));
    while (this.operations.length > 0) {
      const { operation } = this.operations[0];
      if (operation) {
        await operation.execute();
        // Only remove it from the queue after it's processed.
        runInAction(() => {
          this.operations.shift();
        });
      }
    }
    runInAction(() => (this.isProcessing = false));
  }

  async waitForQueue() {
    let done = false;
    setTimeout(() => {
      if (done) return;

      logger.warn({
        message: `[${this.id}] MemCommonEditorStore: Operation queue is taking too long`,
        info: { queueLength: this.operations.length, isProcessing: this.isProcessing },
      });
    }, 800);

    while (this.isProcessing || this.operations.length > 0) {
      await sleep(100);
    }
    done = true;
  }
}
