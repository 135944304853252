import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import {
  CollectionItemModelData,
  CollectionItemUpsertedSyncUpdateValue,
} from "@/store/collection-items/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import {
  SpaceAccountCollectionModelData,
  SpaceAccountCollectionUpsertedSyncUpdateValue,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IAddNoteToCollectionOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncOperationKind,
  SyncModelData,
  SyncCustomErrorData,
} from "@/store/sync/types";

export class AddNoteToCollectionOperation extends BaseSyncOperation<IAddNoteToCollectionOperation> {
  protected collection?: CollectionObservable;

  get operationKind(): SyncOperationKind {
    return "ADD_NOTE_TO_COLLECTION";
  }

  get successToastMessage() {
    return this.triggerSuccessToast ? <>Added to {this.mediumCollectionTitle}</> : null;
  }

  get collectionTitle() {
    return (
      this.store.collections.get(this.payload.collection_id)?.title || UNTITLED_COLLECTION_TITLE
    );
  }

  get noteTitle() {
    return this.store.notes.get(this.payload.note_id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumCollectionTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.collectionTitle}
      </MdsText>
    );
  }

  get mediumNoteTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.noteTitle}
      </MdsText>
    );
  }

  private get collectionItemId() {
    return uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.note_id,
    });
  }

  private get spaceAccountCollectionId() {
    return uuidModule.resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.payload.collection_id,
    });
  }

  async execute() {
    this.collection = await this.store.collections.getAsync(this.payload.collection_id);

    await super.execute();
    trackEvent(TrackedEvent.NoteAddToCollection, {
      note_id: this.payload.note_id,
      note_title: this.noteTitle,
      collection_id: this.payload.collection_id,
      collection_title: this.collectionTitle,
      context: this.eventContext,
    });
  }

  generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

    const collectionItemUpdate = this.generateCollectionItemUpdate();
    if (collectionItemUpdate) {
      optimisticUpdates.push(collectionItemUpdate);
    }

    const collectionUpdate = this.generateCollectionUpdate();
    if (collectionUpdate) {
      optimisticUpdates.push(collectionUpdate);
    }

    const spaceAccountCollectionUpdate = this.generateSpaceAccountCollectionUpdate();
    if (spaceAccountCollectionUpdate) {
      optimisticUpdates.push(spaceAccountCollectionUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute(): Promise<void> {
    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.note_id,
    });

    const spaceAccountCollectionId = uuidModule.resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.payload.collection_id,
    });

    await this.store.collections.recompute(this.payload.collection_id);
    await this.store.collectionItems.recompute(collectionItemId);
    await this.store.spaceAccountCollections.recompute(spaceAccountCollectionId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be added to {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to add {this.mediumNoteTitle} to {this.mediumCollectionTitle}.
        Please contact the collection owner.
      </>
    );
  }
  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be added to {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  protected generateCollectionItemUpdate(): OptimisticSyncUpdate<CollectionItemModelData> {
    const collectionItemValue: CollectionItemUpsertedSyncUpdateValue = {
      model_id: this.collectionItemId,
      model_kind: "COLLECTION_ITEM",
      model_version: 0,
      model_data: {
        collection_id: this.payload.collection_id,
        item_id: this.payload.note_id,
        item_kind: "NOTE",
        locally_created_at: this.committedAt,
        added_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
      },
      model_scopes: [],
    };

    const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionItemValue,
    };

    return collectionItemUpdate;
  }

  protected generateCollectionUpdate(): OptimisticSyncUpdate<CollectionModelData> | undefined {
    const collection = this.collection;
    if (!collection) {
      logger.debug({
        message: "Attempted to generate add note to collection update for non-existent collection",
        info: { collectionId: this.payload.collection_id },
      });

      return;
    }

    const collectionValue: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.collection_id,
      model_kind: "COLLECTION",
      model_version: collection.modelVersion,
      model_data: {
        ...collection.modelData,
        last_added_to_at: this.committedAt,
      },
      model_scopes: collection.modelScopes,
    };

    const collectionUpdate: OptimisticSyncUpdate<CollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionValue,
    };

    return collectionUpdate;
  }

  protected generateSpaceAccountCollectionUpdate():
    | OptimisticSyncUpdate<SpaceAccountCollectionModelData>
    | undefined {
    const collection = this.collection;
    if (!collection) {
      logger.debug({
        message: "Attempted to generate add note to collection update for non-existent collection",
        info: { collectionId: this.payload.collection_id },
      });

      return;
    }

    const spaceAccountCollection = collection?.spaceAccountCollection;
    const spaceAccountCollectionValue: SpaceAccountCollectionUpsertedSyncUpdateValue = {
      model_id: this.spaceAccountCollectionId,
      model_kind: "SPACE_ACCOUNT_COLLECTION",
      model_version: spaceAccountCollection?.modelVersion ?? 0,
      model_data: {
        space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        collection_id: this.payload.collection_id,
        last_viewed_at: spaceAccountCollection?.modelData.last_viewed_at || null,
        last_removed_from_at: spaceAccountCollection?.modelData.last_removed_from_at || null,
        last_added_to_at: this.committedAt,
        shared_at: spaceAccountCollection?.modelData.shared_at || null,
      },
      model_scopes: spaceAccountCollection
        ? spaceAccountCollection.modelScopes
        : [generateDefaultOwnerScopes({ store: this.store })],
    };

    const spaceAccountCollectionUpdate: OptimisticSyncUpdate<SpaceAccountCollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: spaceAccountCollectionValue,
    };

    return spaceAccountCollectionUpdate;
  }
}
