import { ChatMessageStore } from "@/domains/chat/chat-message-store";
import { Maybe } from "@/domains/common/types";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store/AppStore";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { ChatMessageIndexes } from "@/store/chat/ChatMessageIndexes";
import { ChatMessageObservable, GLOBAL_CONTEXT_ID } from "@/store/chat/ChatMessageObservable";
import {
  ChatMessageContext,
  ChatMessageContextKind,
  ChatMessageModelData,
  ChatMessageUpsertedSyncUpdateValue,
  IndexedChatMessage,
} from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SaveChatMessageOperation } from "@/store/sync/operations/chat/SaveChatMessageOperation";
import { SaveDraftNoteOperation } from "@/store/sync/operations/chat/SaveDraftNoteOperation";
import { SubmitChatMessageOperation } from "@/store/sync/operations/chat/SubmitChatMessageOperation";
import { SubmitChatMessageSyncOperationChatMessageContextValue } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { liveQuery, Subscription } from "dexie";
import {
  computed,
  makeObservable,
  runInAction,
  action,
  override,
  onBecomeObserved,
  observable,
} from "mobx";

export class AppStoreChatMessageStore
  extends BaseSyncModelStore<ChatMessageObservable, ChatMessageModelData>
  implements ChatMessageStore
{
  liveQuerySubscription: Maybe<Subscription>;
  sortedChatMessageIndexes: IndexedChatMessage[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatMessage, ...injectedDeps });
    makeObservable<this>(this, {
      getContextFromIndexedMessage: true,
      liveQuerySubscription: true,
      computeIndexes: override,
      createSyncModel: false,
      sendNewMessage: action,
      saveChatMessage: action,
      saveDraftNoteSection: action,
      processSyncUpdate: override,
      processLiveSyncUpdate: action,
      // sortedMessages: computed,
      chatHistory: computed,
      unsentMessages: computed,
      lastSystemMessageId: computed,
      lastUserMessageId: computed,
      sortedChatMessageIndexes: observable,
    });

    onBecomeObserved(this, "sortedChatMessageIndexes", () => {
      this.liveQuerySubscription?.unsubscribe();
      this.liveQuerySubscription = liveQuery(() =>
        this.localTable
          .orderBy(
            "[locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]"
          )
          .keys()
      ).subscribe({
        next: ids => {
          runInAction(() => {
            this.sortedChatMessageIndexes = ids as unknown as IndexedChatMessage[]; // safe to cast as long as we're sure ids are strings
          });
        },
      });
    });
  }

  createSyncModel(data: SyncUpdateValue<ChatMessageModelData>): ChatMessageObservable {
    return new ChatMessageObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get lastUserMessageId(): string | undefined {
    return this.sortedChatMessageIndexes.findLast(([_, isSystemMessage]) => !isSystemMessage)?.[5];
  }

  get lastSystemMessageId(): string | undefined {
    return this.sortedChatMessageIndexes.findLast(([_, isSystemMessage]) => isSystemMessage)?.[5];
  }

  get unsentMessages(): ChatMessageObservable[] {
    const result: ChatMessageObservable[] = [];
    for (let i = this.sortedChatMessageIndexes.length - 1; i >= 0; i--) {
      const [, isSystemMessage, status, , , modelId] = this.sortedChatMessageIndexes[i];
      if (isSystemMessage) {
        continue;
      }

      if (status !== "PROCESSING") {
        break;
      }

      const message = this.get(modelId);
      if (message) {
        result.push(message);
      }
    }

    return result.reverse();
  }

  get chatHistory() {
    const collectionId = this.store.routing.collectionIdParam;
    let context: Maybe<ChatMessageContext>;
    if (collectionId)
      context = {
        kind: ChatMessageContextKind.CollectionDetailView,
        id: collectionId,
        observable: () => this.store.collections.get(collectionId),
      };

    const noteId = this.store.routing.noteIdParam;
    if (noteId) {
      context = {
        kind: ChatMessageContextKind.NoteDetailView,
        id: noteId,
        observable: () => this.store.notes.get(noteId),
      };
    }

    return new ChatHistory({
      context,
      store: this.store,
      indexes: this.sortedChatMessageIndexes.map(indexedMessage => ({
        locallyCreatedAt: indexedMessage[0],
        isSystemMessage: indexedMessage[1],
        status: indexedMessage[2],
        context: this.getContextFromIndexedMessage(indexedMessage),
        id: indexedMessage[5],
      })),
    });
  }

  getContextFromIndexedMessage(indexedMessage: IndexedChatMessage): ChatMessageContext {
    const globalContext = {
      kind: ChatMessageContextKind.Global,
      id: GLOBAL_CONTEXT_ID,
    };

    const contextKind = indexedMessage[4].find(
      kind =>
        kind === ChatMessageContextKind.NoteDetailView ||
        kind === ChatMessageContextKind.CollectionDetailView
    ) as Maybe<ChatMessageContextKind>;

    if (!contextKind) {
      return globalContext;
    }

    const contextId = indexedMessage[3][indexedMessage[4].indexOf(contextKind)];
    if (!contextId) {
      return globalContext;
    }

    return {
      kind: contextKind,
      id: contextId,
    };
  }

  async sendNewMessage(message: string, context?: ChatMessageContext, contextStartedAt?: string) {
    await this.store.chatConversations.generatePrimaryChatConversationIfNeeded();
    const primaryChatConversation = this.store.chatConversations.primaryChatConversation;
    if (!primaryChatConversation) return;

    const operationContext: SubmitChatMessageSyncOperationChatMessageContextValue = (() => {
      if (context?.kind === ChatMessageContextKind.NoteDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "NOTE_DETAIL_VIEW",
          value: { schema_version: 1, note_id: context.id },
        };
      }
      if (context?.kind === ChatMessageContextKind.CollectionDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "COLLECTION_DETAIL_VIEW",
          value: { schema_version: 1, collection_id: context.id },
        };
      }
      return {
        context_id: uuidModule.generate(),
        started_at: contextStartedAt,
        kind: "GLOBAL",
        value: { schema_version: 1 },
      };
    })();

    const messageId = uuidModule.generate();

    // TODO: context id shouldn't be regenerated every time
    await new SubmitChatMessageOperation({
      store: this.store,
      payload: {
        id: messageId,
        chat_conversation_id: primaryChatConversation.id,
        content: message,
        contexts: [operationContext],
      },
    }).execute();

    /**
     * Track the message send event
     */
    trackEvent(TrackedEvent.ChatMessageSend, {
      message_id: messageId,
      message_contents: message,
      context: context?.kind,
      note_id: context?.kind === ChatMessageContextKind.NoteDetailView ? context.id : undefined,
      note_title:
        context?.kind === ChatMessageContextKind.NoteDetailView
          ? this.store.notes.get(context.id)?.title
          : undefined,
      collection_id:
        context?.kind === ChatMessageContextKind.CollectionDetailView ? context.id : undefined,
      collection_title:
        context?.kind === ChatMessageContextKind.CollectionDetailView
          ? this.store.collections.get(context.id)?.title
          : undefined,
    });
  }

  async saveChatMessage({ chatMessageId, noteId }: { chatMessageId: string; noteId: string }) {
    const chatMessage = await this.getAsync(chatMessageId);
    if (!chatMessage) {
      return;
    }

    const encodedContent = notesModule.convertMdxToEncodedContent(chatMessage.content);

    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(
      new SaveChatMessageOperation({
        store: this.store,
        payload: {
          chat_message_id: chatMessageId,
          saved_note_id: noteId,
          encoded_content: encodedContent,
        },
      })
    );
  }

  async saveDraftNoteSection({
    sectionId,
    chatMessageId,
    noteId,
  }: {
    sectionId: string;
    chatMessageId: string;
    noteId: string;
  }) {
    const chatMessage = this.get(chatMessageId);
    const section = chatMessage?.sectionMap[sectionId];
    if (!chatMessage || !section) return;
    const encodedContent = notesModule.convertMdxToEncodedContent(section.value.content);
    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(
      new SaveDraftNoteOperation({
        store: this.store,
        payload: {
          chat_message_id: chatMessageId,
          chat_message_section_id: sectionId,
          saved_note_id: noteId,
          encoded_content: encodedContent,
        },
      })
    );
  }

  public async processSyncUpdate(update: SyncUpdate<ChatMessageModelData>) {
    await this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(update.value.model_id);
    await super.processSyncUpdate(update);
  }

  async processLiveSyncUpdate(
    sync_operation_id: string,
    value: ChatMessageUpsertedSyncUpdateValue
  ) {
    // We should only have one pending optimistic update for chat messages + there are too many if we leave them
    await this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(value.model_id);
    const optimisticUpdate: OptimisticSyncUpdate<ChatMessageModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: value.model_data.locally_created_at,
      kind: "UPSERTED",
      value: value,
    };
    await this.store.sync.actionQueue.applyOptimisticUpdate(sync_operation_id, optimisticUpdate);
    await this.recompute(optimisticUpdate.value.model_id);
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ChatMessageModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ChatMessageModelData>[];
  }): Record<string, unknown> {
    return new ChatMessageIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
