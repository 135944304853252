import { filter, uniqBy } from "lodash-es";
import { computed, makeObservable, observable, reaction, action } from "mobx";
import React from "react";

import { INoteObservable } from "@/store/note";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsDropdownButton,
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsItem,
  MdsItemKind,
  MdsItemListCarouselRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSectionHeaderRowData,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import {
  OwnedBySpaceAccountFacetFilter,
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFilter,
  SearchEngineResponse,
} from "@/store/pages/SearchPageStore/types";
import { ApiSchema, api } from "@/modules/api";
import { generateRecentDateString, generateShortDateString } from "@/domains/date/date";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { OnClick } from "@/design-system/constants/handlers/types";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { MdsButton, MdsButtonSize } from "@/design-system/components/button";
import {
  MdsButtonIconPosition,
  MdsButtonShape,
  MdsButtonVariant,
  MdsSelectionVariant,
} from "@/design-system/components/button/types";
import {
  captureSourceDefs,
  linkKindDefs,
  mediaKindDefs,
} from "@/store/pages/SearchPageStore/facetFilterDefinitions";
import { css, cx } from "@/domains/emotion";
import { Maybe } from "@/domains/common/types";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { resolveSavedSearchSyncModelUuid } from "@/modules/uuid/sync-models/resolveSavedSearchSyncModelUuid";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { SpaceAccountObservable } from "@/store/space-account/observables/SpaceAccountObservable";
import { appRoutes } from "@/app/router";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { getMdsCarouselItemForCollection } from "@/design-system/components/item-list/rows/getMdsCarouselItemForCollection";
import { RemoveSavedSearchFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveSavedSearchFromFavoritesOperation";
import { AddSavedSearchToFavoritesOperation } from "@/store/sync/operations/favorites/AddSavedSearchToFavoritesOperation";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { SearchResultVotingButtons } from "@/store/pages/SearchPageStore/searchResultVotingButtons";
import { mdsColors } from "@/design-system/foundations";
import { MdsSeparator } from "@/design-system/components/separator";
import { MdsSeparatorKind } from "@/design-system/components/separator/types";
import {
  ProfileInfo,
  ProfileKind,
  ProfileSize,
} from "@/components/layout/components/account-profile";
import {
  SearchEngineFacetFilterKind,
  SearchEngineFilterKind,
  SearchEngineParams,
  SearchEngineSortByKind,
} from "@/modules/url-params/search-engine-params/types";
import { urlParamsModule } from "@/modules/url-params";
import { PaginatableApiQueryObservable } from "@/store/queries/common/PaginatableApiQueryObservable";
import { SearchEngineResult } from "@/modules/url-params/search-engine-params/types";
import { createSearchEngineApiQueryObservable } from "@/store/queries/search/createSearchEngineApiQueryObservable";
import SearchResultDebugInfo from "@/store/pages/SearchPageStore/searchResultDebugInfo";
import { AccountObservable } from "@/store/account/observables/AccountObservable";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

export interface ISearchPageStore {
  isSuccess: boolean;
  isLoading: boolean;
  hasNoItems: boolean;
  itemRows: MdsItemListRowData[];
  activeSearchEngineParams: SearchEngineParams;
  listState: {
    useListStateEffects: () => void;
  };
  addToCollectionModal: { selectedNoteIds: string[] };
}

// SearchPage can be rendered from the home page too when facet filters are applied.

export class SearchPageStore extends AppSubStore implements ISearchPageStore {
  private responses: SearchEngineResponse[] = [];

  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  addToCollectionModal: AddToCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;
  apiQuery: PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult>;
  previousSearchEngineQueryString: string | null = null;

  eventContext = EventContext.SearchMultiselectActions;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    this.apiQuery = this.createApiQueryObservable();

    makeObservable<
      this,
      | "responses"
      | "generateNoteDropdown"
      | "generateCollectionDropdown"
      | "updateSearchEngineParams"
      | "getContactOrAccount"
    >(this, {
      responses: observable,
      supportsAddToCollectionListAction: observable,
      supportsMoveToTrashListAction: observable,
      listState: false,
      addToCollectionModal: false,
      shareSheetModal: false,
      apiQuery: observable,
      previousSearchEngineQueryString: observable,
      createApiQueryObservable: false,
      sortOptions: computed,
      activeSearchEngineParams: computed,
      activeSearchEngineQueryString: computed,
      sortLabel: computed,
      uiFilters: computed,
      load: false,
      generateNoteDropdown: false,
      generateCollectionDropdown: false,
      isLoading: computed,
      isSuccess: computed,
      itemRows: computed,
      orderedItemIds: computed,
      isFavorited: computed,
      favoriteItemId: computed,
      id: computed,
      isOnSearchPage: computed,
      setSortBy: action,
      addFilter: action,
      removeFilter: action,
      addFacetFilter: action,
      removeFacetFilter: action,
      updateSearchEngineParams: action,
      toggleFavorite: action,
      hasNoItems: computed,
      getContactOrAccount: false,
      eventContext: false,
    });

    reaction(
      () => this.activeSearchEngineQueryString,
      value => {
        /**
         * Regenerate the query observable when the search query changes.
         */
        if (!this.isOnSearchPage) {
          return;
        }

        if (value === this.previousSearchEngineQueryString) {
          return;
        }

        this.apiQuery = this.createApiQueryObservable();
        this.apiQuery.fetchData();
        this.previousSearchEngineQueryString = value;
      }
    );
  }

  createApiQueryObservable(): PaginatableApiQueryObservable<
    SearchEngineResponse,
    SearchEngineResult
  > {
    return createSearchEngineApiQueryObservable({
      getSpaceId: () => this.store.spaces.myPersonalSpaceId,
      searchParams: this.activeSearchEngineParams,
      apiClient: api,
    });
  }

  get sortOptions(): MdsDropdownContentList {
    const sortBy = this.activeSearchEngineParams.sortBy;

    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: "RELEVANCE",
          kind: MdsDropdownItemKind.Button,
          label: "Relevance",
          isChecked: sortBy === SearchEngineSortByKind.Relevance,
          onClick: this.setSortBy,
        },
        {
          id: "LAST_MODIFIED",
          kind: MdsDropdownItemKind.Button,
          label: "Last modified",
          isChecked: sortBy === SearchEngineSortByKind.LastModified,
          onClick: this.setSortBy,
        },
        {
          id: "LAST_CREATED",
          kind: MdsDropdownItemKind.Button,
          label: "Last created",
          isChecked: sortBy === SearchEngineSortByKind.LastCreated,
          onClick: this.setSortBy,
        },
      ],
    };
  }

  get activeSearchEngineParams(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.store.navigation.activeSearchQuery,
    });
  }

  get activeSearchEngineQueryString(): string {
    return urlParamsModule.search.stringify({
      searchParams: this.activeSearchEngineParams,
    });
  }

  get sortLabel(): string {
    const selectedSortOption = this.sortOptions.items.find(
      e => e.id === this.activeSearchEngineParams.sortBy
    );

    return selectedSortOption && "label" in selectedSortOption ? selectedSortOption.label : "-";
  }

  get uiFilters() {
    const filters: { id: string; kind: string; content: React.ReactNode }[] = [];

    if (!this.store.sync.isReady) {
      return filters;
    }

    const iconLabel = (icon: React.ReactNode, label: string) => () => (
      <div className={cx(compositeLabelStyles)}>
        {icon}
        <span>{label}</span>
      </div>
    );
    const addButton = (
      label: string,
      icon: React.ReactNode,
      onClick: OnClick,
      kind: string,
      id?: string
    ) => {
      filters.push({
        id: id ?? label,
        kind,
        content: (
          <MdsButton
            className={noWrapStyles}
            shape={MdsButtonShape.Round}
            variant={MdsButtonVariant.Outlined}
            size={MdsButtonSize.Medium}
            label={iconLabel(icon, label)}
            iconPosition={MdsButtonIconPosition.Right}
            iconKind={MdsIconKind.Exit}
            onClick={onClick}
            isSelected={true}
            selectionVariant={MdsSelectionVariant.Accent}
          />
        ),
      });
    };
    const dateLabel = (prefix: string, dateString: string) =>
      `${prefix} ${generateRecentDateString(dateString)}`;

    for (const activeFilter of this.activeSearchEngineParams.filters) {
      const onClick = () => this.removeFilter(activeFilter);
      switch (activeFilter.kind) {
        case SearchEngineFilterKind.CreatedBefore: {
          addButton(
            dateLabel(
              "Created before",
              (activeFilter.value as ApiSchema["SearchEngineBaseFiltersCreatedBeforeValue"])
                .created_before
            ),
            <MdsIcon kind={MdsIconKind.Calendar} />,
            onClick,
            activeFilter.kind
          );
          break;
        }
        case SearchEngineFilterKind.CreatedAfter: {
          addButton(
            dateLabel(
              "Created after",
              (activeFilter.value as ApiSchema["SearchEngineBaseFiltersCreatedAfterValue"])
                .created_after
            ),
            <MdsIcon kind={MdsIconKind.Calendar} />,
            onClick,
            activeFilter.kind
          );
          break;
        }
        case SearchEngineFilterKind.ModifiedBefore: {
          addButton(
            dateLabel(
              "Modified before",
              (activeFilter.value as ApiSchema["SearchEngineBaseFiltersModifiedBeforeValue"])
                .modified_before
            ),
            <MdsIcon kind={MdsIconKind.Calendar} />,
            onClick,
            activeFilter.kind
          );
          break;
        }
        case SearchEngineFilterKind.ModifiedAfter: {
          addButton(
            dateLabel(
              "Modified after",
              (activeFilter.value as ApiSchema["SearchEngineBaseFiltersModifiedAfterValue"])
                .modified_after
            ),
            <MdsIcon kind={MdsIconKind.Calendar} />,
            onClick,
            activeFilter.kind
          );
          break;
        }
      }
    }

    const missingDefinitionIcon = <MdsIcon kind={MdsIconKind.Question} />;
    for (const filter of this.activeSearchEngineParams.facetFilters) {
      const onClick = () => this.removeFacetFilter(filter);

      switch (filter.kind) {
        case SearchEngineFacetFilterKind.ContainsLinkKind: {
          const linkKinds = (
            filter as ApiSchema["SearchEngineRequestFacetFiltersSearchEngineFacetFiltersContainsClassicLinkKind"]
          ).value.link_kinds;

          const firstKind = linkKinds[0];
          // Ignoring multiple link kinds for now.
          if (!firstKind) break;

          const def = linkKindDefs[firstKind];
          addButton(
            def?.buttonLabel ?? `With ${firstKind.toLowerCase()} link`,
            def?.icon ?? missingDefinitionIcon,
            onClick,
            firstKind
          );
          break;
        }
        case SearchEngineFacetFilterKind.ContainsMediaKind: {
          const mediaKinds = (
            filter as ApiSchema["SearchEngineRequestFacetFiltersSearchEngineFacetFiltersContainsMediaKind"]
          ).value.media_kinds;

          const firstKind = mediaKinds[0];
          // Ignoring multiple link kinds for now.
          if (!firstKind) break;

          const def = mediaKindDefs[firstKind];
          addButton(
            def?.buttonLabel ?? `With ${firstKind.toLowerCase()} link`,
            def?.icon ?? missingDefinitionIcon,
            onClick,
            firstKind
          );
          break;
        }
        case SearchEngineFacetFilterKind.OwnedBySpaceAccount: {
          const ownedBySpacedAccountFilter = filter as OwnedBySpaceAccountFacetFilter;
          const spaceAccountIds = ownedBySpacedAccountFilter.value.space_account_ids;
          // Ignoring multiple ids for now.
          const spaceAccountId = spaceAccountIds[0];
          const [contactOrAccount, isMe] = this.getContactOrAccount(spaceAccountId);
          if (!contactOrAccount) break;

          if (isMe) {
            addButton(
              ownedBySpacedAccountFilter.config?.negate ? "Shared with me" : "Added by me",
              ownedBySpacedAccountFilter.config?.negate ? (
                <MdsIcon kind={MdsIconKind.Shared} />
              ) : (
                <AccountProfileImage
                  profile={{ kind: ProfileKind.Me }}
                  size={ProfileSize.Small}
                  className={negativeMarginStyles}
                />
              ),
              onClick,
              spaceAccountId
            );
            break;
          }

          addButton(
            `Owned by ${contactOrAccount.profileDisplayName}`,
            <AccountProfileImage
              profile={{ kind: ProfileKind.Contact, data: contactOrAccount as ContactObservable }}
              size={ProfileSize.Small}
              className={negativeMarginStyles}
            />,
            onClick,
            spaceAccountId
          );
          break;
        }
        case SearchEngineFacetFilterKind.ModifiedBySpaceAccount: {
          const spaceAccountIds = (
            filter as ApiSchema["SearchEngineRequestFacetFiltersSearchEngineFacetFiltersModifiedBySpaceAccount"]
          ).value.space_account_ids;
          // Ignoring multiple ids for now.
          const spaceAccountId = spaceAccountIds[0];
          const [contactOrAccount, isMe] = this.getContactOrAccount(spaceAccountId);

          if (!contactOrAccount) break;

          addButton(
            isMe ? `Edited by me` : `Edited by ${contactOrAccount?.profileDisplayName}`,
            isMe ? (
              <AccountProfileImage
                profile={{ kind: ProfileKind.Me }}
                size={ProfileSize.Small}
                className={negativeMarginStyles}
              />
            ) : (
              <AccountProfileImage
                profile={{ kind: ProfileKind.Contact, data: contactOrAccount as ContactObservable }}
                size={ProfileSize.Small}
                className={negativeMarginStyles}
              />
            ),
            onClick,
            spaceAccountId
          );

          break;
        }
        case SearchEngineFacetFilterKind.FromCaptureSource: {
          const sources = (
            filter as ApiSchema["SearchEngineRequestFacetFiltersSearchEngineFacetFiltersFromCaptureSource"]
          ).value.capture_sources;

          const firstSource = sources[0];
          // Ignoring multiple sources for now.
          if (!firstSource) break;

          const def = captureSourceDefs[firstSource];
          addButton(
            def?.buttonLabel ?? `From ${firstSource.toLowerCase()}`,
            def?.icon ?? missingDefinitionIcon,
            onClick,
            firstSource
          );
          break;
        }
        case SearchEngineFacetFilterKind.InCollection: {
          const collectionId = (
            filter as ApiSchema["SearchEngineRequestFacetFiltersSearchEngineFacetFiltersInCollection"]
          ).value.collection_ids[0];
          // Ignoring multiple collections for now.
          const collection = this.store.collections.getCollectionObservableById({ collectionId });
          if (!collection) break;

          addButton(
            `In ${collection.label}`,
            <MdsIcon className="mds-btn-icon" kind={MdsIconKind.Collection} />,
            onClick,
            collectionId
          );
          break;
        }
      }
    }

    const separator = {
      id: "separator",
      kind: "separator",
      content: (
        <MdsSeparator
          kind={MdsSeparatorKind.Pipe}
          className={filterSeparatorStyles}
          iconClassName={filterSeparatorIconStyles}
        />
      ),
      // content: <MdsIcon kind={MdsIconKind.Pipe} className={filterSeparatorStyles} />,
    };
    if (filters.length && separator) {
      filters.push(separator);
    }

    const addDropdown = (
      label: string,
      kind: string,
      icon: React.ReactNode,
      buttonItems: Maybe<MdsDropdownButtonItem>[]
    ) => {
      const items = filter(uniqBy(buttonItems, e => e?.id)) as MdsDropdownButtonItem[];
      if (!items.length) return;

      filters.push({
        id: label,
        kind,
        content: (
          <MdsDropdownButton
            label={iconLabel(icon, label)}
            shape={MdsButtonShape.Round}
            size={MdsButtonSize.Medium}
            variant={MdsButtonVariant.Outlined}
            iconKind={MdsIconKind.AngleDown}
            iconPosition={MdsButtonIconPosition.Right}
            contentList={{ items }}
          />
        ),
      });
    };

    const { directContacts } = this.store.contacts;
    if (directContacts.length) {
      const contactsOrUserAccount: (ContactObservable | SpaceAccountObservable)[] = [
        ...directContacts,
      ];
      const { myPersonalSpaceAccount } = this.store.spaceAccounts;
      if (myPersonalSpaceAccount) {
        contactsOrUserAccount.unshift(myPersonalSpaceAccount);
      }
      {
        const onClick = ({ itemId }: { itemId: string }) => {
          this.addFacetFilter({
            kind: "OWNED_BY_SPACE_ACCOUNT",
            value: { space_account_ids: [itemId] },
          });
        };
        addDropdown(
          "Created by",
          "OWNED_BY_SPACE_ACCOUNT",
          <MdsIcon kind={MdsIconKind.User} />,
          contactsOrUserAccount.map(contactOrAccount => {
            if (
              filters.find(e => e.kind === "OWNED_BY_SPACE_ACCOUNT" && e.id === contactOrAccount.id)
            ) {
              return;
            }

            const profileInfo: ProfileInfo =
              contactOrAccount instanceof SpaceAccountObservable
                ? { kind: ProfileKind.SpaceAccount, data: contactOrAccount }
                : { kind: ProfileKind.Contact, data: contactOrAccount };

            const item: MdsDropdownButtonItem = {
              id:
                contactOrAccount instanceof SpaceAccountObservable
                  ? contactOrAccount.id
                  : contactOrAccount.contactSpaceAccountId,
              kind: MdsDropdownItemKind.Button,
              iconKind: () => (
                <AccountProfileImage
                  profile={profileInfo}
                  size={ProfileSize.Small}
                  className={negativeMarginStyles}
                />
              ),
              label: contactOrAccount.profileDisplayName,
              onClick,
            };
            return item;
          })
        );
      }

      {
        const onClick = ({ itemId }: { itemId: string }) => {
          this.addFacetFilter({
            kind: "MODIFIED_BY_SPACE_ACCOUNT",
            value: { space_account_ids: [itemId] },
          });
        };
        addDropdown(
          "Edited by",
          "MODIFIED_BY_SPACE_ACCOUNT",
          <MdsIcon kind={MdsIconKind.User} />,
          contactsOrUserAccount.map(contactOrAccount => {
            if (
              filters.find(
                e => e.kind === "MODIFIED_BY_SPACE_ACCOUNT" && e.id === contactOrAccount.id
              )
            ) {
              return;
            }

            const profileInfo: ProfileInfo =
              contactOrAccount instanceof SpaceAccountObservable
                ? { kind: ProfileKind.SpaceAccount, data: contactOrAccount }
                : { kind: ProfileKind.Contact, data: contactOrAccount };

            const item: MdsDropdownButtonItem = {
              id:
                contactOrAccount instanceof SpaceAccountObservable
                  ? contactOrAccount.id
                  : contactOrAccount.contactSpaceAccountId,
              kind: MdsDropdownItemKind.Button,
              iconKind: () => (
                <AccountProfileImage
                  profile={profileInfo}
                  size={ProfileSize.Small}
                  className={negativeMarginStyles}
                />
              ),
              label: contactOrAccount.profileDisplayName,
              onClick,
            };
            return item;
          })
        );
      }
    }

    {
      const onClick = ({ itemId }: { itemId: string }) => {
        this.addFacetFilter({
          kind: "IN_COLLECTION",
          value: { collection_ids: [itemId] },
        });
      };
      addDropdown(
        "Collections",
        "IN_COLLECTION",
        <MdsIcon kind={MdsIconKind.Collection} />,
        this.store.collections.all
          .sort((a, b) => b.lastInteractedAt.localeCompare(a.lastInteractedAt))
          .map(collection => {
            if (filters.find(e => e.kind === "IN_COLLECTION" && e.id === collection.id)) {
              return;
            }
            const item: MdsDropdownButtonItem = {
              id: collection.id,
              kind: MdsDropdownItemKind.Button,
              iconKind: MdsIconKind.Collection,
              label: collection.label,
              onClick,
            };
            return item;
          })
      );
    }

    // TODO: Uncomment when we support these filters.

    // {
    //   const onClick = ({ itemId }: { itemId: string }) => {
    //     this.addFacetFilter({
    //       kind: "CONTAINS_LINK_KIND",
    //       value: { link_kinds: [itemId] },
    //     });
    //   };
    //   addDropdown(
    //     "Links",
    //     "CONTAINS_LINK_KIND",
    //     <MdsIcon kind={MdsIconKind.LinkSolid} />,
    //     Object.entries(linkKindDefs).map(([kind, def]) => {
    //       if (filters.find(e => e.kind === "CONTAINS_LINK_KIND" && e.id === kind)) {
    //         return;
    //       }
    //       const item: MdsDropdownButtonItem = {
    //         id: kind,
    //         kind: MdsDropdownItemKind.Button,
    //         iconSize: 16,
    //         iconKind: () => def.icon,
    //         label: def.dropdownLabel,
    //         onClick,
    //       };
    //       return item;
    //     })
    //   );
    // }

    // {
    //   const onClick = ({ itemId }: { itemId: string }) => {
    //     this.addFacetFilter({
    //       kind: "CONTAINS_MEDIA_KIND",
    //       value: { media_kinds: [itemId] },
    //     });
    //   };
    //   addDropdown(
    //     "Contains",
    //     "CONTAINS_MEDIA_KIND",
    //     <MdsIcon kind={MdsIconKind.Shapes} />,
    //     Object.entries(mediaKindDefs).map(([kind, def]) => {
    //       if (filters.find(e => e.kind === "CONTAINS_LINK_KIND" && e.id === kind)) {
    //         return;
    //       }
    //       const item: MdsDropdownButtonItem = {
    //         id: kind,
    //         kind: MdsDropdownItemKind.Button,
    //         iconSize: 16,
    //         iconKind: () => def.icon,
    //         label: def.dropdownLabel,
    //         onClick,
    //       };
    //       return item;
    //     })
    //   );
    // }

    // {
    //   const onClick = ({ itemId }: { itemId: string }) => {
    //     this.addFacetFilter({
    //       kind: "FROM_CAPTURE_SOURCE",
    //       value: { capture_sources: [itemId] },
    //     });
    //   };
    //   addDropdown(
    //     "Captured with",
    //     "FROM_CAPTURE_SOURCE",
    //     <MdsIcon kind={MdsIconKind.PhoneSms} />,
    //     Object.entries(captureSourceDefs).map(([source, def]) => {
    //       if (filters.find(e => e.kind === "FROM_CAPTURE_SOURCE" && e.id === source)) {
    //         return;
    //       }
    //       const item: MdsDropdownButtonItem = {
    //         id: source,
    //         kind: MdsDropdownItemKind.Button,
    //         iconSize: 16,
    //         iconKind: () => def.icon,
    //         label: def.dropdownLabel,
    //         onClick,
    //       };
    //       return item;
    //     })
    //   );
    // }

    if (filters[filters.length - 1] === separator) {
      filters.pop();
    }

    if (!filters.length) return [];

    return filters;
  }

  load = () => {
    this.apiQuery.fetchData();
  };

  private generateNoteDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: async () => await noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () =>
            this.addToCollectionModal.open({
              noteIds: [noteObservable.id],
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: async () => await noteObservable.moveToTrash(),
        },
      ],
    };
  }

  private generateCollectionDropdown({
    collectionObservable,
  }: {
    collectionObservable: CollectionObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: collectionObservable.id,
              entityKind: ShareSheetEntityKind.Collection,
              eventContext: EventContext.SearchRowActions,
            }),
        },
        {
          id: `copy-link-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => {
            actions.copyCollectionLinkToClipboard({ collectionId: collectionObservable.id });
          },
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `delete-${collectionObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: () =>
            actions.deleteCollectionPermanently({
              collection: collectionObservable,
              store: this.store,
            }),
        },
      ],
    };
  }

  get isLoading() {
    return this.apiQuery.isLoading;
  }

  get isSuccess() {
    return this.apiQuery.isSuccess;
  }

  get itemRows(): MdsItemListRowData[] {
    const rows: MdsItemListRowData[] = [];
    const lastHeading = "";
    async function submitClickFeedback(
      trackingId: string,
      pageCursor: string | null,
      searchSessionId: string,
      userSpaceId: string
    ) {
      const response = await api.post("/v2/search/feedback", {
        params: {
          query: {
            space_id: userSpaceId,
          },
        },
        body: {
          search_engine_result_id: trackingId,
          search_session_page_cursor: pageCursor,
          session_id: searchSessionId,
          feedback_kind: "CLICK",
        },
      });

      if (response.error) {
        console.warn("Error in submitting the click feedback: ", response.error);
      }
    }

    let notePosition = 0;
    let collectionPosition = 0;
    let carouselPosition = 0;

    for (const result of this.apiQuery.items) {
      switch (result.kind) {
        case "HEADING": {
          const title = (result.value as ApiSchema["SearchEngineResultSectionHeadingValue"]).label;

          // If a following response returns the continuation of the previous heading.
          if (title === lastHeading) break;

          const row: MdsItemListSectionHeaderRowData = {
            type: MdsItemListRowType.SectionHeader,
            payload: {
              title,
            },
            key: `heading-${title}`,
          };
          rows.push(row);
          break;
        }
        case "ITEM": {
          const item = result.value as ApiSchema["SearchEngineResultSectionItemValue"];
          switch (item.kind) {
            case "NOTE": {
              const value = item.value as ApiSchema["SearchEngineResultSectionItemNoteValue"];
              const id = value.note_id;

              const noteObservable = this.store.notes.getNoteObservableById({ noteId: id });
              if (
                !noteObservable ||
                noteObservable.isTrashed ||
                noteObservable.isDeleted ||
                !noteObservable.canAccess
              ) {
                continue;
              }

              const collectionObservables = filter(
                value.featured_collection_ids?.map(id =>
                  this.store.collections.getCollectionObservableById({
                    collectionId: id,
                  })
                )
              ) as CollectionObservable[];

              const hasExtraRows =
                !!value.featured_collection_ids?.length || !!value.highlighted_snippets?.length;

              const searchSessionCursor = value.metadata.search_session_cursor;
              const searchSessionId = this.apiQuery.latestSearchSessionData?.searchSessionId;
              const mdsItem: MdsItem = {
                id,
                kind: MdsItemKind.Note,
                createPreviewState: () =>
                  new ItemPreviewState({
                    store: this.store,
                    id,
                    kind: MdsItemKind.Note,
                  }),
                label: noteObservable.title,
                rightAlignedComponent: (
                  <>
                    {searchSessionId && (
                      <SearchResultVotingButtons
                        itemId={id}
                        searchEngineResultId={value.metadata.search_engine_result_id}
                        pageCursor={searchSessionCursor}
                        searchSessionId={searchSessionId}
                        userSpaceId={this.store.spaces.myPersonalSpaceId}
                        additionalElements={
                          value.metadata.source_debug_info && (
                            <SearchResultDebugInfo
                              rawScoreFromSource={value.metadata.source_debug_info.raw_score}
                              rerankerScore={value.metadata.source_debug_info.reranker_score}
                              preAdjustedScore={value.metadata.source_debug_info.pre_adjusted_score}
                              source={value.metadata.source_debug_info.kind}
                            />
                          )
                        }
                      />
                    )}
                  </>
                ),
                onClick: () => {
                  if (!searchSessionId) {
                    console.warn("No search session id found. Feedback buttons not rendered.");
                    return;
                  }
                  submitClickFeedback(
                    value.metadata.search_engine_result_id,
                    searchSessionCursor,
                    searchSessionId,
                    this.store.spaces.myPersonalSpaceId
                  );
                  this.store.navigation.goToNote({ noteId: id });

                  /**
                   * Track the navigation to a note
                   */
                  trackEvent(TrackedEvent.SearchResultsOpenNote, {
                    query: this.activeSearchEngineQueryString,
                    sort_by: this.activeSearchEngineParams.sortBy,
                    position: notePosition,
                    note_id: id,
                    note_primary_label: noteObservable.title,
                  });
                },
                sharedBy: noteObservable.sharedBy,
                dateLabel: generateShortDateString(
                  this.activeSearchEngineParams.sortBy === SearchEngineSortByKind.LastCreated
                    ? noteObservable.createdAt
                    : noteObservable.modifiedAt
                ),
                dropdown: this.generateNoteDropdown({ noteObservable }),
                listState: this.listState,
                extraRows: hasExtraRows
                  ? [
                      {
                        id: `note-${id}-content`,
                        content: () => (
                          <MdsItemListRowFeaturedContent
                            collections={sortCollectionsForChips(collectionObservables)}
                            snippet={value.highlighted_snippets?.map(({ text, is_highlight }) => ({
                              text,
                              isHighlight: is_highlight,
                            }))}
                          />
                        ),
                      },
                    ]
                  : undefined,
              };

              rows.push({
                type: MdsItemListRowType.Item,
                key: id,
                payload: mdsItem,
                size: hasExtraRows ? MdsItemListSize.XLarge : MdsItemListSize.Medium,
              });
              notePosition++;
              break;
            }
            case "COLLECTION": {
              const value = item.value as ApiSchema["SearchEngineResultSectionItemCollectionValue"];
              const id = value.collection_id;
              // TODO: delay until render time.
              const collectionObservable = this.store.collections.getCollectionObservableById({
                collectionId: id,
              });
              if (!collectionObservable) break;

              rows.push({
                type: MdsItemListRowType.Item,
                key: id,
                payload: {
                  id,
                  kind: MdsItemKind.Collection,
                  createPreviewState: () =>
                    new ItemPreviewState({
                      store: this.store,
                      id: id,
                      kind: MdsItemKind.Collection,
                    }),
                  label: collectionObservable.label,
                  onClick: () => {
                    this.store.navigation.goToCollection({ collectionId: id });

                    /**
                     * Track the navigation to a collection
                     */
                    trackEvent(TrackedEvent.SearchResultsOpenCollection, {
                      query: this.activeSearchEngineQueryString,
                      sort_by: this.activeSearchEngineParams.sortBy,
                      position: collectionPosition,
                      collection_id: id,
                      collection_primary_label: collectionObservable.label,
                    });
                  },
                  sharedBy: collectionObservable.sharedBy,
                  dateLabel: generateShortDateString(
                    this.activeSearchEngineParams.sortBy === SearchEngineSortByKind.LastCreated
                      ? collectionObservable.createdAt
                      : collectionObservable.modifiedAt
                  ),
                  dropdown: this.generateCollectionDropdown({ collectionObservable }),
                  listState: this.listState,
                },
              });
              collectionPosition++;
              break;
            }
          }
          break;
        }
        case "CAROUSEL": {
          const value = result.value as ApiSchema["SearchEngineResultSectionCarouselValue"];
          const carousel: MdsItemListCarouselRowData = {
            type: MdsItemListRowType.Carousel,
            key: `carousel-${rows.length}`,
            payload: {
              items: [],
            },
          };
          for (const item of value.items) {
            switch (item.kind) {
              case "COLLECTION": {
                const { collection_id: collectionId } =
                  item.value as ApiSchema["SearchEngineResultSectionCarouselItemCollectionValue"];
                const collection = this.store.collections.getCollectionObservableById({
                  collectionId,
                });
                if (!collection) break;

                carousel.payload.items.push(
                  getMdsCarouselItemForCollection({
                    collection,
                    store: this.store,
                    position: carouselPosition,
                    query: this.activeSearchEngineQueryString,
                    sortBy: this.activeSearchEngineParams.sortBy,
                  })
                );
                carouselPosition++;
                break;
              }
            }
          }
          if (carousel.payload.items.length) {
            rows.push(carousel);
          }
          break;
        }
      }
    }

    if (this.apiQuery.isLoading) {
      rows.push(
        {
          type: MdsItemListRowType.Padding,
          key: "padding",
          payload: { height: 25 },
        },
        {
          type: MdsItemListRowType.Skeleton,
          key: "skeleton-1",
          payload: {},
        },
        {
          type: MdsItemListRowType.Skeleton,
          key: "skeleton-2",
          payload: {},
        },
        {
          type: MdsItemListRowType.Skeleton,
          key: "skeleton-3",
          payload: {},
        }
      );
    }

    rows.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return rows;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  get isFavorited() {
    return this.store.favoriteItems.pool.has(this.favoriteItemId);
  }

  get favoriteItemId() {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get id() {
    return resolveSavedSearchSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      queryString: this.activeSearchEngineQueryString,
    });
  }

  get isOnSearchPage(): boolean {
    const isMatch = this.store.navigation.matchActivePath({
      path: appRoutes.search({}).path,
    });

    return Boolean(isMatch);
  }

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.updateSearchEngineParams({ sortBy: itemId as SearchEngineSortByKind });
  };

  addFilter(filter: SearchEngineRequestFilter) {
    this.updateSearchEngineParams({
      filters: [...this.activeSearchEngineParams.filters, filter],
    });
  }

  removeFilter(filter: SearchEngineRequestFilter) {
    const filters = this.activeSearchEngineParams.filters.filter(f => f !== filter);

    this.updateSearchEngineParams({ filters });
  }

  addFacetFilter(filter: SearchEngineRequestFacetFilter) {
    this.updateSearchEngineParams({
      facetFilters: [...this.activeSearchEngineParams.facetFilters, filter],
    });
  }

  removeFacetFilter(filter: SearchEngineRequestFacetFilter) {
    const facetFilters = this.activeSearchEngineParams.facetFilters.filter(f => f !== filter);

    this.updateSearchEngineParams({ facetFilters });
  }

  private updateSearchEngineParams({ filters, facetFilters, sortBy }: Partial<SearchEngineParams>) {
    const searchParams = {
      ...this.activeSearchEngineParams,
    };

    if (filters) {
      searchParams.filters = filters;
    }

    if (facetFilters) {
      searchParams.facetFilters = facetFilters;
    }

    if (sortBy) {
      searchParams.sortBy = sortBy;
    }

    this.store.navigation.goToSearch({
      searchParams,
      config: {
        replace: true,
      },
    });
  }

  toggleFavorite = async () => {
    const savedSearchLabel = this.activeSearchEngineParams.queryString;
    const savedSearchQueryStr = this.activeSearchEngineQueryString;

    const existingSavedSearch = this.store.favoriteItems.pool.has(this.favoriteItemId);

    if (existingSavedSearch) {
      await new RemoveSavedSearchFromFavoritesOperation({
        store: this.store,
        payload: { saved_search_id: this.id },
        savedSearchQueryString: savedSearchQueryStr,
      }).execute();
    } else {
      await new AddSavedSearchToFavoritesOperation({
        store: this.store,
        payload: {
          saved_search_query_string: savedSearchQueryStr,
          saved_search_label: savedSearchLabel,
        },
      }).execute();
    }
  };

  get hasNoItems() {
    return !this.itemRows.some(
      row => row.type === MdsItemListRowType.Item || row.type === MdsItemListRowType.Carousel
    );
  }

  /**
   *
   * @param spaceAccountId
   * @returns id, if this account belong to current user
   */
  private getContactOrAccount(
    spaceAccountId: string
  ): [ContactObservable | AccountObservable | undefined, boolean] {
    if (spaceAccountId === this.store.spaceAccounts.myPersonalSpaceAccountId) {
      return [this.store.account.myAccount, true];
    }
    return [this.store.contacts.getBySpaceAccountId(spaceAccountId), false];
  }
}

const noWrapStyles = css({
  whiteSpace: "nowrap",
  ".mds-btn-label": {
    maxWidth: 250,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const compositeLabelStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const negativeMarginStyles = css({
  margin: "-2px 0",
});

const filterSeparatorStyles = css({
  marginLeft: "1px",
  marginRight: "1px",
  color: mdsColors().grey.x200,
});

const filterSeparatorIconStyles = css({
  fontSize: "18px",
});
