export const ZIndex = {
  SidebarOffscreenBackground: -200,
  Shadow: 5,
  FloatingButton: 30,
  QueueDebugInfo: 40,
  MentionsMenu: 45,
  ScreenOverlay: 50,
  StandardModal: 50,
  QuickSearchModel: 50,
  NavBar: 50,
  FloatingNotePreview: 60,
  FloatingBar: 60,
  InterstitialOverlay: 70,
  SidebarBackground: 90,
  Dropdown: 100,
  HoverCard: 100,
  Sidebar: 120,
  FloatingTooltip: 200,
  ToastNotification: 500,
};
