import { CopilotVotingButtons } from "@/components/copilot/TopicItemVotingButtons";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { api } from "@/modules/api";
import { toastModule } from "@/modules/toast";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";
import { NoteObservable } from "@/store/note/NoteObservable";
import { RelatedCard } from "@/store/routing/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { DateTime } from "luxon";
import { action, computed, makeObservable, observable } from "mobx";

export const MAX_NOTE_EXTRACTION_MINUTES = 3;

enum TopicItemVote {
  None,
  Upvote,
  Downvote,
}

export class CopilotViewStore extends AppSubStore {
  highlightedRowId: string | null = null;
  highlightedTopicId: string | null = null;
  votedIds = new Set<string>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      addToVotedIds: true,
      submitTopicItemFeedback: false,
      submitTopicFeedback: false,
      highlightedTopicId: observable,
      activeItem: computed,
      isRunningPipeline: computed,
      activeSpaceAccountTopics: computed,
      relatedCards: computed,
      highlightedRowId: observable,
      votedIds: observable,
      setHighlightedRow: action,
    });
  }

  setHighlightedRow(rowId: string | null, topicId: string | null) {
    this.highlightedRowId = rowId;
    this.highlightedTopicId = topicId;
  }

  public get activeItem(): NoteObservable | undefined {
    const noteId = this.store.routing.noteIdParam;
    if (noteId) return this.store.notes.get(noteId);
    return undefined;
  }

  /**
   * Returns true if the active item is a note that is currently running the copilot pipeline.
   * Returns false if the pipeline is running but started too long ago.
   */
  public get isRunningPipeline(): boolean {
    const saNote = this.activeItem?.spaceAccountNote;
    if (saNote === undefined) return false;
    if (typeof saNote.lastRelevantNoteExtractionStartedAt !== "string") return false;

    const daySinceStart = DateTime.fromJSDate(
      new Date(saNote.lastRelevantNoteExtractionStartedAt)
    ).diffNow();
    const isRecent = Math.abs(daySinceStart.as("minutes")) < MAX_NOTE_EXTRACTION_MINUTES;

    if (!isRecent) return false;

    return saNote.isRunningRelevantNoteExtractionPipeline;
  }

  public get activeSpaceAccountTopics(): SpaceAccountTopicObservable[] {
    if (!this.activeItem) return [];
    return this.activeItem.spaceAccountTopics.sortedAllTopics;
  }

  public get relatedCards(): RelatedCard[] {
    return this.activeSpaceAccountTopics
      .filter(
        topic =>
          topic.kind === null ||
          (topic.kind === "GENERAL" && topic.metadata?.note_id === String(this.activeItem?.id))
      )
      .map(topic => {
        const items = topic.allTopicItems
          .filter(topicItem => topicItem.itemId !== this.activeItem?.id)
          .map((topicItem, index) => {
            const note = topicItem.item;
            if (!note) return null;
            const row = getRowForNoteId({
              dateTime: note?.modifiedAt,
              inMainPanel: false,
              noteId: note.id,
              store: this.store,
              trackClick: () => {
                trackEvent(TrackedEvent.CopilotOpenNote, {
                  position: index,
                  note_id: note.id,
                  note_primary_label: note.title,
                  context_note_id: this.activeItem?.id,
                  context_note_primary_label: this.activeItem?.title,
                });
              },
            });
            if (!row) return null;
            return {
              ...row,
              relevanceScore: topicItem.itemRelevanceScore,
              sortDateTime: topicItem.item.modifiedAt,
              payload: {
                ...row.payload,
                rightAlignedComponent: (
                  <CopilotVotingButtons
                    itemId={row.payload.id}
                    topicItemId={topicItem.id}
                    topicId={topic.id}
                    itemRelevanceScore={topicItem.itemRelevanceScore}
                    feedbackClass="topic-item"
                    trackUpvote={() => {
                      trackEvent(TrackedEvent.CopilotUpvoteNote, {
                        position: index,
                        note_id: note.id,
                        note_primary_label: note.title,
                        context_note_id: this.activeItem?.id,
                        context_note_primary_label: this.activeItem?.title,
                      });
                    }}
                    trackDownvote={() => {
                      trackEvent(TrackedEvent.CopilotDownvoteNote, {
                        position: index,
                        note_id: note.id,
                        note_primary_label: note.title,
                        context_note_id: this.activeItem?.id,
                        context_note_primary_label: this.activeItem?.title,
                      });
                    }}
                  />
                ),
              },
            };
          })
          .filter(row => !!row)
          .sort((a, b) => Date.parse(b.sortDateTime) - Date.parse(a.sortDateTime)); // sort items by date in descending order

        if (items.length === 0) return null;
        return {
          id: topic.id,
          title: topic.title,
          items: items,
        };
      })
      .filter(card => !!card);
  }

  submitTopicItemFeedback = async (
    voteType: TopicItemVote,
    topicItemId: string,
    additionalComments: string | null
  ) => {
    const userSpaceId = this.store.spaces.myPersonalSpaceId;
    try {
      const response = await api.post("/v2/related-notes/feedback/topic/item/", {
        params: { query: { space_id: userSpaceId } },
        body: {
          space_account_topic_item_id: topicItemId,
          feedback_kind: voteType === TopicItemVote.Upvote ? "UPVOTE" : "DOWNVOTE",
          feedback_comments: additionalComments || "",
        },
      });
      if (response.error) {
        toastModule.triggerToast({
          content: `Failed to submit feedback`,
        });
        return null;
      } else {
        const result = await response.data;
        if (result["feedback_event_id"] !== null) {
          return result["feedback_event_id"];
        }
      }
    } catch (error) {
      console.log("[DEBUG] error", error);
      toastModule.triggerToast({
        content: `Failed to submit feedback`,
      });
      return null;
    }
  };

  submitTopicFeedback = async (
    voteType: TopicItemVote,
    topicId: string,
    additionalComments: string | null
  ) => {
    const userSpaceId = this.store.spaces.myPersonalSpaceId;
    try {
      const response = await api.post("/v2/related-notes/feedback/topic/", {
        params: { query: { space_id: userSpaceId } },
        body: {
          space_account_topic_id: topicId,
          feedback_kind: voteType === TopicItemVote.Upvote ? "UPVOTE" : "DOWNVOTE",
          feedback_comments: additionalComments || "",
        },
      });
      if (response.error) {
        toastModule.triggerToast({
          content: `Failed to submit feedback`,
        });
        return null;
      } else {
        const result = await response.data;
        if (result["feedback_event_id"] !== null) {
          return result["feedback_event_id"];
        }
      }
    } catch (_error) {
      toastModule.triggerToast({
        content: `Failed to submit feedback`,
      });
      return null;
    }
  };

  public addToVotedIds(id: string) {
    this.votedIds.add(id);
  }
}
