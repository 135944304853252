import Dexie from "dexie";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { AppStore } from "@/store/AppStore";
import {
  IndexedNoteSyncUpdateValue,
  NotesFilter,
  NotesIndexTuple,
  NotesSearchParams,
  NotesSortByKind,
} from "@/store/note";
import { CollectionItemIndexTuple } from "@/store/collection-items/types";

export const searchForNotes = async (
  store: AppStore,
  { lens, sortBy, limit, filter }: NotesSearchParams
): Promise<NotesIndexTuple[]> => {
  // TODO: figure out the way to use something safer than a string
  const filterIndex = lens === LensKind.All ? "is_available" : "is_available+is_owned_by_me";

  const sortMap: Record<NotesSortByKind, string> = {
    [SortByKind.LastCreated]: `[${filterIndex}+locally_created_at+model_id]`,
    [SortByKind.LastModified]: `[${filterIndex}+modified_at+model_id]`,
    [SortByKind.LastViewed]: `[${filterIndex}+last_viewed_at+model_id]`,
    [SortByKind.Alphabetical]: `[${filterIndex}+lowercase_primary_label+model_id]`,
  };

  const betweenClause = {
    [LensKind.All]: [
      [1, Dexie.minKey, -Infinity],
      [1, Dexie.maxKey, Infinity],
    ],
    [LensKind.AddedByMe]: [
      [1, 1, Dexie.minKey, -Infinity],
      [1, 1, Dexie.maxKey, Infinity],
    ],
    [LensKind.SharedWithMe]: [
      [1, 0, Dexie.minKey, -Infinity],
      [1, 0, Dexie.maxKey, Infinity],
    ],
  };

  let dexieCollection = store.notes.localTable
    .where(sortMap[sortBy])
    .between(betweenClause[lens][0], betweenClause[lens][1]);

  if (filter) {
    dexieCollection = prepareCreatedAtFilter(dexieCollection, filter.createdAt);
    dexieCollection = prepareModifiedAtFilter(dexieCollection, filter.modifiedAt);
    dexieCollection = prepareCreatedByFilter(dexieCollection, filter.createdBy);
    dexieCollection = prepareModifiedByFilter(dexieCollection, filter.modifiedBy);
    dexieCollection = prepareOlderThanFilter(dexieCollection, filter.olderThan);
    dexieCollection = await prepareCollectionFilter(dexieCollection, filter.collections, store);
  }

  // only alphabetical is sorted in ascending order
  if (sortBy !== SortByKind.Alphabetical) {
    dexieCollection = dexieCollection.reverse();
  }

  return dexieCollection.limit(limit).keys() as unknown as Promise<NotesIndexTuple[]>;
};

const getDateFromTo = (date: { from?: string; to?: string }) => {
  const from = date.from ? new Date(date.from).getTime() : undefined;
  const to = date.to ? new Date(date.to).getTime() : undefined;

  return { from, to };
};

const prepareCreatedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  createdAt: NotesFilter["createdAt"]
) => {
  if (!createdAt) {
    return dexieCollection;
  }

  const { from, to } = getDateFromTo(createdAt);
  if (from === undefined && to === undefined) {
    return dexieCollection;
  }

  return dexieCollection.filter(note => {
    const createdTime = new Date(note.locally_created_at).getTime();

    if (to && from === undefined) {
      return createdTime <= to;
    }

    if (from && to === undefined) {
      return createdTime >= from;
    }

    if (from && to) {
      return createdTime >= from && createdTime <= to;
    }

    return true;
  });
};

const prepareModifiedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  modifiedAt: NotesFilter["modifiedAt"]
) => {
  if (!modifiedAt) {
    return dexieCollection;
  }

  const { from, to } = getDateFromTo(modifiedAt);
  if (from === undefined && to === undefined) {
    return dexieCollection;
  }

  return dexieCollection.filter(note => {
    const modifiedTime = new Date(note.modified_at).getTime();

    if (to && from === undefined) {
      return modifiedTime <= to;
    }

    if (from && to === undefined) {
      return modifiedTime >= from;
    }

    if (from && to) {
      return modifiedTime >= from && modifiedTime <= to;
    }

    return true;
  });
};

const prepareCreatedByFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  createdBy: NotesFilter["createdBy"]
) => {
  if (!createdBy) {
    return dexieCollection;
  }

  return dexieCollection.filter(note =>
    createdBy.includes(note.model_data.owned_by_space_account_id)
  );
};

const prepareModifiedByFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  modifiedBy: NotesFilter["modifiedBy"]
) => {
  if (!modifiedBy) {
    return dexieCollection;
  }

  return dexieCollection.filter(note =>
    modifiedBy.some(id => note.model_data.modified_by_space_account_ids.includes(id))
  );
};

const prepareOlderThanFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  olderThan: NotesFilter["olderThan"]
) => {
  if (!olderThan) {
    return dexieCollection;
  }

  const now = Date.now();
  const thresholds = {
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };

  return dexieCollection.filter(note => {
    const createdTime = new Date(note.locally_created_at).getTime();
    return now - createdTime > thresholds[olderThan];
  });
};

const prepareCollectionFilter = async (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  collections: NotesFilter["collections"],
  store: AppStore
) => {
  if (!collections) return dexieCollection;
  if (!collections.ids.length && collections.mode !== "none") return dexieCollection;

  // Remove duplicates from collections.ids
  const collectionIds = [...new Set(collections.ids)];

  // Create Sets to track note IDs
  const noteIdsByCollection: Record<string, Set<string>> = {};
  const allNotesInSelectedCollections = new Set<string>();

  // Initialize Sets for each collection
  collectionIds.forEach(id => {
    noteIdsByCollection[id] = new Set<string>();
  });

  const { itemsOfSelectedCollections, allCollectionItemIndexes } =
    await queryItemsOfSelectedCollections(collections, store);

  for (const item of itemsOfSelectedCollections) {
    noteIdsByCollection[item[0]].add(item[1]);
    allNotesInSelectedCollections.add(item[1]);
  }

  // In all collections mode, we filter notes by checking if the note is in all specified collections
  if (collections.mode === "all") {
    return dexieCollection.filter(note =>
      collectionIds.every(id => noteIdsByCollection[id].has(note.model_id))
    );
  }

  // In anyOrNone mode, we filter notes by checking if the note is in any of the specified collections
  // or if it is not in any of collections at all
  if (collections.mode === "anyOrNone") {
    const allNotesInAllCollections = new Set<string>(
      allCollectionItemIndexes?.map(item => item[1])
    );

    return dexieCollection.filter(note => {
      const isInAnySpecifiedCollection = collectionIds.some(collectionId =>
        noteIdsByCollection[collectionId].has(note.model_id)
      );

      return isInAnySpecifiedCollection || !allNotesInAllCollections.has(note.model_id);
    });
  }

  if (collections.mode === "none") {
    const allNotesInAllCollections = new Set<string>(
      allCollectionItemIndexes?.map(item => item[1])
    );

    return dexieCollection.filter(note => {
      return !allNotesInAllCollections.has(note.model_id);
    });
  }

  // In any mode, we filter notes by checking if the note is in any of the specified collections
  // (excluding notes in no collections)
  return dexieCollection.filter(note => {
    const isInAnySpecifiedCollection = collectionIds.some(collectionId =>
      noteIdsByCollection[collectionId].has(note.model_id)
    );

    return isInAnySpecifiedCollection;
  });
};

/**
 *  We can use 2 different optimization strategies:
 *
 *  1. In "any" or "any" mode we locate collection items by provided collection_ids.
 *     This is fast enough because we select and then traverse only small subset of data
 *
 *  2. In "none" or "anyOrNone" mode we have to access all collection items but we can take advantage of the indexes
 *     and select only keys (which is faster because Dexie accesses keys faster than values)
 */
const queryItemsOfSelectedCollections = async (
  collections: NotesFilter["collections"],
  store: AppStore
): Promise<{
  itemsOfSelectedCollections: CollectionItemIndexTuple[];
  allCollectionItemIndexes?: CollectionItemIndexTuple[];
}> => {
  if (!collections) return Promise.resolve({ itemsOfSelectedCollections: [] });

  const { mode, ids: collectionIds } = collections;

  // In "all" or "any" mode we locate collection items by provided collection_ids.
  if (mode === "all" || mode === "any") {
    const collectionItems = await store.collectionItems.localTable
      .where("collection_id")
      .anyOf(collectionIds)
      .toArray();

    const itemsOfSelectedCollections: CollectionItemIndexTuple[] = collectionItems.map(item => [
      item.collection_id,
      item.item_id,
      item.model_id,
    ]);

    return { itemsOfSelectedCollections };
  }

  // In "none" or "anyOrNone" mode we query for all collection items _keys_
  const allCollectionItemIndexes = (await store.collectionItems.localTable
    .where("[collection_id+item_id+model_id]")
    .between(Dexie.minKey, Dexie.maxKey)
    .keys()) as unknown as CollectionItemIndexTuple[];

  let itemsOfSelectedCollections: CollectionItemIndexTuple[] = [];
  if (mode === "anyOrNone") {
    itemsOfSelectedCollections = allCollectionItemIndexes.filter(item =>
      collectionIds.includes(item[0])
    );
  }

  return { itemsOfSelectedCollections, allCollectionItemIndexes };
};
