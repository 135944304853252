import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

import { MdsIconKind } from "@/design-system/components/icon/types";

export const iconDefinitionMapping: {
  [key in MdsIconKind]: ProRegularSvgIcons.IconDefinition;
} = {
  [MdsIconKind.ArrowLeftToLine]: ProRegularSvgIcons.faArrowLeftToLine,
  [MdsIconKind.ArrowDownToLine]: ProRegularSvgIcons.faArrowDownToLine,
  [MdsIconKind.ArrowRightToLine]: ProRegularSvgIcons.faArrowRightToLine,
  [MdsIconKind.ArrowUpToLine]: ProSolidSvgIcons.faArrowUpToLine,
  [MdsIconKind.ArrowFromRight]: ProRegularSvgIcons.faChevronDoubleLeft,
  [MdsIconKind.ArrowToRight]: ProRegularSvgIcons.faChevronDoubleRight,
  [MdsIconKind.ArrowUpRight]: ProRegularSvgIcons.faArrowUpRight,
  [MdsIconKind.ArrowUpArrowDown]: ProRegularSvgIcons.faArrowUpArrowDown,
  [MdsIconKind.ArrowDownTurnLeft]: ProRegularSvgIcons.faArrowRightToArc,
  [MdsIconKind.ArrowRightFromLine]: ProRegularSvgIcons.faArrowRightFromLine,
  [MdsIconKind.Play]: ProRegularSvgIcons.faPlay,
  [MdsIconKind.Eye]: ProRegularSvgIcons.faEye,
  [MdsIconKind.EyeSlash]: ProRegularSvgIcons.faEyeSlash,
  [MdsIconKind.Home]: ProRegularSvgIcons.faHomeLgAlt,
  [MdsIconKind.Group]: ProRegularSvgIcons.faUsers,
  [MdsIconKind.Rocket]: ProRegularSvgIcons.faRocket,
  [MdsIconKind.Flows]: ProRegularSvgIcons.faWater,
  [MdsIconKind.Star]: ProRegularSvgIcons.faStar,
  [MdsIconKind.StarSolid]: ProSolidSvgIcons.faStar,
  [MdsIconKind.Tag]: ProRegularSvgIcons.faHashtag,
  [MdsIconKind.Tasks]: ProRegularSvgIcons.faTasks,
  [MdsIconKind.List]: ProRegularSvgIcons.faList,
  [MdsIconKind.BellSlash]: ProRegularSvgIcons.faBellSlash,
  [MdsIconKind.Cells]: ProRegularSvgIcons.faDiagramCells,
  [MdsIconKind.Checkbox]: ProRegularSvgIcons.faCheckSquare,
  [MdsIconKind.CheckboxSolid]: ProSolidSvgIcons.faCheckSquare,
  [MdsIconKind.Check]: ProRegularSvgIcons.faCheck,
  [MdsIconKind.CheckCircleSolid]: ProSolidSvgIcons.faCheckCircle,
  [MdsIconKind.CheckboxEmpty]: ProRegularSvgIcons.faSquare,
  [MdsIconKind.Circle]: ProRegularSvgIcons.faCircle,
  [MdsIconKind.Clock]: ProRegularSvgIcons.faClock,
  [MdsIconKind.Copy]: ProRegularSvgIcons.faCopy,
  [MdsIconKind.Document]: ProRegularSvgIcons.faFileAlt,
  [MdsIconKind.Envelope]: ProRegularSvgIcons.faEnvelope,
  [MdsIconKind.EnvelopeOpen]: ProRegularSvgIcons.faEnvelopeOpen,
  [MdsIconKind.Envelopes]: ProRegularSvgIcons.faEnvelopes,
  [MdsIconKind.Export]: ProRegularSvgIcons.faArrowDownToLine,
  [MdsIconKind.HandPointRibbon]: ProRegularSvgIcons.faHandPointRibbon,
  [MdsIconKind.Scribble]: ProRegularSvgIcons.faSignature,
  [MdsIconKind.Globe]: ProRegularSvgIcons.faGlobe,
  [MdsIconKind.Grip]: ProRegularSvgIcons.faGripDotsVertical,
  [MdsIconKind.Timeline]: ProRegularSvgIcons.faStream,
  [MdsIconKind.Icons]: ProRegularSvgIcons.faIcons,
  [MdsIconKind.Inbox]: ProRegularSvgIcons.faInbox,
  [MdsIconKind.Trash]: ProRegularSvgIcons.faTrashAlt,
  [MdsIconKind.Settings]: ProRegularSvgIcons.faCog,
  [MdsIconKind.AddPerson]: ProRegularSvgIcons.faUserPlus,
  [MdsIconKind.LogOut]: ProRegularSvgIcons.faSignOut,
  [MdsIconKind.DesktopApp]: ProRegularSvgIcons.faSignOut,
  [MdsIconKind.PinRight]: ProRegularSvgIcons.faArrowFromRight,
  [MdsIconKind.UnpinRight]: ProRegularSvgIcons.faArrowToRight,
  [MdsIconKind.LeftArrow]: ProRegularSvgIcons.faArrowLeft,
  [MdsIconKind.RightArrow]: ProRegularSvgIcons.faArrowRight,
  [MdsIconKind.Calendar]: ProRegularSvgIcons.faCalendarAlt,
  [MdsIconKind.Clear]: ProRegularSvgIcons.faTimesCircle,
  [MdsIconKind.Compose]: ProRegularSvgIcons.faPen,
  [MdsIconKind.ComposeInline]: ProRegularSvgIcons.faPenToSquare,
  [MdsIconKind.Copilot]: ProRegularSvgIcons.faLocationArrow,
  [MdsIconKind.Message]: ProRegularSvgIcons.faMessage,
  [MdsIconKind.MessageSolid]: ProSolidSvgIcons.faMessage,
  [MdsIconKind.MessageExclamation]: ProRegularSvgIcons.faMessageExclamation,
  [MdsIconKind.Search]: ProRegularSvgIcons.faSearch,
  [MdsIconKind.DropdownOpened]: ProRegularSvgIcons.faChevronUp,
  [MdsIconKind.DropdownClosed]: ProRegularSvgIcons.faChevronDown,
  [MdsIconKind.Exit]: ProRegularSvgIcons.faTimes,
  [MdsIconKind.BoxFull]: ProLightSvgIcons.faBoxFull,
  [MdsIconKind.AngleRight]: ProRegularSvgIcons.faAngleRight,
  [MdsIconKind.AngleLeft]: ProRegularSvgIcons.faAngleLeft,
  [MdsIconKind.AngleUp]: ProRegularSvgIcons.faAngleUp,
  [MdsIconKind.AngleDown]: ProRegularSvgIcons.faAngleDown,
  [MdsIconKind.Crown]: ProRegularSvgIcons.faCrown,
  [MdsIconKind.Collection]: ProRegularSvgIcons.faHashtag,
  [MdsIconKind.More]: ProRegularSvgIcons.faEllipsisH,
  [MdsIconKind.Merge]: ProRegularSvgIcons.faMerge,
  [MdsIconKind.Minus]: ProRegularSvgIcons.faMinus,
  [MdsIconKind.Plus]: ProRegularSvgIcons.faPlus,
  [MdsIconKind.Reply]: ProRegularSvgIcons.faReply,
  [MdsIconKind.Share]: ProRegularSvgIcons.faShare,
  [MdsIconKind.Shared]: ProRegularSvgIcons.faUserFriends,
  [MdsIconKind.Sidebar]: ProRegularSvgIcons.faSidebarFlip,
  [MdsIconKind.Sparkle]: ProSolidSvgIcons.faSparkles,
  [MdsIconKind.UniqueSparkle]: ProSolidSvgIcons.faSparkle,
  [MdsIconKind.Bolt]: ProSolidSvgIcons.faBolt,
  [MdsIconKind.Upvote]: ProRegularSvgIcons.faThumbsUp,
  [MdsIconKind.Downvote]: ProRegularSvgIcons.faThumbsDown,
  [MdsIconKind.UpvoteSolid]: ProSolidSvgIcons.faThumbsUp,
  [MdsIconKind.DownvoteSolid]: ProSolidSvgIcons.faThumbsDown,
  [MdsIconKind.Send]: ProSolidSvgIcons.faPaperPlaneTop,
  [MdsIconKind.SendAlt]: ProRegularSvgIcons.faPaperPlaneTop,
  [MdsIconKind.Wifi]: ProRegularSvgIcons.faWifi,
  [MdsIconKind.WifiSlash]: ProRegularSvgIcons.faWifiSlash,
  [MdsIconKind.CloudSlash]: ProRegularSvgIcons.faCloudSlash,
  [MdsIconKind.LinkSolid]: ProSolidSvgIcons.faLinkSimple,
  [MdsIconKind.Link]: ProRegularSvgIcons.faLinkSimple,
  [MdsIconKind.Loader]: ProDuotoneSvgIcons.faLoader,
  [MdsIconKind.Lock]: ProRegularSvgIcons.faLock,
  [MdsIconKind.LockSolid]: ProSolidSvgIcons.faLock,
  [MdsIconKind.Bookmark]: ProRegularSvgIcons.faBookmark,
  [MdsIconKind.BookmarkSolid]: ProSolidSvgIcons.faBookmark,
  [MdsIconKind.Redo]: ProRegularSvgIcons.faRedo,
  [MdsIconKind.Menu]: ProRegularSvgIcons.faBars,
  [MdsIconKind.Book]: ProRegularSvgIcons.faBook,
  [MdsIconKind.Warning]: ProRegularSvgIcons.faWarning,
  [MdsIconKind.Experimental]: ProRegularSvgIcons.faFlask,
  [MdsIconKind.Sort]: ProRegularSvgIcons.faSort,
  [MdsIconKind.SortAlt]: ProRegularSvgIcons.faSortAlt,
  [MdsIconKind.Help]: ProRegularSvgIcons.faQuestionCircle,
  [MdsIconKind.Question]: ProRegularSvgIcons.faQuestion,
  [MdsIconKind.Sorted]: ProRegularSvgIcons.faBarsSort,
  [MdsIconKind.Spinner]: ProRegularSvgIcons.faSpinner,
  [MdsIconKind.Exclamation]: ProRegularSvgIcons.faExclamationCircle,
  [MdsIconKind.Danger]: ProSolidSvgIcons.faExclamationCircle,
  [MdsIconKind.Pen]: ProRegularSvgIcons.faPen,
  [MdsIconKind.Ban]: ProRegularSvgIcons.faBan,
  [MdsIconKind.Shapes]: ProRegularSvgIcons.faShapes,
  [MdsIconKind.User]: ProRegularSvgIcons.faUser,
  [MdsIconKind.Image]: ProRegularSvgIcons.faImage,
  [MdsIconKind.Gif]: ProRegularSvgIcons.faGif,
  [MdsIconKind.Film]: ProRegularSvgIcons.faFilm,
  [MdsIconKind.Code]: ProRegularSvgIcons.faCode,
  [MdsIconKind.Quote]: ProRegularSvgIcons.faQuoteLeft,
  [MdsIconKind.QuoteRight]: ProRegularSvgIcons.faQuoteRight,
  [MdsIconKind.Pdf]: ProRegularSvgIcons.faFilePdf,
  [MdsIconKind.PhoneSms]: ProRegularSvgIcons.faMobileButton,
  [MdsIconKind.Import]: ProRegularSvgIcons.faArrowDownToSquare,
  [MdsIconKind.H1]: ProRegularSvgIcons.faH1,
  [MdsIconKind.H2]: ProRegularSvgIcons.faH2,
  [MdsIconKind.H3]: ProRegularSvgIcons.faH3,
  [MdsIconKind.Bold]: ProRegularSvgIcons.faBold,
  [MdsIconKind.Italic]: ProRegularSvgIcons.faItalic,
  [MdsIconKind.Underline]: ProRegularSvgIcons.faUnderline,
  [MdsIconKind.Strikethrough]: ProRegularSvgIcons.faStrikethrough,
  [MdsIconKind.NumberedList]: ProRegularSvgIcons.faListOl,
  [MdsIconKind.RemoveFormat]: ProRegularSvgIcons.faRemoveFormat,
  [MdsIconKind.Table]: ProRegularSvgIcons.faTable,
  [MdsIconKind.Paperclip]: ProRegularSvgIcons.faPaperclip,
  [MdsIconKind.Dot]: ProSolidSvgIcons.faCircleSmall,
  [MdsIconKind.Pipe]: ProRegularSvgIcons.faPipe,
  [MdsIconKind.InfoCircle]: ProRegularSvgIcons.faInfoCircle,
  [MdsIconKind.Notebook]: ProRegularSvgIcons.faNotebook,
  [MdsIconKind.Thumbtack]: ProRegularSvgIcons.faThumbtack,
  [MdsIconKind.ThumbtackSolid]: ProSolidSvgIcons.faThumbTack,
  [MdsIconKind.Heart]: ProRegularSvgIcons.faHeart,
  [MdsIconKind.HeartSolid]: ProSolidSvgIcons.faHeart,
  [MdsIconKind.GearCode]: ProRegularSvgIcons.faGearCode,
  [MdsIconKind.Compass]: ProRegularSvgIcons.faCompass,
  [MdsIconKind.CalendarDay]: ProRegularSvgIcons.faCalendarDay,
  [MdsIconKind.HorizontalRule]: ProRegularSvgIcons.faHorizontalRule,
};
