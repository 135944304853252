import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { forceNetworkCheck, getIsOnline, getWhenOnline } from "@/domains/network/status";
import { clientEnvModule } from "@/modules/client-env";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { type GuestAppStore } from "@/store/GuestAppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  SyncError,
  SyncErrorDisplayType,
  SyncErrorHandlingType,
} from "@/store/sync/operations/errors/SyncError";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import {
  deserializeSyncOperation,
  serializeOptimisticUpdate,
  serializeSyncOperation,
} from "@/store/sync/operations/helpers/common";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { SerializedSyncOperation, SyncOperationGeneric } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncModelKind,
  SyncModelData,
  QueueProcessingState,
  SyncCustomErrorData,
} from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { liveQuery } from "dexie";
import { DateTime } from "luxon";
import { observable, makeObservable, action, runInAction, computed, onBecomeObserved } from "mobx";
import { Subscription } from "node_modules/react-hook-form/dist/utils/createSubject";
import pRetry from "p-retry";

const getMaximumRetryTimeout = () => {
  if (clientEnvModule.isTest()) return 1_000;

  /** 90 seconds. */
  return 90_000;
};

const getMinimumRetryTimeout = () => {
  if (clientEnvModule.isTest()) return 1_000;

  /** 2 seconds. */
  return 2_000;
};

export abstract class BaseSyncActionQueue<
  Store extends AppStore | GuestAppStore,
> extends AppSubStore<Store> {
  /**
   * Contains operations that are actively being processed/synced with the server
   */
  processing: SerializedSyncOperation[] = [];
  /**
   * Contains operations that have been acknowledged by the server but waiting for final confirmation
   */
  pending: SerializedSyncOperation[] = [];
  /**
   * Temporary local changes made before server confirmation
   */
  optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

  protected getSpaceId: () => string;

  // ERROR HANDLING
  abstract get syncErrorModalFields(): Maybe<SyncErrorModalFields>;
  public lastProcessingItemStart?: Date;
  public lastProcessingItemStop?: Date;
  public lastSentOperation: SyncOperationGeneric | undefined;
  public processingError?: SyncError;
  public isFailing = false;

  // OPERATION PROCESSING
  abstract processOperation(operation: SyncOperationGeneric): Promise<Maybe<SyncOperationGeneric>>;

  public processingState: QueueProcessingState = QueueProcessingState.NotReady;

  constructor({
    getSpaceId,
    ...injectedDeps
  }: { getSpaceId: () => string } & AppSubStoreArgs<Store>) {
    super(injectedDeps);
    this.getSpaceId = getSpaceId;
    makeObservable<this, "getSpaceId">(this, {
      initializeLiveQuery: action,
      liveQuerySubscription: observable,
      processing: observable,
      pending: observable,
      optimisticUpdates: observable,
      operationsByOperationKind: computed,
      operationsByModelId: computed,

      updateFromLocal: action,
      clearFakeSyncUpdates: action,

      db: computed,
      getOperationsByModelId: false,
      getSpaceId: false,

      // OPERATIONS
      addToProcessing: action,
      moveFromProcessingToPending: action,
      removeFromProcessing: action,
      addToPending: action,
      removeFromPending: action,

      // OPTIMISTIC UPDATES
      applyOptimisticUpdate: action,
      removeAllOptimisticUpdatesByModelKind: action,
      removeAllOptimisticUpdatesByModelId: action,
      removeAllOptimisticUpdates: action,
      removeAllOptimisticUpdatesBySyncOperationId: action,

      // PROCESSING + PENDING QUEUES
      push: action,
      process: action,
      start: action,
      confirmSyncUpdatesUntil: action,
      processOperation: false,

      // ERROR HANDLING
      lastSentOperation: observable,
      lastProcessedAt: computed,
      processingError: observable,
      skipAndRevertOperationById: action,
      skipAndRevertRelatedOperations: action,
      skipAndRevertRelatedOperationsById: action,
      skipAndRevertUnsyncedOperationsForModelId: action,
      handleCustomError: action,
      handleSyncError: action,
      syncErrorModalFields: false,

      // QUEUE STATUS
      lastProcessingItemStart: observable,
      lastProcessingItemStop: observable,
      isFailing: observable,
      processingState: observable,
      isLoading: computed,
      didFail: action,
      setState: action,
      pause: action,
      resume: action,

      // HYDRATION AND INITIALIZATION
      reset: action,
    });

    onBecomeObserved(this, "processing", () => this.initializeLiveQuery());
    onBecomeObserved(this, "pending", () => this.initializeLiveQuery());
    onBecomeObserved(this, "optimisticUpdates", () => this.initializeLiveQuery());
  }

  liveQuerySubscription: Maybe<Subscription>;
  initializeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(async () => {
      const [processing, pending, optimisticUpdates] = await Promise.all([
        this.db.queue.processing.orderBy("committedAt").toArray(),
        this.db.queue.pending.orderBy("committedAt").toArray(),
        this.db.queue.optimisticUpdates.orderBy("locally_committed_at").toArray(),
      ]);
      return { processing, pending, optimisticUpdates };
    }).subscribe({
      next: ({ processing, pending, optimisticUpdates }) => {
        runInAction(() => {
          this.processing = processing;
          this.pending = pending;
          this.optimisticUpdates = optimisticUpdates;
        });
      },
    });
  }

  async updateFromLocal() {
    const [processing, pending, optimisticUpdates] = await Promise.all([
      this.db.queue.processing.orderBy("committedAt").toArray(),
      this.db.queue.pending.orderBy("committedAt").toArray(),
      this.db.queue.optimisticUpdates.orderBy("locally_committed_at").toArray(),
    ]);
    runInAction(() => {
      this.processing = processing;
      this.pending = pending;
      this.optimisticUpdates = optimisticUpdates;
    });
  }

  get operationsByModelId(): Map<string, SyncOperationGeneric[]> {
    const output = new Map<string, SyncOperationGeneric[]>();

    const addToMap = (operation: SerializedSyncOperation) => {
      const deserializedOperation = deserializeSyncOperation(this.store, operation);
      [operation.modelId, operation.noteId, operation.collectionId].forEach(id => {
        if (id) {
          if (!output.has(id)) output.set(id, []);
          output.get(id)!.push(deserializedOperation);
        }
      });
    };

    this.processing.forEach(addToMap);
    this.pending.forEach(addToMap);
    return output;
  }

  get operationsByOperationKind(): Map<string, SyncOperationGeneric[]> {
    const output = new Map<string, SyncOperationGeneric[]>();
    for (const operation of this.processing) {
      if (!output.has(operation.operationKind)) output.set(operation.operationKind, []);
      output.get(operation.operationKind)!.push(deserializeSyncOperation(this.store, operation));
    }
    for (const operation of this.pending) {
      if (!output.has(operation.operationKind)) output.set(operation.operationKind, []);
      output.get(operation.operationKind)!.push(deserializeSyncOperation(this.store, operation));
    }
    return output;
  }

  get db() {
    return this.store.memDb;
  }

  public async push(syncOperation: SyncOperationGeneric) {
    await this.addToProcessing(syncOperation);
    const processingCount = await this.db.queue.processing.count();
    // TODO: we prob should not call process directly but rather react to liveQuery
    if (processingCount > 0) this.process();
  }

  public start() {
    logger.debug({ message: "[Queue] Start" });
    this.setState(QueueProcessingState.Ready);
    // TODO: we prob should not call process directly but rather react to liveQuery
    this.process();
  }

  public async process() {
    if (this.processingState === QueueProcessingState.NotReady) {
      logger.debug({ message: "[Queue] Not ready" });
      return;
    }

    if (this.processingState === QueueProcessingState.Processing) return;
    if (this.processingState === QueueProcessingState.Paused) return;

    logger.debug({ message: "[Queue] Process" });
    this.setState(QueueProcessingState.Processing);

    try {
      while ((this.processingState as QueueProcessingState) !== QueueProcessingState.Paused) {
        // TODO: we prob should use this.processing instead
        const serializedOperation = await this.db.queue.processing.orderBy("committedAt").first();
        if (!serializedOperation) {
          break;
        }

        const operation = deserializeSyncOperation(this.store, serializedOperation);
        const deadline = DateTime.now().plus({ seconds: 60 });

        const acknowledgedOperation = await pRetry<SyncOperationGeneric | SyncError | undefined>(
          async () => {
            if (this.processingState === QueueProcessingState.Paused) return;
            if (!getIsOnline()) await getWhenOnline();
            runInAction(() => (this.lastProcessingItemStart = new Date()));
            logger.debug({ message: `[Queue] Processing ${operation.id}` });
            return this.processOperation(operation);
          },
          {
            forever: true,
            maxTimeout: getMaximumRetryTimeout(),
            minTimeout: getMinimumRetryTimeout(),
            randomize: true,
            onFailedAttempt: error => {
              logger.error({
                message: "[Queue] Error syncing operation",
                info: {
                  operation: getLoggableOperation(operation),
                  error: objectModule.safeErrorAsJson(error),
                },
              });
              forceNetworkCheck();
            },
            shouldRetry: error => {
              /**
               * @todo - We should have some, retries even for unknown errors.
               */
              if (!(error instanceof SyncError)) {
                this.handleCustomError(operation, { kind: "UNKNOWN" });
                return false;
              }

              if (error.handlingType === SyncErrorHandlingType.RetryWithLimit) {
                return deadline.diffNow().milliseconds > 0;
              }

              /**
               * @todo - We should remove the toasts and replace them
               * with a some UI components.
               */
              if (error.handlingType === SyncErrorHandlingType.RetryForever) {
                // TRANSIENT errors are retried indefinitely but should display a toast eventually.
                // Display toast on the 20th, 40th, 60th, etc error.
                if (
                  error.attemptNumber % 20 === 0 &&
                  error.displayType === SyncErrorDisplayType.Toast
                ) {
                  // Replace a previous toast for the same operation.
                  toastModule.triggerToast({
                    content: error.toastMessage ?? error.message,
                    toastId: error.operationId,
                  });
                }
                return true;
              }

              return false;
            },
          }
        )
          .catch(error => {
            logger.error({
              message: "[Queue] Error processing",
              info: { error: objectModule.safeErrorAsJson(error as Error) },
            });
            return error;
          })
          .finally(() => {
            runInAction(() => (this.lastProcessingItemStop = new Date()));
          });

        // If this returns undefined, we keep the operation in the processing queue and retry indefinitely
        if (acknowledgedOperation === undefined) {
          continue;
        }

        // If there is a sync error, we handle it a bit differently
        if (acknowledgedOperation instanceof SyncError) {
          this.handleSyncError(operation, acknowledgedOperation);
          continue;
        }

        // If the action is acknowledged, we need to move it to the pending queue and move on to the next operation
        if (acknowledgedOperation instanceof BaseSyncOperationGeneric) {
          logger.debug({
            message: "[SYNC][SyncActionQueue] acknowledgedOperation",
            info: { acknowledgedOperation: getLoggableOperation(acknowledgedOperation) },
          });

          await this.moveFromProcessingToPending(acknowledgedOperation);

          runInAction(() => {
            this.lastSentOperation = acknowledgedOperation;
            this.isFailing = false;
          });

          if (
            this.store.sync.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId &&
            acknowledgedOperation.latestSpaceAccountSequenceId <=
              this.store.sync.latestSpaceAccountSequenceId
          ) {
            // If server has confirmed this sequence ID, we can confirm all operations up to this point
            await this.confirmSyncUpdatesUntil(this.store.sync.latestSpaceAccountSequenceId);
          }
        }
      }
    } catch (error) {
      logger.error({
        message: "[Queue] Error processing",
        info: { error: objectModule.safeErrorAsJson(error as Error) },
      });
      return error;
    } finally {
      if ((this.processingState as QueueProcessingState) === QueueProcessingState.Processing) {
        logger.debug({ message: "[Queue] Done" });
        this.setState(QueueProcessingState.Ready);
      }
    }
  }

  /**
   * Removes "fake" sync updates that don't have sequence IDs from the pending queue.
   *
   * Fake sync updates are operations are never sent to the server hence don't have sequence IDs assigned.
   * This happens with operations like content updates that don't require immediate syncing.
   *
   * For each fake update (per provided modelId):
   * 1. Removes it from the pending queue
   * 2. Removes any associated optimistic updates
   * 3. Triggers recompute on the affected models
   *
   * This cleanup is typically called after pushing new content updates to remove stale optimistic state.
   */
  clearFakeSyncUpdates = async (modelId: string) => {
    // DEXIE REFACTOR TODO:  Deduplicate the triggerRecompute calls
    const confirmedSyncOperations = await this.db.queue.pending
      .where("modelId")
      .equals(modelId)
      .filter(operation => !operation.latestSpaceAccountSequenceId)
      .toArray();

    await this.db.transaction(
      "rw",
      [this.db.queue.pending, this.db.queue.optimisticUpdates],
      async () => {
        for (const syncOperation of confirmedSyncOperations) {
          await this.removeFromPending(syncOperation.operationId);
          await this.removeAllOptimisticUpdatesBySyncOperationId(syncOperation.operationId);
        }
      }
    );

    for (const syncOperation of confirmedSyncOperations) {
      const hydratedOperation = deserializeSyncOperation(this.store, syncOperation);
      await hydratedOperation.triggerRecompute();
    }
  };

  // Confirms and cleans up all operations that have been processed by the server
  confirmSyncUpdatesUntil = async (latestSequenceId: number) => {
    // Find all of the acknowledged operations on pending to remove
    // Remove all pending operations + optimistic updates
    // DEXIE REFACTOR TODO:  Deduplicate the triggerRecompute calls
    logger.debug({
      message: "[SYNC][SyncActionQueue] confirmSyncUpdatesUntil ",
      info: { latestSequenceId },
    });

    const confirmedSyncOperations = await this.db.queue.pending
      .filter(operation => {
        if (!operation.latestSpaceAccountSequenceId) return false;
        if (operation.latestSpaceAccountSequenceId <= latestSequenceId) return true;
        return false;
      })
      .toArray();

    // Remove confirmed operations and their optimistic updates
    await this.db.transaction(
      "rw",
      [this.db.queue.pending, this.db.queue.optimisticUpdates],
      async () => {
        for (const syncOperation of confirmedSyncOperations) {
          await this.removeFromPending(syncOperation.operationId);
          await this.removeAllOptimisticUpdatesBySyncOperationId(syncOperation.operationId);
        }
      }
    );

    for (const syncOperation of confirmedSyncOperations) {
      const hydratedOperation = deserializeSyncOperation(this.store, syncOperation);
      await hydratedOperation.triggerRecompute();
    }
  };

  // Without resume to avoid UI flickering these are suitable for toasts only.
  skipAndRevertOperationById = async (operationId: string): Promise<boolean> => {
    const operation = await this.db.queue.processing.where({ operationId }).first();
    if (!operation) return false;
    logger.debug({ message: "[Queue] Reverting...", info: { operationId } });
    await this.db.transaction(
      "rw",
      [this.db.queue.processing, this.db.queue.optimisticUpdates],
      async () => {
        await this.removeFromProcessing(operationId);
        await this.removeAllOptimisticUpdatesBySyncOperationId(operationId);
      }
    );
    const hydratedOperation = deserializeSyncOperation(this.store, operation);
    await hydratedOperation.triggerRecompute();
    return true;
  };

  skipAndRevertRelatedOperations = async (
    operation: SyncOperationGeneric | SerializedSyncOperation
  ): Promise<boolean> => {
    if (operation instanceof TrashNoteOperation) {
      const relatedOperations = ["TRASH_NOTE", "DELETE_NOTE"];
      const operations = await this.getOperationsByModelId(operation.payload.id);
      const filtered = operations.filter(
        e => relatedOperations.includes(e.operationKind) && !e.acknowledged
      );
      for (const operation of filtered) {
        await this.skipAndRevertOperationById(operation.operationId);
      }
    }
    if (operation instanceof UpdateNoteContentUsingDiffOperation) {
      const operations = await this.getOperationsByModelId(operation.payload.id);
      const relatedOperations = [
        "CREATE_NOTE",
        "UPDATE_NOTE_CONTENT_USING_DIFF",
        "TRASH_NOTE",
        "DELETE_NOTE",
      ];
      const filtered = operations.filter(
        e => relatedOperations.includes(e.operationKind) && !e.acknowledged
      );
      for (const operation of filtered) {
        await this.skipAndRevertOperationById(operation.operationId);
      }
    }
    return this.skipAndRevertOperationById(operation.operationId);
  };

  skipAndRevertUnsyncedOperationsForModelId = async (id: string) => {
    const operations = await this.getOperationsByModelId(id);
    const filtered = operations?.filter(e => !e.acknowledged);
    if (filtered?.length) {
      // May drop the first operation too even if it's running right now.
      const revertibleOperations = operations;
      for (const operation of revertibleOperations) {
        await this.skipAndRevertOperationById(operation.operationId);
      }
    }
    this.resume();
  };

  skipAndRevertRelatedOperationsById = async (operationId: string) => {
    const operation = await this.db.queue.processing.where({ operationId }).first();
    if (operation) await this.skipAndRevertRelatedOperations(operation);
    this.resume();
  };

  handleCustomError(operation: SyncOperationGeneric, errorData: SyncCustomErrorData): void {
    if (errorData.kind === "NOT_FOUND") return operation.handleNotFoundError(errorData);
    if (errorData.kind === "INVALID") return operation.handleInvalidError(errorData);
    if (errorData.kind === "PERMISSION_DENIED")
      return operation.handlePermissionDeniedError(errorData);
    if (errorData.kind === "TRANSIENT") return operation.handleTransientError(errorData);
    if (errorData.kind === "UNKNOWN") return operation.handleUnknownError(errorData);
  }

  // Handle SyncError after all retries are exhausted
  async handleSyncError(operation: SyncOperationGeneric, error: SyncError): Promise<void> {
    logger.debug({
      message: "[Queue] handleSyncError",
      info: {
        operation: getLoggableOperation(operation),
        error: objectModule.safeErrorAsJson(error),
      },
    });

    switch (error.handlingType) {
      case SyncErrorHandlingType.Revert: {
        await this.skipAndRevertOperationById(operation.operationId);
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        }
        break;
      }
      case SyncErrorHandlingType.RetryWithLimit:
      case SyncErrorHandlingType.RetryForever: {
        error.retryEndActionHandler?.();
        if (error.displayType === SyncErrorDisplayType.Toast) {
          toastModule.triggerToast({
            content: error.toastMessage ?? error.message,
            toastId: error.operationId,
          });
        } else if (error.displayType === SyncErrorDisplayType.Modal) {
          this.didFail(error);
        } else if (error.displayType === SyncErrorDisplayType.None) {
          await this.skipAndRevertOperationById(operation.operationId);
        }
        break;
      }
      case SyncErrorHandlingType.Fail: {
        this.didFail(error);
        break;
      }
    }
  }

  didFail(syncError?: SyncError) {
    this.isFailing = true;
    if (syncError) this.processingError = syncError;
    this.pause();
  }

  // QUEUE STATUS
  setState = (state: QueueProcessingState) => {
    this.processingState = state;
  };

  get isLoading() {
    return this.processingState === QueueProcessingState.NotReady;
  }

  get lastProcessedAt(): Maybe<DateTime> {
    return this.lastProcessingItemStop
      ? DateTime.fromJSDate(this.lastProcessingItemStop)
      : undefined;
  }

  public pause() {
    logger.debug({ message: "[SYNC][SyncActionQueue] Pausing..." });
    this.setState(QueueProcessingState.Paused);
  }

  public resume = () => {
    logger.debug({
      message: "[SYNC][SyncActionQueue] Resuming...",
      info: objectModule.safeAsJson({
        state: this.processingState,
        processingError: this.processingError,
        isFailing: this.isFailing,
        lastProcessingItemStart: this.lastProcessingItemStart?.toISOString(),
        lastProcessingItemStop: this.lastProcessingItemStop?.toISOString(),
      }),
    });

    if (this.processingState !== QueueProcessingState.Paused) return;

    this.setState(QueueProcessingState.Ready);
    this.processingError = undefined;
    this.isFailing = false;
    this.process();
  };

  async reset() {
    logger.debug({ message: "[Queue] Reset" });
    await localDb.queue.clear();
    runInAction(() => {
      this.lastSentOperation = undefined;
      this.isFailing = false;
      this.processingError = undefined;
      this.lastProcessingItemStart = undefined;
      this.lastProcessingItemStop = undefined;
      this.setState(QueueProcessingState.NotReady);
    });
    this.resume();
  }

  // OPERATIONS
  public async addToProcessing(operation: SyncOperationGeneric) {
    await this.db.queue.processing.add(serializeSyncOperation(operation));
  }

  public async removeFromProcessing(operationId: string) {
    await this.db.queue.processing.delete(operationId);
  }

  public async moveFromProcessingToPending(operation: SyncOperationGeneric) {
    await this.db.transaction("rw", [this.db.queue.processing, this.db.queue.pending], async () => {
      await this.removeFromProcessing(operation.operationId);
      await this.addToPending(operation);
    });
  }

  public async addToPending(operation: SyncOperationGeneric) {
    await this.db.queue.pending.add(serializeSyncOperation(operation));
  }

  public async removeFromPending(operationId: string) {
    await this.db.queue.pending.delete(operationId);
  }

  public async getOperationsByModelId(modelId: Uuid): Promise<SyncOperationGeneric[]> {
    const pendingOperations = await this.db.queue.pending.where({ modelId }).toArray();
    const processingOperations = await this.db.queue.processing.where({ modelId }).toArray();
    return [...processingOperations, ...pendingOperations].map(e =>
      deserializeSyncOperation(this.store, e)
    );
  }

  // OPTIMISTIC UPDATES
  public async applyOptimisticUpdate(
    syncOperationId: Uuid,
    update: OptimisticSyncUpdate<SyncModelData>
  ) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] applyOptimisticUpdate",
      info: { optimisticSyncUpdate: objectModule.safeAsJson(update) },
    });

    await this.db.queue.optimisticUpdates.add(serializeOptimisticUpdate(update, syncOperationId));
  }

  public async removeAllOptimisticUpdates(
    optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[]
  ) {
    await this.db.queue.optimisticUpdates.bulkDelete(
      optimisticUpdates.map(e => e.optimistic_update_id)
    );
  }

  public async removeAllOptimisticUpdatesBySyncOperationId(syncOperationId: string) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeAllOptimisticUpdatesBySyncOperationId",
      info: { syncOperationId },
    });

    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ sync_operation_id: syncOperationId })
      .toArray();

    await this.removeAllOptimisticUpdates(optimisticUpdates);
  }

  public async removeAllOptimisticUpdatesByModelKind(modelKind: SyncModelKind) {
    logger.debug({
      message: "[SYNC][SyncActionQueue] removeAllOptimisticUpdatesByModelKind",
      info: { modelKind },
    });
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_kind: modelKind })
      .toArray();
    await this.removeAllOptimisticUpdates(optimisticUpdates);
  }

  public async removeAllOptimisticUpdatesByModelId(modelId: Uuid) {
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .toArray();
    await this.removeAllOptimisticUpdates(optimisticUpdates);
  }
}
