import React, { useEffect, useState } from "react";
import { SyncModelPermissionEntryWithStatus } from "@/domains/sync-scopes/types";
import { css, cx } from "@/domains/emotion";
import { observer } from "mobx-react-lite";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { getLabelForRoleKind, getRoleNameForRoleKind } from "@/domains/sync-scopes";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import {
  MdsDropdownButton,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsButtonSize } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { FadeInAnimator } from "@/design-system/components/animation";
import { noop } from "lodash-es";

interface ShareSheetPermissionRowLayoutProps {
  isUnsharing: boolean;
  permission: SyncModelPermissionEntryWithStatus;
  children: React.ReactNode;
  canUpdateAccess: boolean;
  canRevokeAccess: boolean;
  handleRevokeAccess: () => void;
  handleUpdateAccessToReadOnly: () => void;
  handleUpdateAccessToReadWrite: () => void;
  handleCancelOperation: (syncOperationId: string) => void;
}

export const ShareSheetPermissionRowLayout = observer<ShareSheetPermissionRowLayoutProps>(
  ({
    permission,
    children,
    isUnsharing,
    canUpdateAccess,
    canRevokeAccess,
    handleRevokeAccess,
    handleUpdateAccessToReadOnly,
    handleUpdateAccessToReadWrite,
    handleCancelOperation,
  }) => {
    const isOwner = permission.role_kind === "OWNER";
    const isReadOnly = permission.role_kind === "VIEWER";
    const isPending = permission.status === "PENDING" || isUnsharing;

    const dropdownContentList: MdsDropdownContentList = {
      items: [],
    };
    if (canUpdateAccess) {
      dropdownContentList.items.push({
        id: "edit",
        kind: MdsDropdownItemKind.Button,
        iconKind: MdsIconKind.Pen,
        label: `Can edit`,
        isChecked: !isReadOnly,
        onClick: isReadOnly ? handleUpdateAccessToReadWrite : handleUpdateAccessToReadOnly,
      });
    }
    if (canRevokeAccess) {
      dropdownContentList.items.push({
        id: "delete",
        kind: MdsDropdownItemKind.Button,
        iconKind: MdsIconKind.Ban,
        label: "Remove access",
        onClick: handleRevokeAccess,
      });
    }

    const [showPendingIcon, setShowPendingIcon] = useState<boolean>(false);
    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (isPending) timeout = setTimeout(() => setShowPendingIcon(true), 1000);
      else setShowPendingIcon(false);
      return () => clearTimeout(timeout);
    }, [isPending]);

    const pendingText = (() => {
      if (isUnsharing && permission.role_kind !== "OWNER") return "Revoking...";
      if (!permission.operationKind) return "Pending...";
      if (permission.operationKind.startsWith("GRANT")) return "Sharing...";
      if (permission.operationKind.startsWith("UPDATE")) return "Updating...";
      if (permission.operationKind.startsWith("REVOKE")) return "Revoking...";
      return "Pending...";
    })();

    const pendingDropdownContentList: MdsDropdownContentList = {
      items: [
        {
          id: "label",
          kind: MdsDropdownItemKind.Detail,
          text: `Updating access to ${getRoleNameForRoleKind(permission.role_kind).toLowerCase()}...`,
        },
        {
          id: "cancel-button",
          kind: MdsDropdownItemKind.Button,
          label: "Cancel",
          onClick: () =>
            permission.operationId ? handleCancelOperation(permission.operationId) : noop,
          isDisabled: !permission.operationId,
          iconKind: MdsIconKind.Ban,
        },
      ],
    };

    return (
      <div className={cx(containerStyles, permission.status === "PENDING" && pendingStyles)}>
        <div className={rowWrapperStyles}>{children}</div>
        {isOwner && (
          <MdsText size={MdsTextSize.Small} className={secondaryTextStyles}>
            {getLabelForRoleKind(permission.role_kind)}
          </MdsText>
        )}
        {!isOwner && isPending && (
          <div className={pendingWrapperStyles}>
            {showPendingIcon && (
              <FadeInAnimator
                element={
                  <MdsDropdownButton
                    iconKind={MdsIconKind.InfoCircle}
                    size={MdsButtonSize.XSmall}
                    contentList={pendingDropdownContentList}
                  />
                }
              />
            )}
            <MdsText size={MdsTextSize.Small} className={secondaryTextStyles}>
              {pendingText}
            </MdsText>
          </div>
        )}
        {!isOwner && !isPending && (
          <MdsDropdownButton
            isDisabled={!dropdownContentList.items.length}
            size={MdsButtonSize.Small}
            className={dropdownStyles}
            placement="below-right-alignment"
            iconPosition={MdsButtonIconPosition.Right}
            iconKind={(isOpen: boolean) => (isOpen ? MdsIconKind.AngleUp : MdsIconKind.AngleDown)}
            label={getLabelForRoleKind(permission.role_kind)}
            contentList={dropdownContentList}
          />
        )}
      </div>
    );
  }
);

const containerStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "4px",
  margin: "2px 0",
  width: "100%",
});

const pendingStyles = css({
  opacity: 0.5,
});

const rowWrapperStyles = css({
  display: "flex",
  alignItems: "center",
  minWidth: 0,
  overflow: "hidden",
});

const secondaryTextStyles = css({
  color: mdsColors().grey.x500,
});

const pendingWrapperStyles = css({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const dropdownStyles = css({
  marginRight: "-13px",

  "&> .mdn-dropdown-button": {
    fontWeight: mdsFontWeights().regular,
  },

  "&> .mdn-dropdown-button:disabled .mds-btn-icon": {
    color: mdsColors().grey.x400,
  },
});
