import { MdsSkeleton } from "@/design-system/components/loader/MdsSkeleton";

const NOTE_SKELETON_DELAY = 1000;

export function NoteSkeleton() {
  return (
    <div>
      <h1>
        <MdsSkeleton delay={NOTE_SKELETON_DELAY} />
      </h1>
      <p>
        <MdsSkeleton count={3} delay={NOTE_SKELETON_DELAY} />
      </p>
      <p>
        <MdsSkeleton count={5} delay={NOTE_SKELETON_DELAY} />
      </p>
    </div>
  );
}
