import { FC, useMemo, memo, ReactNode } from "react";

import { ClassNamesArg, css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import {
  MdsButtonVariant,
  MdsButtonShape,
  MdsButtonSize,
  MdsButtonIconPosition,
  MdsSelectionVariant,
} from "@/design-system/components/button/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { mdsFontWeights, mdsFontSizes } from "@/design-system/foundations/typography";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsDotPulseLoaderKind, MdsDotPulseLoader } from "@/design-system/components/loader";
import { OnClick } from "@/domains/react/types";
import { MdsTooltip, MdsTooltipConfig } from "@/design-system/components/tooltip";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { TestableProps } from "@/domains/testing/types";

export interface MdsButtonProps extends EmotionClassStyles, TestableProps {
  variant?: MdsButtonVariant;
  shape?: MdsButtonShape;
  size?: MdsButtonSize;
  focusable?: boolean;
  label: string | (() => ReactNode) | null;
  onClick?: OnClick;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: HTMLButtonElement["type"];
  iconKind?: MdsIconKind | (() => ReactNode);
  iconPosition?: MdsButtonIconPosition; // Left by default.
  // Overrides dropdown for MdsButtonVariant.OutlineAccent, MdsButtonVariant.Outline, MdsButtonVariant.Transparent.
  isSelected?: boolean;
  // Modifies selected styles. Overrides dropdown for MdsButtonVariant.OutlineAccent, MdsButtonVariant.Outline, MdsButtonVariant.Transparent.
  selectionVariant?: MdsSelectionVariant;
  children?: React.ReactNode;
  // Displays angle-down on the right, unless selected is true. Ignores iconPosition.
  dropdown?: boolean;
  outlet?: boolean;
  // Displayed on the right, only for MdsButtonVariant.MultiIcon. Ignores iconPosition and dropdown.
  icons?: JSX.Element;
  tooltipConfig?: MdsTooltipConfig;
  innerStyles?: {
    Icon?: ClassNamesArg;
    Label?: ClassNamesArg;
  };
}

const ICON_FONT_WEIGHT = mdsFontWeights().regular; // 400
const LABEL_FONT_WEIGHT = mdsFontWeights().semiBold; // 600

const buttonStyles = css({
  display: "flex",
  textWrap: "nowrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  borderRadius: mdsBorderRadius().medium,
  fontWeight: LABEL_FONT_WEIGHT,
  fontSize: mdsFontSizes().small,
  cursor: "pointer",
  transition: "0.25s ease",
  userSelect: "none",
  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
    backgroundColor: mdsColors().grey.x300,
  },
  WebkitAppRegion: "no-drag",
});

const buttonRowStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: 4,
});

const buttonShapeStyleMapping: { [key in MdsButtonShape]: string } = {
  [MdsButtonShape.Round]: css({
    borderRadius: "100px !important",
  }),
  [MdsButtonShape.Square]: css({
    borderRadius: 8,
  }),
};

const buildButtonVariantStyleMapping: ({
  isSelected,
  selectionVariant,
}: {
  isSelected?: boolean;
  selectionVariant?: MdsSelectionVariant;
}) => { [key in MdsButtonVariant]: string } = ({ isSelected, selectionVariant }) => ({
  [MdsButtonVariant.Brand]: css({
    backgroundColor: mdsColors().primary.x500,
    color: mdsColors().grey.x0,
    "&:hover": {
      backgroundColor: mdsColors().primary.x400,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().primary.x600,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x0,
    },
  }),
  [MdsButtonVariant.Primary]: css({
    backgroundColor: mdsColors().secondary.x400,
    color: mdsColors().grey.x0,
    "&:hover": {
      backgroundColor: mdsColors().secondary.x500,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().secondary.x600,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x0,
    },
  }),
  [MdsButtonVariant.Secondary]: css({
    backgroundColor: mdsColors().grey.x0,
    color: mdsColors().secondary.x400,
    borderColor: mdsColors().grey.x100,
    borderWidth: 1,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: mdsColors().secondary.x100,
      borderColor: mdsColors().secondary.x400,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().secondary.x200,
      borderColor: mdsColors().secondary.x400,
    },
    "& .mds-btn-icon": {
      color: mdsColors().secondary.x400,
    },
  }),
  [MdsButtonVariant.Tertiary]: css({
    backgroundColor: mdsColors().grey.x0,
    color: mdsColors().grey.x700,
    borderColor: mdsColors().grey.x100,
    borderWidth: 1,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: mdsColors().grey.x50,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().grey.x100,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x700,
    },
  }),
  [MdsButtonVariant.Text]: css({
    backgroundColor: mdsColors().grey.x0,
    color: mdsColors().grey.x600,
    "&:hover": {
      backgroundColor: mdsColors().grey.x100,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().grey.x200,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x600,
    },
  }),
  [MdsButtonVariant.TextTertiary]: css({
    backgroundColor: "transparent",
    color: mdsColors().grey.x700,
    "&:hover": {
      backgroundColor: mdsColors().grey.x100,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().grey.x200,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x700,
    },
  }),
  [MdsButtonVariant.TextTertiaryOutlined]: css({
    backgroundColor: "transparent",
    color: mdsColors().grey.x700,
    "&:hover": {
      backgroundColor: mdsColors().grey.x100,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().grey.x200,
      transition: "0.2s all",
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x700,
    },
    borderColor: mdsColors().grey.x100,
    borderWidth: 1,
    borderStyle: "solid",
  }),
  // New variants:
  [MdsButtonVariant.FilledPrimary]: css({
    alignItems: "center",
    // Hack: solid gradient avoid flickering when leaving the hover state (its background is a real gradient).
    background: `var(--gradient-mem-gradient, linear-gradient(0deg, #F22E61 0%, #F22E61 100%))`,
    // Original:
    // background: `var(--color-brand-400, #F22E61)`,
    border: `1px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--neutral-white, #FFF)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: ICON_FONT_WEIGHT,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--neutral-white, #FFF)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: LABEL_FONT_WEIGHT,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: `var(--color-brand-400, #F22E61)`,
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--neutral-white, #FFF);`,
    },
    "&:hover": {
      background: `var(--gradient-mem-gradient, linear-gradient(214deg, #EB2487 0%, #F93939 100%))`,
      boxShadow: `0px 3px 6px 0px rgba(242, 46, 97, 0.20)`,
    },
  }),
  [MdsButtonVariant.FilledDark]: css({
    alignItems: "center",
    color: mdsColors().grey.x25,
    background: mdsColors().grey.x600,
    border: `1px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "&:disabled": {
      opacity: 0.4,
    },
    "&:hover": {
      background: mdsColors().grey.x700,
    },
    "&:active": {
      background: mdsColors().grey.x800,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x25,
    },
  }),
  [MdsButtonVariant.Filled]: css({
    alignItems: "center",
    background: `var(--color-grey-50, #F3F3F5)`,
    border: `1px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: ICON_FONT_WEIGHT,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: LABEL_FONT_WEIGHT,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: `var(--color-grey-50, #F3F3F5)`,
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&:hover": {
      background: `var(--color-grey-100, #E8E9ED)`,
    },
  }),
  [MdsButtonVariant.OutlinedAccent]: css({
    alignItems: "center",
    border: `1px solid var(--color-grey-100, #E8E9ED)`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-accent-500, #3360D6)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: ICON_FONT_WEIGHT,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-accent-500, #3360D6)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: LABEL_FONT_WEIGHT,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: `var(--color-grey-50, #F3F3F5)`,
      border: `1px solid var(--color-grey-50, #F3F3F5)`,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-400, #9397A5)`,
    },
    "&:hover": {
      background: `var(--color-grey-25, #F8F8FA)`,
      border: `1px solid var(--color-grey-100, #E8E9ED)`,
    },
    "&.mds-btn-toggled": {
      background: `var(--color-accent-100, #EDF2FF)`,
      border: `1px solid var(--color-accent-100, #EDF2FF)`,
    },
  }),
  [MdsButtonVariant.Outlined]: css({
    alignItems: "center",
    border: `1px solid ${mdsColors().grey.x100}`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    color: mdsColors().grey.x600,
    "&:hover": {
      backgroundColor: mdsColors().grey.x100,
      borderColor: mdsColors().grey.x100,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().grey.x200,
      borderColor: mdsColors().grey.x200,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x600,
    },
    ...(isSelected &&
      selectionVariant === MdsSelectionVariant.Standard && {
        backgroundColor: mdsColors().grey.x100,
        borderColor: mdsColors().grey.x100,
      }),
    ...(isSelected &&
      selectionVariant === MdsSelectionVariant.Accent && {
        backgroundColor: mdsColors().grey.x600,
        borderColor: mdsColors().grey.x600,
        color: mdsColors().grey.x50,
        "&:hover": {
          backgroundColor: mdsColors().grey.x700,
          borderColor: mdsColors().grey.x700,
        },
        "&:active": {
          backgroundColor: mdsColors().grey.x800,
          borderColor: mdsColors().grey.x800,
        },
        "& .mds-btn-icon": {
          color: mdsColors().grey.x50,
        },
      }),
  }),
  [MdsButtonVariant.Toggled]: css({
    alignItems: "center",
    border: `1px solid ${mdsColors().mblue.x100}`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    color: mdsColors().mblue.x500,
    backgroundColor: mdsColors().mblue.x50,
    "&:hover": {
      backgroundColor: mdsColors().mblue.x100,
      borderColor: mdsColors().mblue.x200,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().mblue.x50,
      borderColor: mdsColors().mblue.x100,
    },
    "& .mds-btn-icon": {
      color: mdsColors().mblue.x500,
    },
    "& .mds-btn-label": {
      color: mdsColors().mblue.x500,
    },
  }),
  [MdsButtonVariant.Transparent]: css({
    alignItems: "center",
    border: `1px solid transparent`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: ICON_FONT_WEIGHT,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: LABEL_FONT_WEIGHT,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: "unset",
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&:hover": {
      background: `var(--color-grey-100, #E8E9ED)`,
    },
    "&.mds-btn-toggled": {
      background: `var(--color-grey-50, #F3F3F5)`,
    },
  }),
  [MdsButtonVariant.MultiIcon]: css({
    alignItems: "center",
    border: `1px solid var(--color-grey-100, #E8E9ED)`,
    borderRadius: 100,
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    padding: "6px 12px",
    "& .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: ICON_FONT_WEIGHT,
      lineHeight: "normal",
      textAlign: "center",
    },
    "& .mds-btn-label": {
      color: `var(--color-grey-600, #3F424D)`,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: LABEL_FONT_WEIGHT,
      lineHeight: "16px",
    },
    "&:disabled": {
      background: `var(--color-grey-50, #F3F3F5)`,
      border: `1px solid var(--color-grey-100, #E8E9ED)`,
      opacity: 0.4,
    },
    "&:disabled .mds-btn-label, &:disabled .mds-btn-icon": {
      color: `var(--color-grey-600, #3F424D)`,
    },
    "&:hover": {
      background: `var(--color-grey-100, #E8E9ED)`,
      border: `1px solid var(--color-grey-100, #E8E9ED)`,
    },
  }),
  [MdsButtonVariant.Danger]: css({
    backgroundColor: mdsColors().red.x600,
    color: mdsColors().grey.x0,
    "&:hover": {
      backgroundColor: mdsColors().red.x700,
      transition: "0.2s all",
    },
    "&:active": {
      backgroundColor: mdsColors().red.x800,
    },
    "& .mds-btn-icon": {
      color: mdsColors().grey.x0,
    },
  }),
});

const buttonSizeStyleMapping: { [key in MdsButtonSize]: string } = {
  [MdsButtonSize.Small]: css({
    padding: "6px 12px",
  }),
  [MdsButtonSize.Medium]: css({
    padding: "10px 12px",
  }),
  [MdsButtonSize.Large]: css({
    minHeight: 50,
    padding: 12,
    "& .mds-btn-icon": {
      fontSize: 16,
    },
    "& .mds-btn-label": {
      fontSize: 16,
      lineHeight: "24px",
    },
  }),
  [MdsButtonSize.XLarge]: css({
    padding: "12px 16px",
    "& .mds-btn-icon": {
      fontSize: 20,
    },
    "& .mds-btn-label": {
      fontSize: 20,
      lineHeight: "28px",
    },
  }),
  [MdsButtonSize.XSmall]: css({
    padding: "4px 8px",
  }),
  [MdsButtonSize.XXSmall]: css({
    minHeight: 24,
    height: 24,
    width: 24,
    padding: 4,
  }),
};

const mdsLoaderKindMapping: { [key in MdsButtonVariant]: MdsDotPulseLoaderKind } = {
  [MdsButtonVariant.Brand]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Primary]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Secondary]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Tertiary]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.Text]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.TextTertiary]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.TextTertiaryOutlined]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.FilledPrimary]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.FilledDark]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Filled]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.OutlinedAccent]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.Outlined]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.Transparent]: MdsDotPulseLoaderKind.Dark,
  [MdsButtonVariant.MultiIcon]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Danger]: MdsDotPulseLoaderKind.Light,
  [MdsButtonVariant.Toggled]: MdsDotPulseLoaderKind.Dark,
};

const outletStyles = css({
  borderTopRightRadius: "16px !important",
  borderBottomRightRadius: "16px !important",
  borderBottomLeftRadius: "4px !important",
  borderTopLeftRadius: "16px !important",
});

const buttonLoadingContentStyles = css({
  opacity: "0",
});

export const MdsButton: FC<MdsButtonProps> = memo(function MdsButton({
  variant = MdsButtonVariant.Brand,
  shape = MdsButtonShape.Square,
  size = MdsButtonSize.Medium,
  iconPosition = MdsButtonIconPosition.Left,
  selectionVariant = MdsSelectionVariant.Standard,
  focusable = true,
  label,
  isLoading,
  isDisabled,
  isSelected,
  onClick,
  iconKind,
  icons,
  dropdown,
  outlet,
  className,
  children,
  tooltipConfig,
  innerStyles,
  testId,
  type,
}) {
  const variantStyles = buildButtonVariantStyleMapping({ isSelected, selectionVariant })[variant];
  const shapeStyles = buttonShapeStyleMapping[shape];

  const isNewVariant = (() => {
    // New variants specify their entire styles in buttonVariantStyleMapping so extra styles
    // should not be applied.
    switch (variant) {
      case MdsButtonVariant.FilledPrimary:
      case MdsButtonVariant.FilledDark:
      case MdsButtonVariant.Filled:
      case MdsButtonVariant.OutlinedAccent:
      case MdsButtonVariant.Outlined:
      case MdsButtonVariant.Transparent:
      case MdsButtonVariant.MultiIcon:
        return true;
      default:
        return false;
    }
  })();

  const sizeStyles = buttonSizeStyleMapping[size];

  /**
   * In the future, we can consider making the buttons darker
   * during loading animations.
   *
   * For now, we just "lock" it.
   */
  const dynamicLoadingStyles = useMemo(() => {
    if (isLoading) {
      return css({
        pointerEvents: "none",
      });
    }

    return css({});
  }, [isLoading]);

  const iconButtonDisabledStyles = css({
    "&:disabled": {
      backgroundColor: iconKind ? "transparent" : mdsColors().grey.x50,
      color: mdsColors().grey.x400,
    },
  });

  const combinedStyles = cx(
    buttonStyles,
    variantStyles,
    shapeStyles,
    sizeStyles,
    dynamicLoadingStyles,
    !isNewVariant && iconButtonDisabledStyles,
    outlet && outletStyles,
    className
  );

  const buttonContent = useMemo(() => {
    const mdsLoaderKind = mdsLoaderKindMapping[variant];

    if (isLoading) {
      return (
        <>
          <MdsDotPulseLoader kind={mdsLoaderKind} />
          {label && typeof label !== "function" && (
            <span className={buttonLoadingContentStyles}>{label}</span>
          )}
        </>
      );
    }
    const iconButtonContentStyles =
      !!iconKind &&
      !isNewVariant &&
      css({
        color: isDisabled ? mdsColors().grey.x400 : mdsColors().grey.x600,
      });
    const icon =
      !!iconKind &&
      (typeof iconKind === "function" ? (
        iconKind()
      ) : (
        <MdsIcon innerStyles={{ Icon: { className: "mds-btn-icon" } }} kind={iconKind} />
      ));
    return (
      <>
        {(iconPosition !== MdsButtonIconPosition.Right || icons) && icon}
        {typeof label === "function" && label()}
        {label && typeof label !== "function" && (
          <span className={cx(iconButtonContentStyles, innerStyles?.Label ?? "mds-btn-label")}>
            {label}
          </span>
        )}
        {icons ||
          (isNewVariant && isSelected && (
            <MdsIcon
              innerStyles={{ Icon: { className: "mds-btn-icon" } }}
              kind={MdsIconKind.Exit}
            />
          )) ||
          (dropdown && (
            <MdsIcon
              innerStyles={{ Icon: { className: "mds-btn-icon" } }}
              kind={MdsIconKind.AngleDown}
            />
          )) ||
          (iconPosition === MdsButtonIconPosition.Right && icon)}
      </>
    );
  }, [
    dropdown,
    iconKind,
    iconPosition,
    icons,
    innerStyles?.Label,
    isDisabled,
    isLoading,
    isNewVariant,
    isSelected,
    label,
    variant,
  ]);

  const innerButtonContent = (() => {
    return (
      <>
        {isNewVariant && (
          <>
            {buttonContent}
            {children}
          </>
        )}
        {!isNewVariant && (
          <div className={buttonRowStyles}>
            {buttonContent}
            {children}
          </div>
        )}
      </>
    );
  })();

  const fullButtonContent = focusable ? (
    <button
      className={combinedStyles}
      onClick={onClick}
      disabled={isDisabled}
      data-test-id={testId}
      type={type}
    >
      {innerButtonContent}
    </button>
  ) : (
    <span className={combinedStyles} onClick={onClick} data-test-id={testId}>
      {innerButtonContent}
    </span>
  );

  if (tooltipConfig) {
    return <MdsTooltip config={tooltipConfig}>{fullButtonContent}</MdsTooltip>;
  }

  return fullButtonContent;
});
