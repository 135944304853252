import { inflect } from "inflection";
import {
  computed,
  reaction,
  makeObservable,
  observable,
  action,
  override,
  runInAction,
} from "mobx";
import { CollectionItemListObservable } from "@/store/collection-items/CollectionItemListObservable";
import { CollectionModelData, IndexedCollectionSyncUpdateValue } from "@/store/collections/types";
import { WithAppStore } from "@/store/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { SyncModelKind } from "@/store/sync/types";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { Uuid } from "@/domains/global/identifiers";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { GrantableSyncScopeRoleKind } from "@/domains/sync-scopes/types";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { DeleteCollectionOperation } from "@/store/sync/operations/collections/DeleteCollectionOperation";
import { AddCollectionToFavoritesOperation } from "@/store/sync/operations/favorites/AddCollectionToFavoritesOperation";
import { RemoveCollectionFromFavoritesOperation } from "@/store/sync/operations/favorites/RemoveCollectionFromFavoritesOperation";
import { MarkCollectionViewedOperation } from "@/store/sync/operations/recents/MarkCollectionViewedOperation";
import { GrantCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaEmailAddressOperation";
import { GrantCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/GrantCollectionAclViaSpaceAccountOperation";
import { UpdateCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaEmailAddressOperation";
import { UpdateCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/UpdateCollectionAclViaSpaceAccountOperation";
import { RevokeCollectionAclViaEmailAddressOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaEmailAddressOperation";
import { RevokeCollectionAclViaSpaceAccountOperation } from "@/store/sync/operations/collections/RevokeCollectionAclViaSpaceAccountOperation";
import { MakeCollectionPrivateOperation } from "@/store/sync/operations/collections/MakeCollectionPrivateOperation";
import { appRoutes } from "@/app/router";
import { liveQuery } from "dexie";

export class CollectionObservable extends BaseSyncModel<CollectionModelData> {
  public modelKind = SyncModelKind.Collection;

  isLocked: boolean = false;
  title: string | undefined;
  description: string | undefined;
  itemList: CollectionItemListObservable;

  // INDEXES
  modifiedAt: string;
  createdAt: string;
  lastViewedAt: string;
  lastAddedToAt: string;
  lastInteractedAt: string;
  sharedWithMeAt: string;

  constructor({
    id,
    data,
    store,
  }: {
    id: string;
    data: IndexedCollectionSyncUpdateValue;
  } & WithAppStore) {
    super({ id, data, store });
    this.id = id;

    this.title = data.model_data.title;
    this.description = data.model_data.description;
    this.itemList = new CollectionItemListObservable({ collectionId: this.id, store });

    this.modifiedAt = data.modified_at;
    this.createdAt = data.created_at;
    this.lastAddedToAt = data.last_added_to_at;
    this.lastViewedAt = data.last_viewed_at;
    this.lastInteractedAt = data.last_interacted_at;
    this.sharedWithMeAt = data.shared_with_me_at;

    makeObservable(this, {
      // OBSERVABLES
      modelKind: observable,
      isLocked: observable,
      title: observable,
      description: observable,
      itemList: observable,

      // INDEXES
      subscribeToLocal: override,
      modifiedAt: observable,
      createdAt: observable,
      lastAddedToAt: observable,
      lastViewedAt: observable,
      lastInteractedAt: observable,
      sharedWithMeAt: observable,

      // OVERRIDES
      isShared: override,

      // EDITABLE PROPERTIES
      lock: action,
      unlock: action,
      setTitle: action,
      setDescription: action,
      save: action,

      // PROPERTIES
      label: computed,
      sharedAt: computed,
      receivedAt: computed,
      lastMentionedAt: computed,
      lastInteractedAtByMe: computed,
      existsRemotely: computed,
      isTrashed: computed,
      path: computed,

      // FAVORITES
      favoriteItemId: computed,
      favoriteItem: computed,
      isFavorited: computed,

      // SHARING
      overview: computed,

      // SPACE ACCOUNT COLLECTION
      spaceAccountCollectionId: computed,
      spaceAccountCollection: computed,

      // ACTIONS
      delete: action,
      grantAccessViaEmailAddress: action,
      grantAccessViaSpaceAccount: action,
      updateAccessViaEmailAddress: action,
      updateAccessViaSpaceAccount: action,
      revokeAccessViaEmailAddress: action,
      revokeAccessViaSpaceAccount: action,
      makePrivate: action,
      toggleFavorite: action,
      addToRecents: action,
    });

    reaction(
      () => this.modelData,
      modelData => {
        if (!this.isLocked) {
          this.title = modelData.title;
          this.description = modelData.description;
        }
      },
      { fireImmediately: true }
    );
  }

  subscribeToLocal() {
    this.localDataSubscription?.unsubscribe();
    this.localDataSubscription = liveQuery(() =>
      this.store.collections.localTable.get(this.id)
    ).subscribe({
      next: data => {
        if (data)
          runInAction(() => {
            this.data = data;
            this.modifiedAt = data.modified_at;
            this.createdAt = data.created_at;
            this.lastAddedToAt = data.last_added_to_at;
            this.lastViewedAt = data.last_viewed_at;
            this.lastInteractedAt = data.last_interacted_at;
          });
      },
    });
  }

  // EDITABLE PROPERTIES
  public lock() {
    this.isLocked = true;
  }

  public unlock() {
    this.isLocked = false;
  }

  setTitle(title: string) {
    this.lock();
    this.title = title;
  }

  setDescription(description: string) {
    this.lock();
    this.description = description;
  }

  public async save() {
    if (this.title !== this.modelData.title || this.description !== this.modelData.description)
      await new UpdateCollectionOperation({
        store: this.store,
        payload: {
          id: this.id,
          title: this.title,
          description: this.description,
        },
      }).execute();

    this.unlock();
  }

  // PROPERTIES
  get label() {
    return this.title || UNTITLED_COLLECTION_TITLE;
  }

  get sharedAt(): string | null | undefined {
    return this.modelData.shared_at;
  }

  // TODO: Implement proper received_at when ready
  get receivedAt(): string | null | undefined {
    return this.sharedAt;
  }

  get lastMentionedAt(): string | undefined {
    // TODO:
    return undefined;
  }

  get lastInteractedAtByMe(): string | undefined {
    return this.spaceAccountCollection?.lastInteractedAt;
  }

  get existsRemotely() {
    return this.remoteData.model_version > 0;
  }

  get isTrashed() {
    // Collections don't go into the trash. They are deleted immediately.
    return false;
  }

  get path(): string {
    return appRoutes.collectionsView({ params: { collectionId: this.id } }).path;
  }

  get favoriteItemId(): Uuid {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.id,
    });
  }

  get favoriteItem(): FavoriteItemObservable | undefined {
    return this.store.favoriteItems.get(this.favoriteItemId);
  }

  get isFavorited(): boolean {
    return this.store.favoriteItems.pool.has(this.favoriteItemId);
  }

  get isShared(): boolean {
    return this.modelData.shared_at !== null;
  }

  get overview() {
    let overviewStr = `${this.isShared ? "Shared" : "Private"} Collection`;

    const sizeData = this.itemList.sizeData;

    /**
     * If the collection items have loaded, we display the counts
     *
     * E.g. => "Shared Collection · 100 items"
     */
    if (sizeData.data) {
      overviewStr += ` · ${sizeData.data} ${inflect("item", sizeData.data)}`;
    }

    return overviewStr;
  }

  get spaceAccountCollectionId(): Uuid {
    return resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.id,
    });
  }

  get spaceAccountCollection(): SpaceAccountCollectionObservable | undefined {
    return this.store.spaceAccountCollections.get(this.spaceAccountCollectionId);
  }

  public async delete() {
    await new DeleteCollectionOperation({ store: this.store, payload: { id: this.id } }).execute();
  }

  public async grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new GrantCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async grantAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: Uuid;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new GrantCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public async updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new UpdateCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async updateAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: Uuid;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    await new UpdateCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public async revokeAccessViaEmailAddress({ targetEmailAddress }: { targetEmailAddress: string }) {
    await new RevokeCollectionAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async revokeAccessViaSpaceAccount({
    targetSpaceAccountId,
  }: {
    targetSpaceAccountId: Uuid;
  }) {
    await new RevokeCollectionAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public async makePrivate() {
    await new MakeCollectionPrivateOperation({
      store: this.store,
      payload: {
        id: this.id,
      },
    }).execute();
  }

  public async toggleFavorite() {
    if (this.isFavorited)
      await new RemoveCollectionFromFavoritesOperation({
        store: this.store,
        payload: { collection_id: this.id },
      }).execute();
    else
      await new AddCollectionToFavoritesOperation({
        store: this.store,
        payload: { collection_id: this.id },
      }).execute();
  }

  public addToRecents = async () => {
    await new MarkCollectionViewedOperation({
      store: this.store,
      payload: { collection_id: this.id },
    }).execute();
  };
}
