import { IndexedBoolean } from "@/domains/common/types";
import { SearchSuggestionType } from "@/domains/search";
import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { notesModule } from "@/modules/notes";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import { NoteContentDocumentModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  HydrationSyncUpdateResponse,
  PreloadingState,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { isClientUpgradeError } from "@/store/utils/errors";
import { debounce } from "lodash-es";
import { makeObservable, runInAction, action, observable, override, ObservableMap } from "mobx";
import pRetry, { AbortError, FailedAttemptError } from "p-retry";

const NOTE_CONTENT_PRELOADING_DISABLED_KEY = "note_content_preloading_disabled";
const MAX_REFETCH_COUNT = 5;

export class AppStoreNoteContentDocumentStore extends BaseSyncModelStore<
  NoteContentDocumentObservable,
  NoteContentDocumentModelData
> {
  public isPreloadingDisabled: boolean = false;
  public preloadingState: PreloadingState = PreloadingState.NotStarted;
  refetchCounter: ObservableMap<string, number> = new ObservableMap();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.NoteContentDocument, ...injectedDeps });
    makeObservable<this, "updateOfflineSearch" | "getContent">(this, {
      updateOfflineSearch: true,
      getContent: true,
      refetchCounter: observable,
      processSyncUpdate: override,
      isPreloadingDisabled: observable,
      preloadingState: observable,
      createSyncModel: false,
      fetch: action,
      debouncedFetch: action,
      initializePreloadingState: action,
      setIsPreloadingDisabled: action,
      togglePreloading: action,
      preloadAll: action,
    });
    this.initializePreloadingState();
  }

  createSyncModel(
    updateValue: SyncUpdateValue<NoteContentDocumentModelData>
  ): NoteContentDocumentObservable {
    return new NoteContentDocumentObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  public debouncedFetch = debounce(async (id: string) => this.fetch(id), 500, { maxWait: 2500 });

  async fetch(id: string): Promise<NoteContentDocumentObservable | undefined> {
    let hasError = false;
    let nextPageToken: string | undefined = undefined;
    const updates: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        const response = await pRetry(
          async () => {
            const resp = await api.get(`/v2/sync/updates/hydrate`, {
              params: {
                query: {
                  space_id: this.store.spaces.myPersonalSpaceId,
                  sync_model_kind: SyncModelKind.NoteContentDocument,
                  sync_model_ids: [id],
                },
              },
            });

            if (resp.error) {
              if (isClientUpgradeError(resp.error)) {
                this.store.sync.handleClientUpgradeError();
                throw new AbortError("Client upgrade error");
              }
              throw new Error(JSON.stringify(resp.error));
            }

            return resp;
          },
          {
            retries: 3,
            onFailedAttempt: (error: FailedAttemptError) => {
              logger.warn({
                message:
                  "[SYNC][AppStoreNoteContentDocumentStore] Retrying fetch note content document",
                info: { id, attempt: error.attemptNumber, retriesLeft: error.retriesLeft },
              });
            },
          }
        );

        const actions = response.data?.results || [];
        updates.push(...actions);
        nextPageToken = response.data?.hydration_session_cursor || undefined;
      } catch (e) {
        logger.error({
          message: "[SYNC][AppStoreNoteContentDocumentStore] Error fetching note content document",
          info: { id, error: objectModule.safeErrorAsJson(e as Error) },
        });
        hasError = true;
        break;
      }
    } while (nextPageToken);

    if (hasError) return; // Returning undefined should be treated as an error

    for (const update of updates) {
      await this.processSyncUpdate(update, { endpoint: "/v2/sync/updates/hydrate (one)" });
      /**
       * We generate a random ID for storing sync updates in the local DB.
       */
      const syncUpdateId = uuidModule.generate();
      await this.store.sync.db.syncUpdates.put(
        {
          ...update,
          /**
           * After we roll out CVRs, we can remove the syncUpdates table.
           *
           * TODO: @MacroMackie follow up with this on Monday, Nov 11.
           */
          sync_id: `deprecated-${syncUpdateId}`,
        },
        syncUpdateId
      );
    }

    return this.getAsync(id);
  }

  public async initializePreloadingState() {
    const storedValue = await localStorage.getItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY);
    this.isPreloadingDisabled = storedValue === "true";
  }

  public setIsPreloadingDisabled(value: boolean) {
    this.isPreloadingDisabled = value;
    localStorage.setItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY, value.toString());
  }

  public togglePreloading() {
    this.setIsPreloadingDisabled(!this.isPreloadingDisabled);
  }

  async preloadAll() {
    if (this.isPreloadingDisabled) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents is disabled",
      });

      return;
    }

    const noteContentPreloadingState =
      await this.store.memDb.settings.getNoteContentPreloadingState();

    if (noteContentPreloadingState === PreloadingState.Complete) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloading was already complete",
      });

      return;
    }

    if (
      this.preloadingState === PreloadingState.InProgress ||
      this.preloadingState === PreloadingState.Complete
    ) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloading is already in progress or complete",
      });

      return;
    }

    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents...");
    runInAction(() => (this.preloadingState = PreloadingState.InProgress));

    let hasError = false;
    let nextPageToken: string | undefined = undefined;
    let hasNextPage = false;
    const updates: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        if (hasNextPage && !nextPageToken) {
          logger.error({
            message:
              "[SYNC][AppStoreNoteContentDocumentStore] Unexpected hydration_session_cursor while preloading note content documents",
            info: { hasNextPage, nextPageToken: nextPageToken || "undefined" },
          });

          return;
        }

        const response: HydrationSyncUpdateResponse = await api.get(`/v2/sync/updates/hydrate`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              hydration_session_cursor: nextPageToken,
              only_sync_model_kinds: [SyncModelKind.NoteContentDocument],
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) this.store.sync.handleClientUpgradeError();
          hasError = true;
          break;
        }

        const actions = response.data?.results || [];
        updates.push(...actions);
        nextPageToken = response.data?.hydration_session_cursor || undefined;
        hasNextPage = response.data?.has_next_page || false;
      } catch (e) {
        logger.error({
          message:
            "[SYNC][AppStoreNoteContentDocumentStore] Error preloading note content documents",
          info: { error: objectModule.safeErrorAsJson(e as Error) },
        });
        hasError = true;
        break;
      }
    } while (hasNextPage);

    if (hasError) {
      runInAction(() => (this.preloadingState = PreloadingState.Failed));
      logger.error({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Failed to preload note content documents",
      });
      return;
    }

    runInAction(async () => {
      for (const update of updates)
        await this.processSyncUpdate(update, { endpoint: "/v2/sync/updates/hydrate (all)" });

      this.preloadingState = PreloadingState.Complete;
    });

    await this.store.memDb.settings.setNoteContentPreloadingState(PreloadingState.Complete);

    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloaded");
  }

  async processSyncUpdate(
    update: SyncUpdate<SyncModelData>,
    { hydrating, endpoint = "/v2/sync-updates" }: { hydrating?: boolean; endpoint?: string } = {}
  ) {
    if (hydrating) {
      await super.processSyncUpdate(update);
      return;
    }

    const updateValue = update.value as SyncUpdateValue<NoteContentDocumentModelData>;

    if (update.kind == "UPSERTED" || update.kind == "ACL_UPSERTED") {
      if (updateValue.model_version > 1 && !updateValue.model_data.encoded_content) {
        logger.error({
          message: "[SYNC][AppStoreNoteContentDocumentStore] Received invalid note encoded content",
          info: { update, endpoint },
        });
        const refetchCount = this.refetchCounter.get(updateValue.model_id) || 0;
        if (refetchCount < MAX_REFETCH_COUNT) {
          this.debouncedFetch(updateValue.model_id);
          this.refetchCounter.set(updateValue.model_id, refetchCount + 1);
        } else {
          await super.processSyncUpdate(update);
        }
        return;
      }
    }

    this.refetchCounter.delete(updateValue.model_id);

    await super.processSyncUpdate(update);
    await this.updateOfflineSearch(updateValue.model_data);
  }

  private async updateOfflineSearch(value: NoteContentDocumentModelData) {
    try {
      if (!value) {
        return;
      }

      const note = await this.store.notes.getAsync(value.note_id);
      if (!note) {
        logger.error({
          message:
            "[SYNC][AppStoreNoteContentDocumentStore] Attempt to update offline search for non-existent note",
          info: { noteId: value.note_id },
        });

        return;
      }

      // TODO: add lastMentionedAt for includeMentionedAt
      const generateSortKey = (): number => {
        const sortKey = note.lastViewedAt || note.locallyCreatedAt;

        return new Date(sortKey || "").getTime();
      };

      const offlineEntry = {
        modelId: value.note_id,
        content: this.getContent(value.encoded_content),
        type: SearchSuggestionType.NOTE,
        sortKey: generateSortKey(),
        isAvailable: (note.isAvailable ? 1 : 0) as IndexedBoolean,
      };

      await this.store.search.updateFullTextEntry(offlineEntry);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreNoteContentDocumentStore] Error updating offline search",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  private getContent(encodedContent: string | null): string {
    if (!encodedContent) return "";

    const { plaintext, primaryLabel, secondaryLabel } =
      notesModule.convertEncodedContentToNoteContent(encodedContent);

    return `${primaryLabel || ""} ${secondaryLabel || ""} ${plaintext || ""}`;
  }
}
