import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { DeleteCollectionModal } from "@/components/modal/delete-collection/DeleteCollectionModal";
import { CollectionsListPageList } from "@/pages/collections/components/CollectionsListPageList";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { FadeBottom } from "@/components/horizontal-scroll/FadeBottom";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { CollectionsListPageHeader } from "@/pages/collections/components/CollectionsListPageHeader";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";

interface CollectionsListPageProps {}

export const CollectionsListPage: FC<CollectionsListPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.collectionsListPage;

  useEffectOnMount(() => {
    page.resetState();
  });

  /**
   * Track views of the collections list page.
   */
  useEffect(() => {
    trackEvent(TrackedEvent.CollectionsView, {
      lens: page.lens,
      sort_by: page.sortBy,
    });
  }, [page.lens, page.sortBy]);

  return (
    <>
      <ShareSheetModal store={page.shareSheetModal} />
      <DeleteCollectionModal state={page.deleteCollectionModal} />
      <CollectionsListPageHeader />
      <MdsPanelContainer>
        <CollectionsListPageList />
        <FadeBottom />
      </MdsPanelContainer>
    </>
  );
});
