import styled from "@emotion/styled";

export const MdsModalTitle = styled.div(({ theme }) => ({
  minHeight: 40,
  gap: theme.spacing.sm,
  display: "flex",
  alignItems: "center",
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.large,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.large,
}));
