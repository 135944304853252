import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsModalBody } from "@/design-system/components/modal/MdsModalBody";
import { MdsModalContent } from "@/design-system/components/modal/MdsModalContent";
import { MdsModalTitle } from "@/design-system/components/modal/MdsModalTitle";
import { useAppStore } from "@/store";
import { Maybe } from "@/domains/common/types";
import { useState } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { MdsSpinner } from "@/design-system/components/loader/MdsSpinner";

export const ImportIsRunningModal = observer(function ImportIsRunningModal() {
  const { store } = useAppStore();
  const [dismissedForImportId, setDismissedForImportId] = useState<Maybe<string>>();
  const lastImport = store.dataImports.latestImport;
  const isOpen = (dismissedForImportId !== lastImport?.id && lastImport?.isRunning) ?? false;

  const handleClose = () => {
    setDismissedForImportId(lastImport?.id);
  };

  return (
    <MdsModal isModalOpen={isOpen} handleCloseModal={handleClose}>
      <MdsModalBody>
        <MdsModalTitle>
          Importing <MdsSpinner size={16} />
        </MdsModalTitle>
        <MdsModalContent>
          <div>
            Your import is in progress. The Mem 2.0 Alpha will be available once your import is
            complete.
          </div>
        </MdsModalContent>
        <ButtonContainer>
          <MdsButton label="Okay" onClick={handleClose} variant={MdsButtonVariant.FilledDark} />
        </ButtonContainer>
      </MdsModalBody>
    </MdsModal>
  );
});

const ButtonContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));
