import { ChangeEventHandler } from "react";
import styled from "@emotion/styled";
import { mdsColors } from "@/design-system/foundations";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { css } from "@/domains/emotion";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { Direction } from "@/components/note/editor/top-bar/organize";

interface MdsDropdownSearchInputHeaderProps {
  onChange: ChangeEventHandler;
  onKeyDirection?: (direction: Direction) => void;
  onEscape?: () => void;
  onEnter?: () => void;
  onClear?: () => void;
  value: string;
  placeholder?: string;
}
export const MdsDropdownSearchInputHeader = ({
  onChange,
  value,
  placeholder,
  onKeyDirection,
  onEscape,
  onEnter,
  onClear,
}: MdsDropdownSearchInputHeaderProps) => {
  return (
    <Search>
      <MdsIcon kind={MdsIconKind.Search} innerStyles={{ Icon: { className: searchIconStyles } }} />
      <SearchInput
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        ref={el => el?.focus()}
        onKeyDown={e => {
          switch (e.key) {
            case "ArrowUp":
              e.preventDefault();
              onKeyDirection?.(Direction.UP);
              return true;
            case "ArrowDown":
              e.preventDefault();
              onKeyDirection?.(Direction.DOWN);
              return true;
            case "Escape":
              e.preventDefault();
              onEscape?.();
              return true;
            case "Enter":
            case "Return":
              e.preventDefault();
              onEnter?.();
              return true;
          }
        }}
      />
      <MdsIconButton
        iconKind={MdsIconKind.Exit}
        variant={MdsIconButtonVariant.FullyTransparent}
        iconStyles={clearIconStyles}
        onClick={onClear}
      />
    </Search>
  );
};

const searchIconStyles = css({
  color: mdsColors().grey.x500,
});

const clearIconStyles = css({
  color: mdsColors().grey.x600,
});

const Search = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: theme.spacing.sm,
  padding: `0 ${theme.spacing.sm} 0 ${theme.spacing.sm}`,
  minHeight: 40,
}));

const SearchInput = styled.input(({ theme }) => ({
  flex: 1,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  "&::placeholder": {
    color: theme.colors.grey.x400,
  },
}));
