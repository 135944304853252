import React from "react";
import { css, cx } from "@/domains/emotion";
import { colorModule } from "@/modules/color";
import AnonUser from "@/assets/legacy-images/anon-user.svg?react";
import { ProfileSize, ProfileVariant } from "@/components/layout/components/account-profile/types";

interface AccountProfileImageContentProps {
  className?: string;
  size: ProfileSize;
  variant?: ProfileVariant;
  photoUrl: string | null;
  displayName: string;
}

export const AccountProfileImageContent: React.FC<AccountProfileImageContentProps> = ({
  className,
  size,
  variant = ProfileVariant.Rounded,
  photoUrl,
  displayName,
}) => {
  const classNames = cx(
    baseIconStyles,
    variantStyleMapping[variant],
    sizeStyleMapping[size],
    className
  );

  if (photoUrl) {
    return <img src={photoUrl} alt={displayName} className={classNames} />;
  }

  if (!displayName) {
    return <AnonUser className={classNames} />;
  }

  const color = colorModule.hashStringToColor({
    str: displayName,
    config: { lightness: 0.9, saturation: 0.1 },
  });
  const backgroundColor = colorModule.colorToHex({ color });
  const displayNameInitial = displayName[0].toUpperCase();

  return (
    <div className={classNames} style={{ backgroundColor }}>
      {displayNameInitial}
    </div>
  );
};

const sizeStyleMapping: Record<ProfileSize, ReturnType<typeof css>> = {
  [ProfileSize.Small]: css({
    height: "20px",
    width: "20px",
    fontSize: "12px",
  }),
  [ProfileSize.Standard]: css({
    height: "24px",
    width: "24px",
    fontSize: "14px",
  }),
  [ProfileSize.Medium]: css({
    height: "32px",
    width: "32px",
    fontSize: "20px",
  }),
  [ProfileSize.Large]: css({
    height: "34px",
    width: "34px",
    fontSize: "16px",
  }),
};

const baseIconStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  flexShrink: 0,
});

const variantStyleMapping: Record<ProfileVariant, ReturnType<typeof css>> = {
  [ProfileVariant.Rounded]: css({
    borderRadius: "50%",
  }),
  [ProfileVariant.Square]: css({
    borderRadius: "4px",
  }),
};
