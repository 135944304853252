import { observer } from "mobx-react-lite";

import { useAppStore } from "@/store";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { CollectionsListPageListEmpty } from "@/pages/collections/components/CollectionsListPageListEmpty";

export const CollectionsListPageList = observer(() => {
  const { pageStore } = useAppStore();

  const page = pageStore.collectionsListPage;
  if (page.shouldShowEmptyState) {
    return <CollectionsListPageListEmpty />;
  }

  return <MdsVirtualizedItemList rows={page.itemRows} loading={page.isLoading} />;
});
