import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { DataImportSyncModelData } from "@/store/data-imports/types";

export type DataImportIndexData = {
  started_at: string;
};

export class DataImportIndexes {
  private data: SyncUpdateValue<DataImportSyncModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<DataImportSyncModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<DataImportSyncModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<DataImportSyncModelData>) || remoteData;
  }

  get started_at() {
    return this.data.model_data.started_at;
  }

  get indexes(): DataImportIndexData {
    return { started_at: this.started_at };
  }
}
