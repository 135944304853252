import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { groupLens, LensGroup } from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListItemRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { generateShortDateString } from "@/domains/date/date";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { INoteObservable } from "@/store/note";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { DeleteNotePermanentlyModalStore } from "@/components/modal/delete-notes-permanently/DeleteNotePermanentlyModalStore";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { Subscription } from "node_modules/react-hook-form/dist/utils/createSubject";
import Dexie, { liveQuery } from "dexie";
import { EventContext } from "@/domains/metrics/context";

type TrashItemIndexTuple = [trashed_at: string, model_id: string];

export class TrashPageStore extends AppSubStore {
  listState: ListStateObservable;
  deleteNotePermanentlyModal: DeleteNotePermanentlyModalStore;
  supportsRestoreListAction = true;
  showConfirmModal = false;

  liveQuerySubscription?: Subscription;
  subscribedItems: TrashItemIndexTuple[] = [];

  eventContext = EventContext.TrashMultiselectActions;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.deleteNotePermanentlyModal = new DeleteNotePermanentlyModalStore(injectedDeps, {});
    this.listState = new ListStateObservable({
      ...injectedDeps,
      listStateProvider: this,
      deleteNotePermanentlyModal: this.deleteNotePermanentlyModal,
    });

    makeObservable(this, {
      initializeQuery: action,
      liveQuerySubscription: observable,

      subscribedItems: observable,
      items: computed,
      itemRows: computed,
      orderedItemIds: computed,
      generateItemRow: false,
      generateItemDropdown: false,

      listState: false,
      deleteNotePermanentlyModal: false,
      supportsRestoreListAction: observable,
      showConfirmModal: observable,
      openConfirmModal: action,
      closeConfirmModal: action,
      emptyTrash: action,
      isEmptyingTrash: computed,

      eventContext: false,
    });

    onBecomeObserved(this, "subscribedItems", () => this.initializeQuery());
    onBecomeUnobserved(this, "subscribedItems", () => this.liveQuerySubscription?.unsubscribe());
  }

  openConfirmModal = () => {
    this.showConfirmModal = true;
  };

  closeConfirmModal = () => {
    this.showConfirmModal = false;
  };

  emptyTrash = async () => {
    for (const row of this.itemRows) {
      if (row.type !== MdsItemListRowType.AsyncNote) continue;
      // TODO: Implement a single action to delete all notes on the backend.
      await this.store.notes.deleteNote({ noteId: row.payload.noteId });
    }

    this.closeConfirmModal();
  };

  get isEmptyingTrash() {
    // Assuming any note deletion is the result of emptyTrash.
    const deleteNoteOperations =
      this.store.sync.actionQueue.operationsByOperationKind.get("DELETE_NOTE");
    if (!deleteNoteOperations) return false;
    return deleteNoteOperations.length > 0;
  }

  generateItemDropdown({ noteObservable }: { noteObservable: INoteObservable }): MdsItemDropdown {
    return {
      items: [
        {
          id: `restore-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Redo,
          label: "Restore this note",
          onClick: async () => await noteObservable.restoreFromTrash(),
        },
        {
          id: `delete-permanently-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete this note forever",
          onClick: () => this.deleteNotePermanentlyModal.open({ notes: [noteObservable] }),
        },
      ],
    };
  }

  initializeQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.store.notes.localTable
        .where("[trashed_at+model_id]")
        .above(["\0", Dexie.minKey])
        .reverse()
        .keys();
    }).subscribe({
      next: ids =>
        runInAction(() => {
          this.subscribedItems = ids as unknown as TrashItemIndexTuple[];
        }),
    });
  }

  get items(): LensGroup<TrashItemIndexTuple>[] {
    const items = this.subscribedItems.map(item => ({
      dateTime: new Date(item[0]),
      item: item,
    }));
    return groupLens(items);
  }

  generateItemRow(note: INoteObservable): MdsItemListItemRowData {
    return {
      type: MdsItemListRowType.Item,
      key: note.id,
      size: MdsItemListSize.XLarge,
      payload: {
        id: note.id,
        kind: MdsItemKind.Note,
        createPreviewState: () =>
          new ItemPreviewState({
            store: this.store,
            id: note.id,
            kind: MdsItemKind.Note,
          }),
        label: note.title,
        onClick: () => this.store.navigation.goToNote({ noteId: note.id }),
        sharedBy: note.sharedBy,
        dateLabel: generateShortDateString(note.trashedAt || ""),
        listState: this.listState,
        dropdown: this.generateItemDropdown({ noteObservable: note }),
        extraRows: [
          {
            id: `note-${note.id}-content`,
            content: () => (
              <MdsItemListRowFeaturedContent
                collections={sortCollectionsForChips(note.collectionList?.allCollections || [])}
                snippet={
                  note.collectionList?.allCollections.length
                    ? undefined
                    : [{ text: note.secondaryTitle || "No additional text" }]
                }
              />
            ),
          },
        ],
      },
    };
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.items) {
      // Add header for each section
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      // Add each item afterwards
      for (const item of group.items) {
        output.push({
          type: MdsItemListRowType.AsyncNote,
          key: item.item[1],
          size: MdsItemListSize.XLarge,
          payload: {
            noteId: item.item[1],
            itemRow: (note: INoteObservable) => this.generateItemRow(note),
          },
        });
      }
    }

    if (output.length) {
      output.push({
        type: MdsItemListRowType.Padding,
        key: "padding",
        payload: { height: 50 },
      });
    }
    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }
}
