import { getChatItems } from "@/store/chat/getChatItems";
import { AppStore } from "@/store/AppStore";
import { ChatMessageContext, ChatItem, ChatItemKind, ChatHistoryIndexes } from "@/store/chat/types";
import { makeObservable, observable, action } from "mobx";
import { MentionChip } from "@/pages/chat/ChatInput";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { MENTION_PREFIX_COLLECTION, MENTION_PREFIX_NOTE } from "@/store/chat/constants";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { getCollectionItemSubtitle } from "@/components/collection-item-subtitle";
import { Maybe } from "@/domains/common/types";
import styled from "@emotion/styled";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { uuidModule } from "@/modules/uuid";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note/note";
import { noop } from "lodash-es";
import { css } from "@/domains/emotion";
import { mdsFontSizes, mdsSpacings } from "@/design-system/foundations";
import { generateRecentDateString } from "@/domains/date/date";
import { DateTime } from "luxon";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { notesModule } from "@/modules/notes";
import { INoteObservable } from "@/store/note";
import {
  MemCommonEditorActionKind,
  MemCommonEditorFileVariant,
  MemCommonEditorSlashCommand,
} from "@mem-labs/common-editor";
import { Subscription } from "dexie";
import { EventContext } from "@/domains/metrics/context";
import { fileModule } from "@/modules/file";
import { fileModule as commonEditorFileModule } from "@mem-labs/common-editor";
import { v4 as uuidv4 } from "uuid";

const MAX_RESULTS = 100;

enum InsertFileMode {
  Attachment,
  Image,
}

export class ChatHistory {
  private store: AppStore;

  context?: ChatMessageContext;
  items: ChatItem[] = [];
  contextStartedAt?: string;
  liveQuerySubscription: Maybe<Subscription>;
  searchSuggestions: SearchSuggestion[] = [];
  mentionQuery = "";

  constructor({
    indexes,
    context,
    store,
  }: {
    indexes: ChatHistoryIndexes[];
    context?: ChatMessageContext;
    store: AppStore;
  }) {
    this.context = context;
    this.store = store;

    this.items = getChatItems(indexes);
    const lastSectionHeader = this.items.findLast(item => item.kind === ChatItemKind.SectionHeader);
    if (
      lastSectionHeader &&
      "locallyCreatedAt" in lastSectionHeader &&
      typeof lastSectionHeader.locallyCreatedAt === "string"
    ) {
      this.contextStartedAt = lastSectionHeader.locallyCreatedAt;
    }

    makeObservable<
      ChatHistory,
      "store" | "getSlashCommandChips" | "getSearchSuggestionType" | "insertFileAction"
    >(this, {
      getSearchSuggestionType: true,
      liveQuerySubscription: true,
      store: false,
      insertFileAction: false,
      getSlashCommandChips: false,
      getAvailableChips: false,
      contextStartedAt: false,
      context: observable,
      items: observable,
      submitChatMessage: action,
      mentionQuery: observable,
      searchSuggestions: observable,
      search: action,
    });
  }

  private getSearchSuggestionType(mentionChar: string) {
    if (mentionChar === MENTION_PREFIX_NOTE) {
      return SearchSuggestionType.NOTE;
    }

    if (mentionChar === MENTION_PREFIX_COLLECTION) {
      return SearchSuggestionType.COLLECTION;
    }

    return SearchSuggestionType.OTHER;
  }

  private async insertFileAction(
    mode: InsertFileMode
  ): Promise<Maybe<MemCommonEditorSlashCommand>> {
    const isImageMode = mode === InsertFileMode.Image;
    const file = await fileModule.askForUserFile(isImageMode ? "image/*" : undefined);
    if (!file) return;

    const fileId = uuidv4();
    const { info, variant } = await commonEditorFileModule.extractInfoAsync({ file, fileId });

    if (!fileModule.checkIfFileCanBeUploaded({ info })) return;

    if (isImageMode) {
      if (variant !== MemCommonEditorFileVariant.Image) return;

      return {
        kind: MemCommonEditorActionKind.InsertImage,
        payload: {
          imageId: fileId,
          imageMimeType: info.fileMimeType,
          imageName: file.name,
          encodedImageContent: info.encodedFileContent,
        },
      };
    }

    return {
      kind: MemCommonEditorActionKind.InsertFile,
      payload: { fileName: file.name, ...info },
    };
  }

  getSlashCommandChips = (mentionQueryText: string) => {
    const groupTitles = {
      Date: "Insert date",
      Attachment: "Insert attachment",
      Formatting: "Insert formatting",
    };
    const today = DateTime.now().toLocaleString(DateTime.DATE_FULL);
    const yesterday = DateTime.now().minus({ days: 1 }).toLocaleString(DateTime.DATE_FULL);
    const tomorrow = DateTime.now().plus({ days: 1 }).toLocaleString(DateTime.DATE_FULL);
    const SLASH_COMMANDS: (SearchSuggestion & {
      iconKind: MdsIconKind;
      content?: MentionChip["content"];
      groupTitle: MentionChip["groupTitle"];
      action: MentionChip["action"];
    })[] = [
      {
        modelId: "insert-date-today",
        type: SearchSuggestionType.OTHER,
        label: `Date Day Today's ${today}`,
        content: () => (
          <MenuLabel>
            Today <LighterText>– {today}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `date day today's ${today}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: today,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-date-yesterday",
        type: SearchSuggestionType.OTHER,
        label: `Yesterday's ${yesterday}`,
        content: () => (
          <MenuLabel>
            Yesterday <LighterText>– {yesterday}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `yesterday's ${yesterday}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: yesterday,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-date-tomorrow",
        type: SearchSuggestionType.OTHER,
        label: `Tomorrow's ${tomorrow}`,
        content: () => (
          <MenuLabel>
            Tomorrow <LighterText>– {tomorrow}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `tomorrow's {tomorrow}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: tomorrow,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: CommandIds.InsertImage,
        type: SearchSuggestionType.OTHER,
        label: "Image Photo Picture PNG JPG JPEG",
        content: () => <MenuLabel>Image</MenuLabel>,
        lowercaseLabel: "image photo picture png jpg jpeg",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Attachment,
        iconKind: MdsIconKind.Image,
        action: () => this.insertFileAction(InsertFileMode.Image),
        isAvailable: 1,
      },
      {
        modelId: CommandIds.InsertFile,
        type: SearchSuggestionType.OTHER,
        label: "File PDF ZIP CSV MP3 MP4 WAV MOV DOC XLS",
        content: () => <MenuLabel>File</MenuLabel>,
        lowercaseLabel: "file pdf zip csv mp3 mp4 wav mov doc xls",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Attachment,
        iconKind: MdsIconKind.Paperclip,
        action: () => this.insertFileAction(InsertFileMode.Attachment),
        isAvailable: 1,
      },
      {
        modelId: "insert-header-1",
        type: SearchSuggestionType.OTHER,
        label: "Large Header Section Title H1",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Large Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "large header section title h1",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H1,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 1,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-header-2",
        type: SearchSuggestionType.OTHER,
        label: "Medium Header Section Subtitle H2",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Medium Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "medium header section subtitle h2",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H2,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 2,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-header-3",
        type: SearchSuggestionType.OTHER,
        label: "Small Header Section h3",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Small Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <Key>#</Key>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "small header section h3",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H3,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 3,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-check-list",
        type: SearchSuggestionType.OTHER,
        label: "Task List Checklist TODOs Checkbox Action Item",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Task List</MenuLabel>
            <KeyboardShortcut>
              <Key>[</Key>
              <Key>]</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "task list checklist todos checkbox action item",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.Tasks,
        action: {
          kind: MemCommonEditorActionKind.ToggleChecklistTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-bullet-list",
        type: SearchSuggestionType.OTHER,
        label: "Bulleted List Unordered",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Bulleted List</MenuLabel>
            <KeyboardShortcut>
              <Key>-</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "bulleted list unordered",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.List,
        action: {
          kind: MemCommonEditorActionKind.ToggleBulletListTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-ordered-list",
        type: SearchSuggestionType.OTHER,
        label: "Numbered List Ordered",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Numbered List</MenuLabel>
            <KeyboardShortcut>
              <Key>1</Key>
              <Key>.</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "numbered list ordered",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.NumberedList,
        action: {
          kind: MemCommonEditorActionKind.ToggleOrderedListTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-table",
        type: SearchSuggestionType.OTHER,
        label: "Table Sheet Column Row",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Table</MenuLabel>
            <KeyboardShortcut>
              <Key>|</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "table sheet column row",
        lastViewedAt: "",
        sortKey: 0,
        iconKind: MdsIconKind.Table,
        groupTitle: groupTitles.Formatting,
        action: {
          kind: MemCommonEditorActionKind.InsertTable,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-codeblock",
        type: SearchSuggestionType.OTHER,
        label: "Code Script JavaScript Function CSS HTML",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Code</MenuLabel>
            <KeyboardShortcut>
              <Key>`</Key>
              <Key>`</Key>
              <Key>`</Key>
              <Key>⏎</Key>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "code script javascript function css html",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.Code,
        action: {
          kind: MemCommonEditorActionKind.ToggleCodeBlockTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-blockquote",
        type: SearchSuggestionType.OTHER,
        label: "Quote Blockquote Quotation",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Quote</MenuLabel>
            <KeyboardShortcut>
              <Key>&gt;</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "quote blockquote quotation",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.QuoteRight,
        action: {
          kind: MemCommonEditorActionKind.ToggleQuoteBlockTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-divider",
        type: SearchSuggestionType.OTHER,
        label: "HR Divider Separator Line Rule Break",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Divider</MenuLabel>
            <KeyboardShortcut>
              <Key>-</Key>
              <Key>-</Key>
              <Key>-</Key>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "hr divider separator line rule break",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.HorizontalRule,
        action: {
          kind: MemCommonEditorActionKind.InsertHorizontalRule,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
    ];
    const suggestions = mentionQueryText
      ? this.store.search.inMemory(mentionQueryText, SLASH_COMMANDS)
      : SLASH_COMMANDS;

    const chips = suggestions.map(e => {
      const command = SLASH_COMMANDS.find(c => e.modelId === c.modelId);
      const chip: MentionChip = {
        id: e.modelId,
        label: e.label,
        className: command ? menuButtonStyles : undefined,
        content: command?.content,
        iconKind: command?.iconKind,
        groupTitle: command?.groupTitle,
        action: command?.action,
      };
      return chip;
    });

    return chips;
  };

  search = async (mentionQueryText: string, mentionChar: string, excludeCurrent?: boolean) => {
    const suggestionType = this.getSearchSuggestionType(mentionChar);
    let andCondition = (item: SearchSuggestion) => item.type === suggestionType;
    if (excludeCurrent) {
      andCondition = item => item.modelId !== this.context?.id && item.type === suggestionType;
    }

    const suggestions = await this.store.search.forSuggestions(
      mentionQueryText,
      "mentions",
      andCondition
    );

    return suggestions;
  };

  // TODO: extract to a separate module
  getAvailableChips = async (
    mentionQuery: string,
    opts?: { inlineCreation?: boolean; excludeCurrent?: boolean }
  ): Promise<MentionChip[]> => {
    const mentionChar = mentionQuery[0];
    const mentionQueryText = mentionQuery.slice(1).trim();

    if (mentionChar === "/") return this.getSlashCommandChips(mentionQueryText);
    const suggestions = await this.search(mentionQueryText, mentionChar, opts?.excludeCurrent);

    const isSearchingCollections = mentionQuery.startsWith("#");
    const iconKind = isSearchingCollections ? MdsIconKind.Collection : MdsIconKind.Document;

    const lowercaseMentionQueryText = mentionQueryText.toLowerCase();

    let addCreateNew = !!opts?.inlineCreation && !!lowercaseMentionQueryText.length;

    const getDropdownButtonContentForNote = (
      id: string,
      label: string
    ): Maybe<{
      isOwnedByMe: INoteObservable["isOwnedByMe"];
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
    }> => {
      const note = this.store.notes.get(id);
      if (note) {
        const subtitle = generateRecentDateString(
          DateTime.fromISO(
            note.lastMentionedAt ||
              note.lastViewedAt ||
              note.receivedAt ||
              note.locallyCreatedAt ||
              ""
          ),
          { skipFullDayName: true }
        );
        return {
          isOwnedByMe: note.isOwnedByMe,
          iconKind: () => <NoteIcon toggleSelected={noop} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {note.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>{subtitle}</MentionSubtitle>
            </MentionContent>
          ),
        };
      }
    };

    const getDropdownButtonContentForCollection = (
      id: string,
      label: string
    ): Maybe<{
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
      isOwnedByMe: CollectionObservable["isOwnedByMe"];
    }> => {
      const collection = this.store.collections.get(id);
      if (collection) {
        return {
          iconKind: () => <CollectionIcon collectionId={id} />,
          content: () => (
            <MentionContent>
              <MentionTitle>
                <span>{label}</span>
                {collection.isShared && (
                  <SmallerIcon
                    kind={MdsIconKind.Shared}
                    innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
                  />
                )}
              </MentionTitle>
              <MentionSubtitle>
                {getCollectionItemSubtitle(collection.itemList.sizeData)}
              </MentionSubtitle>
            </MentionContent>
          ),
          isOwnedByMe: collection.isOwnedByMe,
        };
      }
    };

    const prepareChips = (getExtraInfo?: typeof getDropdownButtonContentForCollection) => {
      const exactMatchIndex = suggestions.findIndex(
        suggestion => suggestion.lowercaseLabel.trim() === lowercaseMentionQueryText
      );
      if (exactMatchIndex >= 0) {
        const exactMatch = suggestions[exactMatchIndex];
        suggestions.splice(exactMatchIndex, 1);
        suggestions.unshift(exactMatch);
      }
      suggestions.slice(0, MAX_RESULTS).forEach(suggestion => {
        const { modelId: id, label, lowercaseLabel } = suggestion;
        const { iconKind: icon, content, isOwnedByMe } = getExtraInfo?.(id, label) ?? {};
        chips.push({
          id,
          label,
          iconKind: icon ?? iconKind,
          content,
        });
        if (lowercaseLabel.trim() === lowercaseMentionQueryText && isOwnedByMe) {
          addCreateNew = false;
        }
      });
    };

    const chips: MentionChip[] = [];
    switch (mentionChar) {
      case MENTION_PREFIX_NOTE: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentNotesInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, MAX_RESULTS)
            .forEach(note => {
              const { id, title } = note;
              const label = title || UNTITLED_NOTE_TITLE;
              const {
                iconKind: icon,
                content,
                isOwnedByMe,
              } = getDropdownButtonContentForNote(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
              if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
                addCreateNew = false;
              }
            });
          break;
        }

        prepareChips(getDropdownButtonContentForNote);
        break;
      }
      case MENTION_PREFIX_COLLECTION: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentCollectionsInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, 5)
            .forEach(collection => {
              const { id, title } = collection;
              const label = title || UNTITLED_COLLECTION_TITLE;
              const { iconKind: icon, content } =
                getDropdownButtonContentForCollection(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
            });
          break;
        }

        prepareChips(getDropdownButtonContentForCollection);
        break;
      }
    }
    const exactMatchIndex = chips.findIndex(
      chip => chip.label.trim().toLowerCase() === lowercaseMentionQueryText
    );
    if (exactMatchIndex >= 0) {
      const exactMatch = chips[exactMatchIndex];
      chips.splice(exactMatchIndex, 1);
      chips.unshift(exactMatch);
    }
    if (addCreateNew) {
      const id = uuidModule.generate();
      chips.push({
        alwaysVisible: true,
        id: id,
        label: mentionQueryText,
        iconKind: () => (
          <PlusIconWrapper>
            <MdsIcon kind={MdsIconKind.Plus} />
          </PlusIconWrapper>
        ),
        content: () => (
          <MentionContent>
            <MentionTitle>“{mentionQueryText}”</MentionTitle>
            <MentionSubtitle>
              Create new {isSearchingCollections ? "collection" : "note"}
            </MentionSubtitle>
          </MentionContent>
        ),
        beforeSelection: async () => {
          if (isSearchingCollections) {
            await this.store.collections.createCollection({
              collectionId: id,
              title: mentionQueryText,
              description: "",
              eventContext: EventContext.EditorInline,
            });
            return;
          }
          this.store.notes.createNote({
            noteId: id,
            eventContext: EventContext.EditorInline,
          });

          const encodedContent = notesModule.convertMdxToEncodedContent("# " + mentionQueryText);
          const queue = this.store.notes.getNoteQueue({ noteId: id });
          queue.push(
            new UpdateNoteContentUsingDiffOperation({
              store: this.store,
              payload: {
                id,
                encoded_content_diff: encodedContent || "",
              },
              primaryLabel: mentionQueryText,
              secondaryLabel: "",
            })
          );
        },
      });
    }
    return chips;
  };

  submitChatMessage = async ({ markdownContent }: { markdownContent: string }) => {
    if (!markdownContent.trim()) return;

    await this.store.chatMessages.sendNewMessage(
      markdownContent,
      this.context,
      this.contextStartedAt
    );
  };
}

const MentionContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const MentionTitle = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey.x600,
  display: "flex",
  gap: theme.spacing.sm,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const SmallerIcon = styled(MdsIcon)({
  height: 12,
  width: 12,
});

const smallerIconFontSizeStyles = css({
  fontSize: mdsFontSizes().xxsmall,
  width: 12,
});

const MentionSubtitle = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const PlusIconWrapper = styled.div({
  width: 40,
  height: 40,
  // borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const menuButtonStyles = css({
  borderRadius: mdsSpacings().xs,
  padding: mdsSpacings().sm,
});

const LighterText = styled.span(({ theme }) => ({
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  color: theme.colors.grey.x500,
}));

const MenuLabel = styled.span(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const SpaceBetween = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const KeyboardShortcut = styled.span(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.xs,
}));

const Key = styled.span(({ theme }) => ({
  display: `flex`,
  height: `16px`,
  minWidth: `16px`,
  padding: `1px`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  gap: `8px`,

  borderRadius: `4px`,
  border: `1px solid ${theme.colors.grey.x100}`,

  color: theme.colors.grey.x500,
  textAlign: `center`,
  fontSize: theme.fontSizes.xxxsmall,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
}));

const SpaceKey = styled(Key)({
  padding: `1px 3px`,
});

export enum CommandIds {
  InsertImage = "insert-image",
  InsertFile = "insert-file",
}
