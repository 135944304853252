import { isEqual, max, min } from "lodash-es";
import { makeObservable, observable, runInAction } from "mobx";
import pRetry from "p-retry";

import { IAsyncData } from "@/domains/async/AsyncData";
import { objectModule } from "@/modules/object";

import {
  FetchValue,
  GetCachedValue,
  GetInternalState,
  SetCachedValue,
  SetInternalState,
} from "@/store/queries/types";
import { Maybe } from "@/domains/common/types";
import { logger } from "@/modules/logger";
import { AppStoreAuthStore } from "@/store/auth/AppStoreAuthStore";
import { AppStoreQueriesCacheStore } from "@/store/queries/AppStoreQueriesCacheStore";

interface BackoffParams {
  initialBackoffMs?: number;
  maxBackoffMs?: number;
  backoffMultiplier?: number;
  retries?: number;
}

export class QueryObservable<Data = unknown> implements IAsyncData<Data> {
  private auth: AppStoreAuthStore;
  private queriesCache: AppStoreQueriesCacheStore;
  private refreshInterval: Maybe<number>;
  private backoffParams: BackoffParams;
  private fetchValue: FetchValue<Data>;
  private getCachedValue: Maybe<GetCachedValue<Data>>;
  private setCachedValue: Maybe<SetCachedValue<Data>>;
  private getInternalState: Maybe<GetInternalState>;
  private setInternalState: Maybe<SetInternalState>;
  private lastRequestTime: number = 0;
  private throttleMs: number = 0;

  abortController: Maybe<AbortController> = undefined;
  id: string;
  isAuthenticated: boolean;
  isPaginated: boolean;
  data: Maybe<Data> = undefined;
  dataLoadedAt?: Maybe<Date> = undefined;
  failedAt: Maybe<Date> = undefined;
  isLoading = false;
  ready = false;
  hasNextPage = false;
  pageCount: Maybe<number> = undefined;
  activePages: number[] = [0]; // Visible for debug purposes only.
  isFetching = false;

  constructor({
    auth,
    queriesCache,
    id,
    isAuthenticated = true,
    isPaginated = false,
    fetchValue,
    getCachedValue,
    setCachedValue,
    getInternalState,
    setInternalState,
    refreshInterval,
    throttleMs = 0,
    ...backoffParams
  }: BackoffParams & {
    auth: AppStoreAuthStore;
    queriesCache: AppStoreQueriesCacheStore;
    id: string;
    isAuthenticated?: boolean;
    isPaginated?: boolean;
    fetchValue: FetchValue<Data>;
    getCachedValue?: GetCachedValue<Data>;
    setCachedValue?: SetCachedValue<Data>;
    getInternalState?: GetInternalState;
    setInternalState?: SetInternalState;
    refreshInterval?: number;
    throttleMs?: number;
  }) {
    this.auth = auth;
    this.queriesCache = queriesCache;
    this.id = id;
    this.isAuthenticated = isAuthenticated;
    this.isPaginated = isPaginated;
    this.fetchValue = fetchValue;
    this.getCachedValue = getCachedValue;
    this.setCachedValue = setCachedValue;
    this.getInternalState = getInternalState;
    this.setInternalState = setInternalState;
    this.backoffParams = backoffParams;
    this.refreshInterval = refreshInterval;
    this.throttleMs = throttleMs;

    makeObservable<
      this,
      | "auth"
      | "queriesCache"
      | "refreshInterval"
      | "backoffParams"
      | "fetchValue"
      | "getCachedValue"
      | "setCachedValue"
      | "getInternalState"
      | "setInternalState"
      | "refreshValue"
      | "waitForAuth"
      | "lastRequestTime"
      | "throttleMs"
    >(this, {
      auth: false,
      queriesCache: false,
      refreshInterval: false,
      backoffParams: false,
      fetchValue: false,
      getCachedValue: false,
      setCachedValue: false,
      getInternalState: false,
      setInternalState: false,
      lastRequestTime: false,
      throttleMs: false,

      abortController: false,
      id: false,
      isAuthenticated: false,
      isPaginated: false,
      data: observable.ref,
      dataLoadedAt: observable,
      failedAt: observable,
      isLoading: observable,
      isFetching: observable,
      ready: observable,
      hasNextPage: observable,
      pageCount: observable,
      activePages: observable,

      setActivePages: false,
      forceRefetch: false,
      setRefreshInterval: false,
      startPolling: false,
      stopPolling: false,
      refreshValue: false,
      waitForAuth: false,
      resetState: false,
    });
  }

  setActivePages = (pages: number[]) => {
    if (!this.isPaginated || !pages.length) return;

    const { activePages, pageCount } = this;
    const hasPageCount = typeof pageCount === "number";
    const requiredPages =
      // Before they are fetched pages cannot be deactivated to avoid loops.
      hasPageCount ? activePages.filter(i => i === pageCount) : [];

    const newActivePages = (() => {
      const a = [...pages, ...requiredPages];
      const start = min(a) ?? a[0];
      const stop = max(a) ?? a[0];
      const length = stop - start + 1;
      // Make sure there are no gaps:
      return Array.from({ length }, (_, k) => k + start);
    })();

    if (isEqual(activePages, newActivePages)) return;

    const addingActivePages = newActivePages.length >= activePages.length;

    // activateNextPage should only happen once until that page is fetched.
    const clearHasNextPage =
      addingActivePages && !requiredPages.length && hasPageCount && pages.includes(pageCount);

    runInAction(() => {
      this.activePages = newActivePages;
      if (clearHasNextPage) {
        // Disabled otherwise the loading more animation is hidden immediately.
        // this.hasNextPage = false;
      }
    });

    addingActivePages && this.forceRefetch();
  };

  forceRefetch = () => {
    if (this.isFetching) {
      console.debug(`[Query ${this.id}]: Skipping forceRefetch, already fetching`);
      return;
    }
    this.startPolling();
  };

  setRefreshInterval = (refreshInterval: number, restart = true) => {
    if (refreshInterval === this.refreshInterval) return;

    this.refreshInterval = refreshInterval;
    restart && this.startPolling();
  };

  startPolling = () => {
    this.stopPolling();

    console.debug(`[Query ${this.id}]: Start polling`);
    const abortController = new AbortController();
    const { signal } = abortController;
    this.abortController = abortController;

    const fetchAndCacheValue = async () => {
      if (signal.aborted || this.isFetching) return;

      runInAction(() => {
        this.isFetching = true;
      });

      try {
        if (!this.data) {
          const cachedValue = this.queriesCache.getCachedValue<Data>(this.id);
          if (cachedValue && this.setInternalState) {
            console.debug(`[Query ${this.id}]: Restoring state`);
            const { activePages, value, internalState } = cachedValue;
            this.setInternalState(internalState);
            runInAction(() => {
              this.activePages = activePages;
              this.data = value;
              this.ready = true;
            });
          } else {
            this.getCachedValue?.().then(
              value => {
                if (this.data) return;

                runInAction(() => {
                  this.data = value;
                  this.ready = true;
                });
              },
              () => undefined
            );
          }
        }

        await this.waitForAuth(signal);

        if (signal.aborted) return;

        await this.refreshValue(signal);
      } finally {
        runInAction(() => {
          this.isFetching = false;
        });
      }

      if (!signal.aborted && typeof this.refreshInterval === "number") {
        setTimeout(fetchAndCacheValue, this.refreshInterval);
      }
    };

    fetchAndCacheValue();
  };

  stopPolling = () => {
    console.debug(`[Query ${this.id}]: Stop polling`);

    this.abortController?.abort();
  };

  private refreshValue = async (signal: AbortSignal) => {
    const now = Date.now();
    const timeSinceLastRequest = now - this.lastRequestTime;

    /**
     * If we haven't waited long enough, delay the request.
     *
     * This enables us to configure certain queries to avoid
     * hammering the server with requests, (e.g. during /sync,
     * when we may be receiving many poke-requests, ...)
     */
    if (this.throttleMs > 0 && timeSinceLastRequest < this.throttleMs) {
      await new Promise(resolve => setTimeout(resolve, this.throttleMs - timeSinceLastRequest));
    }

    let start = new Date();
    return pRetry(
      async attemptNumber => {
        this.lastRequestTime = Date.now();
        start = new Date();
        console.debug(
          `[Query ${this.id}]: Refreshing pages=${this.activePages}${
            attemptNumber > 1 ? ` attempt=${attemptNumber}` : ""
          }`
        );

        runInAction(() => {
          this.isLoading = true;
        });
        const res = await this.fetchValue(signal, this.activePages);

        if (signal.aborted || !res) {
          runInAction(() => {
            this.isLoading = false;
          });
          return;
        }

        this.setCachedValue?.(res.data);

        runInAction(() => {
          this.isLoading = false;
          this.data = res.data;
          this.dataLoadedAt = start;
          this.hasNextPage = !!res.hasNextPage;
          this.failedAt = undefined;
          this.ready = true;

          const { pageCount } = res;
          if (typeof pageCount === "number") {
            this.pageCount = pageCount;
            const newActivePages = this.activePages.filter(i => i <= pageCount + 1);
            this.setActivePages(newActivePages);
          }
        });
        if (this.getInternalState) {
          this.queriesCache.setCachedValue({
            id: this.id,
            value: res.data,
            activePages: this.activePages,
            internalState: this.getInternalState(),
          });
        }
        console.debug(
          `[Query ${this.id}]: Done, took ${new Date().getTime() - start.getTime()} ms`
        );
      },
      {
        ...this.backoffParams,
        onFailedAttempt: error => {
          runInAction(() => {
            this.isLoading = false;
            this.failedAt = start;
          });
          logger.error({
            message: `[Query ${this.id}]: Failed`,
            info: objectModule.safeAsJson({ error }),
          });
        },
      }
    );
  };

  private waitForAuth = async (signal: AbortSignal) => {
    const { auth } = this;

    if (!this.isAuthenticated || auth.isAuthenticated) return;

    const start = Date.now();
    await new Promise<void>(resolve => {
      if (auth.isAuthenticated) return resolve();

      let warned = false;
      const h = window.setInterval(() => {
        if (!warned && Date.now() - start > 500) {
          console.warn(`[Query ${this.id}][auth] no authentication yet`);
          warned = true;
        }

        if (!signal.aborted && !auth.isAuthenticated) return;

        resolve();
        window.clearInterval(h);
      }, 20);
    });
  };

  resetState = () => {
    this.stopPolling();
    runInAction(() => {
      this.data = undefined;
      this.dataLoadedAt = undefined;
      this.isLoading = false;
      this.failedAt = undefined;
      this.ready = false;
      this.activePages = [0];
    });
  };
}
