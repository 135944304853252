import { css, cx, keyframes } from "@/domains/emotion";
import React, { memo } from "react";

interface MdsSkeletonProps {
  count?: number;
  height?: number | string;
  className?: string;
  delay?: number; // duration in ms
}

export const MdsSkeleton = memo<MdsSkeletonProps>(
  ({ count = 1, height = "1em", className, delay = 0 }) => {
    const combinedAnimation = css({
      animation: `${fadeIn} 80ms ease-in forwards, ${skeletonAnimation} 1.2s ease-in-out infinite`,
      animationDelay: `${delay}ms`,
      opacity: 0,
    });

    // Combine all styles using cx
    const styles = cx(skeletonRowStyles, combinedAnimation, css({ height }), className);

    const rows: React.ReactNode[] = [];
    for (let i = 0; i < count; i++) {
      rows.push(<span key={i} className={styles} />);
    }
    return rows;
  }
);

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const skeletonAnimation = keyframes({
  from: {
    backgroundPosition: "-200px 0",
  },
  to: {
    backgroundPosition: `calc(200px + 100%) 0`,
  },
});

const skeletonRowStyles = css({
  backgroundColor: "#eee",
  backgroundImage: `linear-gradient(90deg, #eee, #f5f5f5, #eee)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "200px 100%",
  borderRadius: "4px",
  display: "inline-block",
  lineHeight: "1em",
  margin: "0 64px 0 72px",
  width: `calc(100% - 136px)`,

  "@media (max-width: 900px)": {
    ".tiptap > *": {
      margin: "0 24px 0 32px",
      width: `calc(100% - 56px)`,
    },
  },
});
