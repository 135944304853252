import { ApiSchema } from "@/modules/api";
import { CollectionIndexData } from "@/store/collections/CollectionIndexes";
import { SyncUpdateValue, UpsertedSyncUpdateResponse } from "@/store/sync/types";
import { IndexedBoolean } from "@/domains/common/types";
import { SortByKind } from "@/modules/lenses/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export type CollectionModelData = ApiSchema["CollectionSyncModelDataResponseSchema"];
export type CollectionUpsertedSyncUpdateValue = ApiSchema["CollectionSyncModelResponseSchema"];
export type CollectionDeletedSyncUpdateValue = ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type CollectionSyncUpdateValue =
  | CollectionUpsertedSyncUpdateValue
  | CollectionDeletedSyncUpdateValue;

export type CollectionSyncUpdate = UpsertedSyncUpdateResponse & {
  value: CollectionSyncUpdateValue;
};

export type IndexedCollectionSyncUpdateValue = SyncUpdateValue<CollectionModelData> &
  CollectionIndexData;

export type CollectionMetadataModelData =
  ApiSchema["CollectionMetadataSyncModelDataResponseSchema"];
export type CollectionMetadataUpsertedSyncUpdateValue =
  ApiSchema["CollectionMetadataSyncModelResponseSchema"];
export type CollectionMetadataDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];

export type CollectionMetadataSyncUpdateValue =
  | CollectionMetadataUpsertedSyncUpdateValue
  | CollectionMetadataDeletedSyncUpdateValue;

export type CollectionMetadataSyncUpdate = UpsertedSyncUpdateResponse & {
  value: CollectionMetadataSyncUpdateValue;
};

export type CollectionIndexTuple =
  | [
      is_available: IndexedBoolean,
      is_owned_by_me: IndexedBoolean,
      datetime: string,
      model_id: string,
    ]
  | [is_available: IndexedBoolean, datetime: string, model_id: string];

export type CollectionSearchSortByKind =
  | SortByKind.LastModified
  | SortByKind.LastCreated
  | SortByKind.LastViewed
  | SortByKind.Alphabetical
  | SortByKind.LastShared;

export interface CollectionSearchParams<T extends CollectionSearchReturnType> {
  query?: string;
  sortBy?: SortByKind;
  filter?: {
    isShared?: boolean;
    isOwnedByMe?: boolean;
    createdBy?: string[];
    modifiedBy?: string[];
  };
  returns: T;
}

export enum CollectionSearchReturnType {
  CollectionObservable = "CollectionObservable",
  IndexedCollection = "IndexedCollection",
  CollectionIndexTuple = "CollectionIndexTuple",
}

export type CollectionSearchReturnTypeMap<T extends CollectionSearchReturnType> = {
  [CollectionSearchReturnType.CollectionObservable]: CollectionObservable[];
  [CollectionSearchReturnType.IndexedCollection]: IndexedCollectionSyncUpdateValue[];
  [CollectionSearchReturnType.CollectionIndexTuple]: CollectionIndexTuple[];
}[T];
