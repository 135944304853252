import { Table } from "dexie";
import { SidePanelRoute, SidePanelState, SidePanelTab } from "@/store/routing/types";
import { CollectionsListPageParams } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageParams } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesSearchParams } from "@/store/note";
import { toJS } from "mobx";
import { PreloadingState } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";

const SIDE_PANEL_STATE_KEY = "side_panel_state";
const SIDE_PANEL_ACTIVE_TAB_ID_KEY = "side_panel_active_tab_id";
const SIDE_PANEL_TABS_KEY = "side_panel_tabs";

const NOTES_LIST_PAGE_PARAMS_KEY = "notes_list_page_params";
const COLLECTIONS_LIST_PAGE_PARAMS_KEY = "collections_list_page_params";
const COLLECTIONS_VIEW_PAGE_PARAMS_KEY = "collections_view_page_params";

const NOTE_CONTENT_PRELOADING_STATE_KEY = "note_content_preloading_state";

const LAST_SYNC_ID_KEY = "last_sync_id";

const getCollectionsViewPageParamsKey = (
  collectionId: string
): `collections_view_page_params-${string}` =>
  `${COLLECTIONS_VIEW_PAGE_PARAMS_KEY}-${collectionId}`;

type SettingsTable = Table<
  unknown,
  | "side_panel_state"
  | "side_panel_active_tab_id"
  | "side_panel_tabs"
  | "notes_list_page_params"
  | "collections_list_page_params"
  | "collections_view_page_params"
  | `collections_view_page_params-${string}`
  | "note_content_preloading_state"
  | "last_sync_id"
>;

export class SettingsDB {
  table: SettingsTable;

  constructor(table: SettingsTable) {
    this.table = table;
  }

  // SIDE PANEL STATE
  getSidePanelState = async (): Promise<{
    sidePanelState?: SidePanelState;
    activeTabId?: SidePanelTab;
    tabs?: Map<SidePanelTab, SidePanelRoute[]>;
  }> => {
    const [sidePanelState, activeTabId, tabsString] = await Promise.all([
      this.table.get(SIDE_PANEL_STATE_KEY),
      this.table.get(SIDE_PANEL_ACTIVE_TAB_ID_KEY),
      this.table.get(SIDE_PANEL_TABS_KEY),
    ]);

    const parsedTabs = tabsString ? JSON.parse(tabsString as string) : [];
    const tabs = new Map<SidePanelTab, SidePanelRoute[]>();
    for (const [tabId, tabRoutes] of parsedTabs) {
      tabs.set(tabId as SidePanelTab, tabRoutes as SidePanelRoute[]);
    }

    return {
      sidePanelState: sidePanelState as SidePanelState,
      activeTabId: activeTabId as SidePanelTab,
      tabs,
    };
  };

  setSidePanelState = async (state: SidePanelState) => {
    return this.table.put(state, SIDE_PANEL_STATE_KEY);
  };

  setSidePanelActiveTabId = async (sidePanelActiveTab: SidePanelTab) => {
    return this.table.put(sidePanelActiveTab, SIDE_PANEL_ACTIVE_TAB_ID_KEY);
  };

  setSidePanelTabs = async (tabs: [SidePanelTab, SidePanelRoute[]][]) => {
    const tabsString = JSON.stringify(tabs);
    return this.table.put(tabsString, SIDE_PANEL_TABS_KEY);
  };

  // NOTE LIST PAGE PARAMS
  getNotesListPageParams = async (): Promise<Partial<NotesSearchParams>> => {
    const params = await this.table.get(NOTES_LIST_PAGE_PARAMS_KEY);
    return params as Partial<NotesSearchParams>;
  };

  setNotesListPageParams = async (params: Partial<NotesSearchParams>) => {
    return this.table.put(toJS(params), NOTES_LIST_PAGE_PARAMS_KEY);
  };

  // COLLECTIONS LIST PAGE PARAMS
  getCollectionsListPageParams = async (): Promise<Partial<CollectionsListPageParams>> => {
    const params = await this.table.get(COLLECTIONS_LIST_PAGE_PARAMS_KEY);
    return params as Partial<CollectionsListPageParams>;
  };

  setCollectionsListPageParams = async (params: Partial<CollectionsListPageParams>) => {
    return this.table.put(toJS(params), COLLECTIONS_LIST_PAGE_PARAMS_KEY);
  };

  // COLLECTIONS VIEW PAGE PARAMS
  getCollectionsViewPageParams = async (
    collectionId: string
  ): Promise<Partial<CollectionsViewPageParams>> => {
    const params = await this.table.get(getCollectionsViewPageParamsKey(collectionId));
    return params as Partial<CollectionsViewPageParams>;
  };

  setCollectionsViewPageParams = async (
    collectionId: string,
    params: Partial<CollectionsViewPageParams>
  ) => {
    return this.table.put(toJS(params), getCollectionsViewPageParamsKey(collectionId));
  };

  setNoteContentPreloadingState = async (noteContentPreloadingState: PreloadingState) => {
    return this.table.put(noteContentPreloadingState, NOTE_CONTENT_PRELOADING_STATE_KEY);
  };

  getNoteContentPreloadingState = async (): Promise<Maybe<PreloadingState>> => {
    return (await this.table.get(NOTE_CONTENT_PRELOADING_STATE_KEY)) as Maybe<PreloadingState>;
  };

  clearNoteContentPreloadingState = async () => {
    return this.table.delete(NOTE_CONTENT_PRELOADING_STATE_KEY);
  };

  setLastSyncId = async ({ syncId }: { syncId: string }) => {
    return this.table.put(syncId, LAST_SYNC_ID_KEY);
  };

  getLastSyncId = async (): Promise<string | undefined> => {
    return (await this.table.get(LAST_SYNC_ID_KEY)) as string | undefined;
  };

  clearLastSyncId = async () => {
    return this.table.delete(LAST_SYNC_ID_KEY);
  };
}
