import table from "@/domains/local-db/account/settings/adapter";
import config from "@/domains/local-db/account/settings/config";
import { CollectionsListPageParams } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageParams } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesSearchParams } from "@/store/note";
import { SidePanelRoute, SidePanelState, SidePanelTab } from "@/store/routing/types";

export const setSidePanelState = async (state: SidePanelState) => {
  return table.set(config.SIDE_PANEL_STATE_KEY, {
    [config.SIDE_PANEL_STATE_KEY]: state,
  });
};

export const setSidePanelActiveTabId = async (sidePanelActiveTab: SidePanelTab) => {
  return table.set(config.SIDE_PANEL_ACTIVE_TAB_ID_KEY, {
    [config.SIDE_PANEL_ACTIVE_TAB_ID_KEY]: sidePanelActiveTab,
  });
};

export const setSidePanelTabs = async (tabs: [SidePanelTab, SidePanelRoute[]][]) => {
  const tabsString = JSON.stringify(tabs);
  return table.set(config.SIDE_PANEL_TABS_KEY, {
    [config.SIDE_PANEL_TABS_KEY]: tabsString,
  });
};

export const setNotesListPageParams = async (params: NotesSearchParams) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.NOTES_LIST_PAGE_PARAMS_KEY, {
    [config.NOTES_LIST_PAGE_PARAMS_KEY]: paramsString,
  });
};

export const setCollectionsListPageParams = async (params: CollectionsListPageParams) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.COLLECTIONS_LIST_PAGE_PARAMS_KEY, {
    [config.COLLECTIONS_LIST_PAGE_PARAMS_KEY]: paramsString,
  });
};

export const setCollectionsViewPageParams = async (
  collectionId: string,
  params: CollectionsViewPageParams
) => {
  const paramsString = JSON.stringify(params);
  return table.set(config.getCollectionsViewPageParamsKey(collectionId), {
    [config.COLLECTIONS_VIEW_PAGE_PARAMS_KEY]: paramsString,
  });
};

export const removeCollectionsViewPageParams = async (collectionId: string) => {
  return table.remove(config.getCollectionsViewPageParamsKey(collectionId));
};

export const removeCollectionsListPageParams = async () => {
  return table.remove(config.COLLECTIONS_LIST_PAGE_PARAMS_KEY);
};

export const removeNotesListPageParams = async () => {
  return table.remove(config.NOTES_LIST_PAGE_PARAMS_KEY);
};

export const removeSidePanelState = async () => {
  return table.remove(config.SIDE_PANEL_STATE_KEY);
};

export const removeSidePanelActiveTabId = async () => {
  return table.remove(config.SIDE_PANEL_ACTIVE_TAB_ID_KEY);
};

export const removeSidePanelTabs = async () => {
  return table.remove(config.SIDE_PANEL_TABS_KEY);
};
