import type { AppSubStoreArgs } from "@/store/types";
import { type SyncUpdateValue, type OptimisticSyncUpdate, SyncModelKind } from "@/store/sync/types";
import type { DataImportSyncModelData } from "@/store/data-imports/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { DataImportObservable } from "@/store/data-imports/DataImportObservable";
import {
  computed,
  makeObservable,
  observable,
  runInAction,
  onBecomeObserved,
  onBecomeUnobserved,
  action,
  override,
} from "mobx";
import { Maybe } from "@/domains/common/types";
import { liveQuery, Subscription } from "dexie";
import Dexie from "dexie";
import { AppStore } from "@/store";
import { DataImportIndexes } from "@/store/data-imports/DataImportIndexes";

type DataImportItemTuple = [started_at: string, model_id: string];

export class AppStoreDataImportsStore extends BaseSyncModelStore<
  DataImportObservable,
  DataImportSyncModelData
> {
  liveQuerySubscription?: Subscription;
  subscribedItems: DataImportItemTuple[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.DataImport, ...injectedDeps });

    makeObservable<this, "getLatestImports">(this, {
      computeIndexes: override,
      createSyncModel: false,

      initializeLiveQuery: action,
      liveQuerySubscription: observable,
      subscribedItems: observable,

      latestImport: computed,
      length: computed,
      getLatestImports: false,
    });

    onBecomeObserved(this, "subscribedItems", () => this.initializeLiveQuery());
    onBecomeUnobserved(this, "subscribedItems", () => {
      this.liveQuerySubscription?.unsubscribe();
    });
  }

  createSyncModel(data: SyncUpdateValue<DataImportSyncModelData>): DataImportObservable {
    return new DataImportObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  initializeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.localTable
        .where("[started_at+model_id]")
        .above(["\0", Dexie.minKey])
        .reverse()
        .keys();
    }).subscribe({
      next: ids => {
        runInAction(() => {
          this.subscribedItems = ids as unknown as DataImportItemTuple[];
        });
      },
    });
  }

  get length(): number {
    return this.subscribedItems.length;
  }

  get latestImport(): Maybe<DataImportObservable> {
    const latestOne = this.getLatestImports(1);
    return latestOne[0];
  }

  getLatestImports(count: number): DataImportObservable[] {
    const sliced = this.subscribedItems.slice(0, count);
    return sliced.map(([_startedAt, id]) => this.get(id)).filter(e => !!e);
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<DataImportSyncModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<DataImportSyncModelData>[];
  }): Record<string, unknown> {
    return new DataImportIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
