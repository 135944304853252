import { logger } from "@/modules/logger";
import Dexie, { EntityTable } from "dexie";
import { objectModule } from "@/modules/object";
import {
  SerializedOptimisticUpdate,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { SerializedSyncOperation } from "@/store/sync/operations/types";
import { SettingsDB } from "@/domains/db/settingsDb";
import { SearchFullTextEntry, SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { UNTITLED_COLLECTION_TITLE, UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";

export class MemDB extends Dexie {
  public readonly spaceAccountId: string;

  public readonly mappedTables: Record<
    SyncModelKind,
    {
      remote: Dexie.Table<SyncUpdateValue<SyncModelData>>;
      local: Dexie.Table<SyncUpdateValue<SyncModelData>>;
    }
  >;

  public readonly queue: {
    processing: EntityTable<
      SerializedSyncOperation,
      "operationId" | "committedAt" | "operationKind" | "modelId" | "collectionId" | "noteId"
    >;
    pending: EntityTable<
      SerializedSyncOperation,
      | "operationId"
      | "committedAt"
      | "operationKind"
      | "latestSpaceAccountSequenceId"
      | "modelId"
      | "collectionId"
      | "noteId"
    >;
    optimisticUpdates: EntityTable<
      SerializedOptimisticUpdate<SyncModelData>,
      | "optimistic_update_id"
      | "sync_operation_id"
      | "model_kind"
      | "model_id"
      | "locally_committed_at"
    >;
  };

  /**
   * @TODO - After we roll out CVRs, we can remove the `syncUpdates` table.
   *
   * TODO: @MacroMackie follow up with this on Monday, Nov 11.
   */
  public readonly syncUpdates: Dexie.Table<SyncUpdate<SyncModelData> & { sync_id: string }>;
  public readonly settings: SettingsDB;
  public readonly searchSuggestions: Dexie.Table<SearchSuggestion>;
  public readonly searchFullText: Dexie.Table<SearchFullTextEntry>;

  private readonly _supportedModels: SyncModelKind[] = [
    SyncModelKind.Note,
    SyncModelKind.NoteContentDocument,
    SyncModelKind.Collection,
    SyncModelKind.CollectionItem,
    SyncModelKind.CollectionMetadata,
    SyncModelKind.Contact,
    SyncModelKind.ChatConversation,
    SyncModelKind.ChatMessage,
    SyncModelKind.FavoriteItem,
    SyncModelKind.SpaceAccountContact,
    SyncModelKind.SpaceAccountChatMessage,
    SyncModelKind.SpaceAccountCollection,
    SyncModelKind.SpaceAccountNote,
    SyncModelKind.SavedSearch,
    SyncModelKind.SpaceAccountFeatureFlagsConfig,
    SyncModelKind.SpaceAccountTopic,
    SyncModelKind.SpaceAccountTopicItem,
    SyncModelKind.DataImport,
  ];

  constructor(spaceAccountId: string) {
    super(`MemDB/${spaceAccountId}`);

    this.spaceAccountId = spaceAccountId;

    this.setupVersionAndStores();
    this.addListeners();

    this.mappedTables = this.mapTables();
    this.queue = {
      processing: this.table("processing"),
      pending: this.table("pending"),
      optimisticUpdates: this.table("optimisticUpdates"),
    };
    this.syncUpdates = this.table("syncUpdates");
    this.settings = new SettingsDB(this.table("settings"));
    this.searchSuggestions = this.table("searchSuggestions");
    this.searchFullText = this.table("searchFullText");
  }

  public bootstrap() {
    // TODO: implement
  }

  public async erase() {
    await this.transaction(
      "rw",
      [
        ...Object.values(this.mappedTables).flatMap(table => [table.local, table.remote]),
        this.syncUpdates,
        this.searchSuggestions,
        this.searchFullText,
        this.settings.table,
      ],
      async () => {
        await Promise.all([
          Object.values(this.mappedTables).flatMap(table => [
            table.local.clear(),
            table.remote.clear(),
          ]),
          this.syncUpdates.clear(),
          this.searchSuggestions.clear(),
          this.searchFullText.clear(),
          this.settings.clearNoteContentPreloadingState(),
          this.settings.clearLastSyncId(),
        ]);
      }
    );
  }

  // TODO: maybe delay message and show a modal to user vs system window
  // https://web.dev/articles/persistent-storage#how_is_permission_granted
  // https://dexie.org/docs/StorageManager
  async persist(): Promise<boolean> {
    return await navigator.storage?.persist?.();
  }

  async estimateQuota(): Promise<StorageEstimate | undefined> {
    return await navigator.storage?.estimate?.();
  }

  async isStoragePersisted(): Promise<boolean> {
    return (await navigator.storage?.persisted?.()) ?? false;
  }

  // Define indexes for each table
  // Note that actual schema doesn't matter since indexedDb is key-value storage
  // Don't forget to update _supportedModels
  private setupVersionAndStores() {
    const defaultIndexes = "model_id";

    this.version(1).stores({
      "remote/NOTE": defaultIndexes,
      "local/NOTE": `${defaultIndexes},primary_label,modified_at,created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,

      "remote/NOTE_CONTENT_DOCUMENT": defaultIndexes,
      "local/NOTE_CONTENT_DOCUMENT": defaultIndexes,

      "remote/COLLECTION": defaultIndexes,
      "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id]`,

      "remote/COLLECTION_ITEM": defaultIndexes,
      "local/COLLECTION_ITEM": `${defaultIndexes},collection_id,item_id,[collection_id+item_id+model_id],[item_id+collection_id+model_id]`,

      "remote/COLLECTION_METADATA": defaultIndexes,
      "local/COLLECTION_METADATA": defaultIndexes,

      "remote/CONTACT": defaultIndexes,
      "local/CONTACT": `${defaultIndexes},is_direct,profile_display_name,profile_email_address,profile_display_name_and_email_address`,

      "remote/FAVORITE_ITEM": defaultIndexes,
      "local/FAVORITE_ITEM": `${defaultIndexes},sort_key,[sort_key+model_id]`,

      "remote/SPACE_ACCOUNT_NOTE": defaultIndexes,
      "local/SPACE_ACCOUNT_NOTE": defaultIndexes,

      "remote/SPACE_ACCOUNT_COLLECTION": defaultIndexes,
      "local/SPACE_ACCOUNT_COLLECTION": defaultIndexes,

      "remote/SAVED_SEARCH": defaultIndexes,
      "local/SAVED_SEARCH": defaultIndexes,

      "remote/SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG": defaultIndexes,
      "local/SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG": defaultIndexes,

      "remote/SPACE_ACCOUNT_TOPIC": defaultIndexes,
      "local/SPACE_ACCOUNT_TOPIC": defaultIndexes,

      "remote/SPACE_ACCOUNT_TOPIC_ITEM": defaultIndexes,
      "local/SPACE_ACCOUNT_TOPIC_ITEM": `${defaultIndexes},space_account_topic_id,item_id,[space_account_topic_id+item_id+model_id],[item_id+space_account_topic_id+model_id]`,

      "remote/CHAT_CONVERSATION": defaultIndexes,
      "local/CHAT_CONVERSATION": defaultIndexes,

      "remote/CHAT_MESSAGE": defaultIndexes,
      "local/CHAT_MESSAGE": `${defaultIndexes},is_system_message,locally_created_at,status,context_ids,context_kinds,[locally_created_at+model_id],[locally_created_at+is_system_message+model_id],[locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]`,

      "remote/SPACE_ACCOUNT_CHAT_MESSAGE": defaultIndexes,
      "local/SPACE_ACCOUNT_CHAT_MESSAGE": defaultIndexes,

      "remote/SPACE_ACCOUNT_CONTACT": defaultIndexes,
      "local/SPACE_ACCOUNT_CONTACT": defaultIndexes,

      "remote/DATA_IMPORT": defaultIndexes,
      "local/DATA_IMPORT": `${defaultIndexes},started_at,ended_at`,

      processing: "&operationId,committedAt,operationKind,modelId,collectionId,noteId",
      pending:
        "&operationId,committedAt,operationKind,latestSpaceAccountSequenceId,modelId,collectionId,noteId",
      optimisticUpdates: "&sync_id,sync_operation_id,model_kind,model_id,locally_committed_at",
      syncUpdates: "&sync_id,locally_committed_at,committed_at",
      searchSuggestions: `&modelId,sortKey,mentionKey,label,*labelWords`,
      searchFullText: `&modelId,content`,
      settings: "",
    });

    /**
     * Migrated from "sync_id" to "optimistic_update_id".
     * Because it is a primary key, we need to drop-and-recreate the table.
     * (Because these are just the optimisticUpdates, they are safe to drop, although
     * some clients may see stale data for a short period of time.)
     */
    this.version(2).stores({
      /** Deleting the table by setting it to null. */
      optimisticUpdates: null,
    });

    this.version(3).stores({
      /** Recreating the table with the new schema. */
      optimisticUpdates:
        "&optimistic_update_id,sync_operation_id,model_kind,model_id,locally_committed_at",
    });

    this.version(4).stores({
      "local/CONTACT": `${defaultIndexes},is_direct,profile_display_name,profile_email_address,profile_display_name_and_email_address,[is_direct+model_id]`,
      searchFullText: `&modelId,content,sortKey`,
    });

    this.version(5).stores({
      "local/DATA_IMPORT": `${defaultIndexes},started_at,[started_at+model_id]`,
    });

    this.version(6)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(note => {
            note.lowercase_primary_label = note.primary_label.toLowerCase();
          });
      });

    this.version(7)
      .stores({
        "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/COLLECTION")
          .toCollection()
          .modify(collection => {
            collection.title = collection.title || UNTITLED_COLLECTION_TITLE;
            collection.lowercase_title = collection.title.toLowerCase();
          });
      });

    this.version(8)
      .stores({
        searchSuggestions: `&modelId,sortKey,mentionKey,label,lowercaseLabel,*labelWords`,
      })
      .upgrade(async tx => {
        const suggestions = await tx.table("searchSuggestions").toArray();
        const notes = await tx.table("local/NOTE").toArray();
        const collections = await tx.table("local/COLLECTION").toArray();

        const noteMap = new Map(notes.map(note => [note.model_id, note]));
        const collectionMap = new Map(
          collections.map(collection => [collection.model_id, collection])
        );

        await Promise.all(
          suggestions.map(async suggestion => {
            let label;
            if (suggestion.type === SearchSuggestionType.NOTE) {
              const note = noteMap.get(suggestion.modelId);
              label = note?.primary_label || UNTITLED_NOTE_TITLE;
            } else if (suggestion.type === SearchSuggestionType.COLLECTION) {
              const collection = collectionMap.get(suggestion.modelId);
              label = collection?.title || UNTITLED_COLLECTION_TITLE;
            }

            if (label) {
              await tx.table("searchSuggestions").update(suggestion.modelId, {
                label,
                lowercaseLabel: label.toLowerCase(),
              });
            }
          })
        );
      });

    // intro locally_created_at
    this.version(9)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(note => {
            note.locally_created_at = note.model_data.locally_created_at;
          });
      });

    // Added shared_with_me_at, [is_available+shared_with_me_at+model_id], [is_available+is_shared+shared_with_me_at+model_id] and [is_available+is_owned_by_me+shared_with_me_at+model_id]
    this.version(10)
      .stores({
        "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,shared_with_me_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+shared_with_me_at+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_shared+shared_with_me_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id],[is_available+is_owned_by_me+shared_with_me_at+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/COLLECTION")
          .toCollection()
          .modify(async collection => {
            const spaceAccountCollectionId = resolveSpaceAccountCollectionSyncModelUuid({
              spaceAccountId: this.spaceAccountId,
              collectionId: collection.model_id,
            });
            const spaceAccountCollection = await this.table("local/SPACE_ACCOUNT_COLLECTION").get(
              spaceAccountCollectionId
            );
            collection.shared_with_me_at = spaceAccountCollection?.model_data.shared_at || "";
          });
      });

    // add [is_available+lowercase_primary_label+model_id] and [is_available+is_owned_by_me+lowercase_primary_label+model_id]
    this.version(11).stores({
      "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_primary_label+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_primary_label+model_id],[trashed_at+model_id]`,
    });
  }

  private mapTables(): Record<SyncModelKind, { remote: Dexie.Table; local: Dexie.Table }> {
    return this._supportedModels.reduce(
      (acc, kind) => {
        acc[kind] = {
          remote: this.table(`remote/${kind}`),
          local: this.table(`local/${kind}`),
        };
        return acc;
      },
      {} as Record<SyncModelKind, { remote: Dexie.Table; local: Dexie.Table }>
    );
  }

  private addListeners() {
    // Log global events
    this.on("ready", () => {
      logger.debug({
        message: "[MemDB] is ready",
      });
    });

    this.on("populate", () => {
      logger.debug({
        message: "[MemDB] is populated",
      });
    });

    this.on("blocked", error => {
      // Its not technically an error, db will become unlocked after version change is complete on whoever is holding the lock
      // but we log it anyway to keep an eye on it
      logger.error({
        message: "[MemDB] is blocked",
        info: { error: objectModule.safeAsJson({ ...error }) },
      });
    });
  }
}
