// import { clientEnv, nodeEnvironment } from "@/config/clientEnv";
import { NodeEnvironment } from "@/domains/common/types";
import {
  DATADOG_LOGGING_CLIENT_TOKEN,
  DATADOG_SITE,
  DATADOG_SERVICE_NAME,
} from "@/domains/datadog/constants";
import { getBackendAnalyticsBaseUrl } from "@/manager/backend-api-client/initializeBackendApiManager";
import { clientEnvModule } from "@/modules/client-env";
import { datadogLogs } from "@datadog/browser-logs";

/**
 * Matches the site name of our datadog instance.
 */

export const initializeDatadogLoggingClient = async () => {
  const nodeEnv = clientEnvModule.nodeEnv();
  const packageVersion = clientEnvModule.packageVersion();
  const { buildInfo } = clientEnvModule.buildInfo();
  const version = `${packageVersion}-${buildInfo.gitCommitSha}`;

  datadogLogs.init({
    clientToken: DATADOG_LOGGING_CLIENT_TOKEN,
    site: DATADOG_SITE,
    version,
    forwardErrorsToLogs: true,
    service: DATADOG_SERVICE_NAME,
    sessionSampleRate: 100,
    env: nodeEnv,
    proxy: `${getBackendAnalyticsBaseUrl()}/v2/analytics/datadog/proxy`,
  });

  /**
   * If we aren't in production, we default to using the console
   * logger instead of the real logger.
   */
  if (nodeEnv !== NodeEnvironment.Production) {
    datadogLogs.logger.setHandler("console");
  }

  return datadogLogs;
};
